import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import styled from 'styled-components';
import qs from 'qs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import {
  bodyDataAtom,
  hideUnselectedOptionsAtom,
  neckDataAtom,
  currentPartAtom,
  partEditModeAtom,
  customerPartAtom,
  newNeckPricingAtom,
  newBodyPricingAtom,
} from 'shared/state/pricingState';
import {
  mapParentPricing, nextCustomerPart, updateBodyPricing, updateNeckPricing, updatePricing,
} from 'shared/data';
import { find, includes, omit } from 'lodash';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { ICustomerPart, IPricedPart } from 'shared/types/parts';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import DensityCalculatorDrawer from 'pages/Util/DensityCalculator/Components/DensityCalculatorDrawer';
import {
  PART_BOM_COLLECTION, PART_VIEWER_COLLECTION, partBomItemsAtom, PART_PRICING_COLLECTION,
  partConfigTermsAtom,
} from 'shared/state/partViewState';
import { IPartBom } from 'shared/types/pricingTool';
import InventoryQuickEditDrawer from 'pages/Inventory/Components/InventoryQuickEditDrawer';
import { currentCustomerAtom, customersAtom } from 'shared/state/customerState';
import { configToDescription } from 'shared/partParser/util';
import Loader from 'shared/components/Utility/Loader';
import PartPricingColumn from './Components/PartPricingColumn';
import PartDetailColumn from './Components/PartDetailColumn';
import {
  NewItemHeader,
} from './Components';

const ComponentWrapper = styled(FlexColumn)`
  width: calc(100% - 100px);
  height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  left: 68px;
`;

const PartDataRow = styled(FlexRow)`
  width: 100%;
  min-height: 50vh;
  flex-grow: 2;
  align-items: flex-start;
`;

export default () => {
  const location = useLocation();
  const {
    customer, partId, copy,
  } = qs.parse(location.search.replace('?', ''));
  const partType = window.location.href.match(/body/i) ? 'body' : 'neck';
  const { firestore } = useFirebase();

  const customers = useRecoilValue(customersAtom);
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const setHidden = useSetRecoilState(hideUnselectedOptionsAtom);

  const setPartEditMode = useSetRecoilState(partEditModeAtom);

  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(customerPartAtom);
  const setCurrentPart = useSetRecoilState(currentPartAtom);
  const dataAtom = partType === 'body' ? bodyDataAtom : neckDataAtom;
  const partData = useRecoilValue(dataAtom);
  const newPricingAtom = partType === 'body' ? newBodyPricingAtom : newNeckPricingAtom;
  // @ts-ignore -- it's complaining because there is no overlap between the two atoms
  const newPricingObject = useRecoilValue(newPricingAtom);
  const partViewerDataString = useRecoilValue(PART_VIEWER_COLLECTION);
  const partPricingDataString = useRecoilValue(PART_PRICING_COLLECTION);
  const partBomDbString = useRecoilValue(PART_BOM_COLLECTION);
  const bodyData = useRecoilValue(bodyDataAtom);
  const neckData = useRecoilValue(neckDataAtom);
  const setBomItems = useSetRecoilState(partBomItemsAtom);

  const [_siblings, setSiblings] = useState<any[]>([]);
  const [customerParts, setCustomerParts] = useState<any[]>([]);

  const pricingUpdateMethod = partType === 'body' ? updateBodyPricing : updateNeckPricing;

  const fetchParent = async (parentSku: string|null) => {
    if (!parentSku) return null;
    let parent = null;
    let parentPricing = null;
    const [parentDoc, parentPricingDoc] = await Promise.all([
      firestore.collection(partViewerDataString).doc(parentSku).get(),
      firestore.collection(partPricingDataString).doc(parentSku).get(),
    ]);
    if (!parentDoc.exists) return null;
    parent = parentDoc.data();
    parentPricing = parentPricingDoc.exists ? parentPricingDoc.data() : null;
    return { ...parent, pricing: parentPricing };
  };

  useEffect(() => {
    setHidden(!!partId);

    if (!copy && partId) {
      // This is the case where a part exists—we have a partId and the copy flag is false
      // In this case, we are viewing/editing an existing part.
      Promise.all([
        firestore.collection(partViewerDataString).doc(partId).get(),
        firestore.collection(partPricingDataString).doc(partId).get(),
        firestore.collection(partViewerDataString).where('customer', '==', customer || partId.substring(0, 5))
          .where('type', 'in', partType === 'body' ? ['BB', 'GB'] : ['BN', 'GN']).get(),
      ]).then((_partData) => {
        const [partDoc, pricingDoc, customerPartDocs] = _partData;
        if (!partDoc.exists) throw new Error('No record for that id');
        const data = partDoc.data() as ICustomerPart;
        const pricingData = pricingDoc.data();
        if (!data) throw new Error('No data for that record');
        const customerPartRecords = customerPartDocs.docs.map((d) => d.data());
        setCustomerParts(customerPartRecords);

        // @ts-ignore
        // if data.childSku is null, this will return null
        fetchParent(data.parent || null, data.customer, data.type).then((parent) => {
          // If the part doesn't have pricing, we will not worry about ensuring the pricing is up-to-date.
          // @ts-ignore
          if (!pricingData) {
            const customerRecord = find(customers, (c: ICustomerRecord) => c.id === partId.slice(0, 5));
            if (customerRecord) {
              setCurrentCustomer(customerRecord);
              const newState: IPricedPart = {
                ...newPricingObject,
                ...newPartState,
                ...data,
                customer: customerRecord.id,
                Description: configToDescription(data.config),
                Sku: partId,
                Id: data.Id || '',
                notes: data.notes,
                parent: data.parent || undefined,
              };
              // @ts-ignore
              if (partType === 'body') newState.volume = parent?.volume || data.volume;
              // @ts-ignore
              setNewPartState(newState);
            }
          } else {
            // If the part does have pricing, we need to compare it to the current pricing in our database
            // to make sure that all components of this part price are up-to-date.
            // The records are merged into one, with the updated pricing integrated.
            const recordData = pricingUpdateMethod(pricingData, partData);
            const pricingObject = parent ? mapParentPricing(parent, pricingData) : pricingData;
            const partCategory = includes(['GN', 'BN'], data.type) ? 'neck' : 'body';
            const updatedPricing = updatePricing({ pricing: pricingObject, partCategory: partType }, [bodyData, neckData], partCategory);
            const newState = {
              ...data,
              Description: configToDescription(data.config),
              id: partId,
              active: data.active,
              Sku: data.Sku,
              qboId: data.Id,
              notes: data.notes || '',
              parent,
              ...updatedPricing.pricing,
            };

            if (partType === 'body') {
              // @ts-ignore
              newState.volume = parent?.volume || data.volume;
            }
            setNewPartState(newState);
            setCurrentPart(newState);

            // @ts-ignore
            setCurrentCustomer(find(customers, (c: ICustomerRecord) => c.id === data.customer));
            // firestore.collection(customersDbString).doc(data.customer).get().then((d) => {
            //   setCurrentCustomer(d.data() as ICustomerRecord);
            // });
          }
        });
      });
    } else if (copy) {
      const tempData = localStorage.getItem('pricing.temp.part');
      if (!tempData) throw new Error('no copy data to read!');
      const copyRecord = JSON.parse(tempData);
      firestore.collection(partViewerDataString)
        .where('customer', '==', customer)
        .where('type', '==', copyRecord.type)
        .get()
        .then((customerPartDocs) => {
          const parts = customerPartDocs.docs.map((d) => d.data());
          setCustomerParts(parts);
          const recordData = pricingUpdateMethod(copyRecord.pricing || {}, partData);
          setNewPartState({
            ...newPricingObject,
            ...newPartState,
            Description: configToDescription(copyRecord.config, configTerms),
            ...recordData,
            ...omit(copyRecord, 'oldPartNumber', 'oldDescription'),
            parent: copyRecord.parent,
            materialsConfirmed: null,
            materialsConfirmedBy: null,
            lastSold: null,
          });
          // @ts-ignore
          setCurrentCustomer(find(customers, (c: ICustomerRecord) => c.id === copyRecord.customer));
          firestore.collection(partBomDbString).doc(copyRecord.oldPartNumber).get().then((doc) => {
            if (!doc.exists) return;
            const bomData = doc.data() as IPartBom;
            setBomItems(bomData.bom);
            setPartEditMode(true);
          });
        });
    } else if (!copy && !partId && customer) {
      firestore.collection(partViewerDataString).where('customer', '==', customer).get().then((snap) => {
        const _customerParts: any[] = snap.docs.map((d) => d.data());
        const matchType = partType === 'body' ? 'B' : 'N';
        /*
         * TODO: NEED TO CLONE BASIC PRICING OBJECT FOR THE CORRECT PART TYPE
        */
        const nextPart = nextCustomerPart(customer, _customerParts, `[G|B]${matchType}`);
        const customerRecord = find(customers, (c: ICustomerRecord) => c.id === customer);
        if (customerRecord) {
          setCurrentCustomer(customerRecord);
          setNewPartState({
            ...newPartState,
            id: nextPart,
            Sku: nextPart,
            customerId: customerRecord.id,
            ...newPricingObject,
          });
        }
        setPartEditMode(true);
      });
    }
  }, [partId]);

  return (
    <>
      {newPartState.Sku.length > 0 ? (
        <>
          <InventoryQuickEditDrawer />
          <DensityCalculatorDrawer key="density-calculator-drawer" />
          <ComponentWrapper key="part-record-component-wrapper">
            <NewItemHeader partType={partType} partId={partId || newPartState.id} copy={copy} customerParts={customerParts || []} />
            <PartDataRow key="part-record-part-data-row">
              <PartDetailColumn key="part-record-part-detail-column" partType={partType} partId={partId || newPartState.id} copy={copy} customerParts={customerParts || []} />
              <PartPricingColumn key="part-record-part-pricing-column" partType={partType} partState={newPartState} />
            </PartDataRow>
          </ComponentWrapper>
        </>
      ) : (
        <FlexRow style={{
          width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Loader />
        </FlexRow>
      )}
    </>
  );
};
