import axios from 'axios/index';
import {
  find, findIndex, first, includes, omit, sortBy,
} from 'lodash';
import { Modal } from 'antd';
import {
  IQBOInvoice, IQBOItem, IQBOSparseInvoice, IQBOSparseInvoiceLine,
} from 'shared/types/qbo';
import { ICustomerRecord, IShipment, IShipmentItem } from 'shared/types/dbRecords';
import { qboDateString } from 'shared/data/calendar';
import QBOItem from 'shared/data/QBO/item';
import API_BASE_URL from './index';

export const CREDIT_CARD_FEE_PERCENTAGE = 0.015;
const CREDIT_CARD_FEE_ITEM_ID = '13648';

const STUB_INVOICE = {
  Line: [
    {
      Id: '1',
      LineNum: 1,
      Description: 'Stub',
      Amount: 1,
      DetailType: 'SalesItemLineDetail',
      SalesItemLineDetail: {
        ItemRef: {
          value: '9782',
          name: '9910018',
        },
        UnitPrice: 1,
        Qty: 1,
        ItemAccountRef: {
          value: '21',
          name: '40000 Sales Income',
        },
        TaxCodeRef: {
          value: 'TAX',
        },
        TaxClassificationRef: {
          value: 'EUC-99990202-V1-00020000',
        },
      },
    },
    {
      Amount: 1,
      DetailType: 'SubTotalLineDetail',
      SubTotalLineDetail: {},
    },
  ],
};

const sortPartsFirst = (items: IShipmentItem[]) => sortBy(items, [(item) => {
  const firstChar = item.Sku.charAt(0);
  if (Number.isNaN(parseInt(firstChar, 10))) return item.Sku;
  return `{${item.Sku}`;
}]);
const generateLineItems = async (items: IShipmentItem[], shippingCost: number = 0): Promise<IQBOSparseInvoiceLine[]> => new Promise((resolve, reject) => {
  QBOItem.fetchMany(items
    .filter((i: IShipmentItem) => i.quantityShipped > 0) // don't add zero-quantity items to invoice
    .map((i) => i.Sku))
    .then((qboItems) => {
      const totalCost = items.reduce((acc, i) => acc + (i.quantityShipped * i.unitPrice), 0) + shippingCost;

      const lineItems = sortPartsFirst(items).map((i: IShipmentItem, index: number) => {
        const qboItem = find(qboItems, (q: IQBOItem) => q.Name === i.Sku);

        const baseLineItem = {
          Description: i.notes?.length ? [i.Description, i.notes].join('\n') : i.Description,
          DetailType: 'SalesItemLineDetail',
          LineNum: index + 1,
          Id: `${index + 1}`,
          Amount: i.quantityShipped * i.unitPrice,
        };

        return {
          ...baseLineItem,
          SalesItemLineDetail: {
            Qty: i.quantityShipped,
            UnitPrice: i.unitPrice,
            ItemRef: {
              value: qboItem ? qboItem.Id : '',
              name: qboItem ? qboItem.Description : '',
            },
          },
        };
      });
      resolve(lineItems.filter((l: any) => l) as IQBOSparseInvoiceLine[]);
    });
});

const shippingAddress = (customer: ICustomerRecord) => {
  const addressLines = [
    customer.ShipAddr.Line1,
    customer.ShipAddr.Line2,
    customer.ShipAddr.Line3,
    customer.ShipAddr.Line4,
  ].filter((l: string) => l);
  return {
    ...omit(customer.ShipAddr, ['default', 'name', 'id', 'Id', 'shippingAccount', 'shippingVendor']),
    Line1: customer.CompanyName,
    Line2: addressLines[0],
    Line3: addressLines[1],
    Line4: addressLines[2],
    Line5: addressLines[3],
  };
};
const billingAddress = (customer: ICustomerRecord) => {
  const addressLines = [
    customer.BillAddr.Line1,
    customer.BillAddr.Line2,
    customer.BillAddr.Line3,
    customer.BillAddr.Line4,
  ].filter((l: string) => l);
  return {
    ...customer.BillAddr,
    Line1: customer.CompanyName,
    Line2: addressLines[0],
    Line3: addressLines[1],
    Line4: addressLines[2],
    Line5: addressLines[3],
  };
};
const shippingCostLine = (shippingCost: number) => (shippingCost ? [{
  Amount: shippingCost,
  DetailType: 'SalesItemLineDetail',
  SalesItemLineDetail: {
    ItemRef: {
      value: 'SHIPPING_ITEM_ID',
    },
  },
  LineNum: 100,
  Id: '100',
}] : []);

export const calculateCreditCardFee = (shipmentValue: number, shippingCost: number) => {
  const totalCost = shipmentValue + shippingCost;
  return Math.ceil(totalCost * CREDIT_CARD_FEE_PERCENTAGE * 100) / 100;
};

const creditCardFeeLine = (totalCost: number) => (totalCost ? [{
  Amount: calculateCreditCardFee(totalCost, 0),
  DetailType: 'SalesItemLineDetail',
  Description: '1.5% Credit Card Processing Fee\nOmit this fee if you are paying by check or bank/ACH.',
  SalesItemLineDetail: {
    ItemRef: {
      value: CREDIT_CARD_FEE_ITEM_ID,
    },
  },
  LineNum: 101,
  Id: '101',
}] : []);

const shipmentToInvoice = async (shipment: IShipment): Promise<IQBOSparseInvoice> => {
  const date = new Date();
  return new Promise((resolve, reject) => {
    generateLineItems(shipment.shippedItems).then((lineItems) => {
      const shippingCost = shippingCostLine(shipment.shippingCost);
      // Add a credit card fee if the customer has a sales term of 7 (credit card / due on receipt)
      const creditCardFee = shipment.customer.SalesTermRef.value === '7' ? creditCardFeeLine(shipment.value + (shipment.shippingCost || 0)) : [];
      const invoiceObject: IQBOSparseInvoice = {
        domain: 'QBO',
        AllowIPNPayment: true,
        AllowOnlinePayment: true,
        AllowOnlineCreditCardPayment: true,
        AllowOnlineACHPayment: true,
        Balance: shipment.value + (shipment.shippingCost || 0) + (creditCardFee.length ? creditCardFee[0].Amount : 0),
        BillAddr: billingAddress(shipment.customer),
        BillEmail: shipment.customer.PrimaryEmailAddr,
        CustomField: [
          {
            DefinitionId: '1',
            Name: 'P.O. Number',
            Type: 'StringType',
            StringValue: shipment.purchaseOrder,
          },
        ],
        CustomerRef: {
          name: shipment.customer.CompanyName,
          value: shipment.customer.Id,
        },
        CustomerMemo: {
          value: shipment.notes,
        },
        Deposit: 0,
        DocNumber: shipment.shipmentNumber,
        EmailStatus: 'NeedToSend',
        // @ts-ignore
        Line: [...lineItems, ...shippingCost, ...creditCardFee.filter((l) => l.Amount > 0)],
        MetaData: {
          CreateTime: qboDateString(date, true),
          LastUpdatedTime: qboDateString(date, true),
        },
        PrintStatus: 'NeedToPrint',
        TrackingNum: null,
        SalesTermRef: shipment.customer.SalesTermRef,
        // @ts-ignore - need to remove Id, default, and name fields for QBO validation
        ShipAddr: shippingAddress(shipment.customer),
        ShipDate: includes(['EGGLE', 'GOULD', 'XOTIC'], shipment.customer.id) ? '' : qboDateString(date, true),
        ShipMethodRef: null,
        TotalAmt: shipment.value + (shipment.shippingCost || 0),
        TxnDate: qboDateString(shipment.shipDate.toDate() || new Date(), true),
      };
      if (shipment.trackingNumber) {
        invoiceObject.TrackingNum = shipment.trackingNumber;
        invoiceObject.ShipMethodRef = {
          value: shipment.trackingNumber.match(/1Z/) ? 'UPS' : 'FedEx',
          name: shipment.trackingNumber.match(/1Z/) ? 'UPS' : 'FedEx',
        };
      }
      resolve(invoiceObject);
    })
      .catch((e) => {
        reject(e);
      });
  });
};

const fetchLast = async (controller: AbortController): Promise<IQBOInvoice> => {
  const response = await axios.get(`${API_BASE_URL}/invoice/fetchLast`, { signal: controller.signal });
  return first(response.data.QueryResponse.Invoice) as IQBOInvoice;
};

const fetchById = async (qboId: string): Promise<IQBOInvoice> => {
  const response = await axios.get(`${API_BASE_URL}/invoice/fetchById?Id=${qboId}`);
  return response.data.Invoice;
};

const fetchMany = (docNumberList: string[]): Promise<IQBOInvoice[]> => {
  const docNumberListString = docNumberList.map((doc: string) => `'${doc}'`).join(',');
  return new Promise((resolve, reject) => axios.get(`${API_BASE_URL}/invoice/fetchMany?docNumbers=${docNumberListString}`)
    .then((response) => {
      const data = response.data.QueryResponse.Invoice;
      resolve(data);
    })
    .catch((e) => {
      console.log(e);
      reject(e);
    }));
};

const update = async (shipment: IShipment, qboId: string) => {
  const qboInvoice = await fetchById(qboId);
  const data = await shipmentToInvoice(shipment);
  const cleanedUpdateData = omit(data, ['EmailStatus', 'ShipDate', 'PrintStatus', 'Deposit', 'Line', 'MetaData']);
  const updatedLines = [...qboInvoice.Line.filter((l: IQBOSparseInvoiceLine) => (!!l.Description && !l.Description.match(/Credit Card/)) && (l.DetailType !== 'SubTotalLineDetail' && !l.SalesItemLineDetail?.ItemRef?.value.match(/SHIPPING/)))];
  data.Line.forEach((item: IQBOSparseInvoiceLine) => {
    const index = findIndex(updatedLines, (l: IQBOSparseInvoiceLine) => l.LineNum === item.LineNum);
    if (index > -1) updatedLines[index] = item;
    else updatedLines.push(item);
  });
  const updated = {
    ...qboInvoice, ...cleanedUpdateData, Line: updatedLines, MetaData: { ...qboInvoice.MetaData, LastUpdatedTime: qboDateString(new Date(), true) },
  };

  await axios.post(`${API_BASE_URL}/invoice/update`, { ...updated, Id: qboId });
  return qboId;
};

const createStub = async (customer: ICustomerRecord, nextInvoiceNumber: string) => {
  const stubData = {
    ...STUB_INVOICE,
    CustomerRef: {
      name: customer.CompanyName,
      value: customer.Id,
    },
    DocNumber: nextInvoiceNumber,
  };
  const responseData = await axios.post(`${API_BASE_URL}/invoice/create`, stubData);
  return responseData.data.Invoice.Id;
};

const create = async (shipment: IShipment) => {
  const qboId = await createStub(shipment.customer, shipment.shipmentNumber);
  // const data = await shipmentToInvoice(shipment);
  try {
    return await update(shipment, qboId);
  } catch (error: any) {
    // if creating the actual invoice fails, we want to return the invoice ID so we can try again later.
    Modal.error({
      title: 'Error creating invoice',
      content: 'There was an error creating the invoice in QBO—the invoice record was created but the invoice itself failed to post. We will now save this to HELM, and you can try to save again later.',
    });
    return qboId;
  }
};
export default {
  create,
  createStub,
  fetchLast,
  update,
  fetchMany,
};
