import React, { useEffect, useState, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Divider, Radio,
} from 'antd';
import shortid from 'shortid';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { ORDER_ITEMS_DB_COLLECTION, orderItemsAtom, showAllOrderItemsAtom } from 'shared/state/orderState';
import { orderItemEditModalAtom } from 'shared/state/orderItemState';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import { formatPercent, formatPrice } from 'shared/data';
import theme from 'shared/theme';
import { devLog } from 'shared/util/logging';
import useFirebase from 'vendor/Firebase';
import { OrderItemHeader, OrderPartFieldWrapper } from 'pages/ProductionSchedule/Components/styledComponents';
import { cloneDeep } from 'lodash';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import RefreshOrderItemsButton from './Buttons/RefreshOrderItemsButton';
import { OrderItem } from './OrderItem';
import AddOrderItemButton from './Buttons/AddOrderItemButton';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from '../../../../shared/state/routingState';
import ResetAllocationButton from './Buttons/ResetAllocationButton';
import OrderItemsByGeometryButton from './Buttons/OrderItemsByGeometryButton';

const DragListOl = styled.ol`
  min-width: 80vw;
  max-width: 75vw;
  padding: 0;
    
    @media ${theme.device.laptopM} {
        min-width: 65vw;
    }
`;

interface IComponent {
  customerId: string;
  orderCompleted: boolean;
  orderId: string;
  currentOrderType: 'body' | 'neck';
  refreshCallback: (refreshedItems: IOrderItem[]) => void;
  reorderCallback: (reorderedItems: IOrderItem[]) => void;
  salesOrderNumber: string;
  resetAllocationCallback: () => Promise<void>;
}
const OrderItemList = ({
  customerId, currentOrderType, orderCompleted, orderId, refreshCallback, reorderCallback, salesOrderNumber, resetAllocationCallback,
}: IComponent) => {
  const { firestore } = useFirebase();
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [showAllOrderItems, setShowAllOrderItems] = useState<boolean>(orderCompleted);
  const [showOrderItemsContainer, setShowOrderItemsContainer] = useState<boolean>(true);
  const [orderItemsHeaderString, setOrderItemsHeaderString] = useState('Order Items');
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const [modalState, setModalState] = useRecoilState(orderItemEditModalAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);

  const dragProps = {
    onDragEnd: (fromIndex: number, toIndex: number) => {
      const draggedItem = cloneDeep(orderItems[fromIndex]);
      const data: IOrderItem[] = [...orderItems.filter((i: IOrderItem) => i.id !== draggedItem.id)];

      data.splice(toIndex, 0, draggedItem);
      firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: data }).then(() => {
        setOrderItems(data);
        reorderCallback(data);
      });
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    style: { width: '90%' },
  };
  const toggleOrderItems = (e: any) => {
    setShowAllOrderItems(e.target.value);
  };

  // const toggleContainerVisible = (e: any) => {
  //   setShowOrderItemsContainer(!showOrderItemsContainer);
  // };

  const onOrderItemsChange = useCallback((item?: IOrderItem) => {
    const orderItemsSell = orderItems.filter((i) => i)
      .map((o: IOrderItem) => o.unitPrice * (orderCompleted ? o.quantityShipped : o.quantityOpen))
      .reduce((a, b) => a + b, 0);

    const orderMaterialCost = orderItems.filter((i) => i)
      .filter((i) => (orderCompleted ? i.quantityShipped : i.quantityOpen) > 0)
      .map((o: IOrderItem) => {
        const bomCost = o?.bom ? o.bom.map((i: IBomItem) => i.unitCost * i.quantity).reduce((a, b) => a + b, 0) : 0;
        return bomCost * (orderCompleted ? o.quantityShipped : o.quantityOpen) || 0;
      })
      .reduce((a, b) => a + b, 0);

    setOrderItemsHeaderString(`Order Items: ${formatPrice(orderMaterialCost)} BOM value (${formatPercent(orderMaterialCost / orderItemsSell, 2)})`);
    setModalState({ visible: true, item });
  }, [orderItems, orderCompleted]);

  const onItemSave = useCallback((savedItem: IOrderItem) => {
    const updatedItems = [...orderItems];
    const index = updatedItems.findIndex((i) => i.id === savedItem.id);

    if (index !== -1) {
      updatedItems[index] = savedItem;
    } else {
      updatedItems.push(savedItem);
    }

    setOrderItems(updatedItems);
    // Update global state only after save
    refreshCallback(updatedItems);
  }, [orderItems, refreshCallback]);

  const onRefreshOrderItems = (refreshedItems: IOrderItem[]) => {
    firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: refreshedItems }).then(() => {
      setOrderItems(refreshedItems);
      refreshCallback(refreshedItems);
      onOrderItemsChange();
    });
  };

  const onResetAllocation = async (refreshedItems: IOrderItem[]) => {
    await firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: refreshedItems });
    await resetAllocationCallback();
    setOrderItems(refreshedItems);
    return null;
  };

  const onReorderItemsByGeometry = async (reorderedItems: IOrderItem[]) => {
    await firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: reorderedItems });
    setOrderItems(reorderedItems);
  };

  useEffect(() => {
    devLog('OrderItemList', 67, 'Updating order items');
    // onOrderItemsChange(); // <-- This might be the culprit
  }, []);

  // useEffect(() => {
  //   console.log('Modal state:', modalState);
  // }, [modalState]);

  return (
    <>
      <Divider key={shortid.generate()} style={{ display: 'flex' }} orientation="left">
        {orderItemsHeaderString}
      </Divider>
      <OrderPartFieldWrapper key="order-item-list-field-wrapper" expand={showOrderItemsContainer}>
        <OrderItemHeader key="order-item-list-header">
          <Radio.Group
            key="order-item-list-item-type-toggle"
            buttonStyle="solid"
            value={showAllOrderItems}
            onChange={toggleOrderItems}
          >
            <Radio.Button key="order-item-list-show-all-items" value>All items</Radio.Button>
            <Radio.Button key="order-item-list-open-items-only" value={false}>Open items only</Radio.Button>
          </Radio.Group>
          <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
            <AddOrderItemButton key="order-item-list-add-part-button" salesOrderNumber={salesOrderNumber} callback={onOrderItemsChange} />
            <RefreshOrderItemsButton key="refresh-item-list-button" customerId={customerId} orderItems={orderItems} orderType={currentOrderType} callback={onRefreshOrderItems} />
            <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}>
              <OrderItemsByGeometryButton key="order-items-by-geometry" orderItems={orderItems} callback={onReorderItemsByGeometry} />
            </ScopedComponent>
            <ResetAllocationButton key="reset-item-allocation-button" orderItems={orderItems} callback={onResetAllocation} />
          </ScopedComponent>
        </OrderItemHeader>
        {orderItems.filter((o: IOrderItem) => o).length ? (
          <ReactDragListView {...dragProps} enableScroll key="order-item-list-drag-list">
            <DragListOl key="order-item-list-drag-list-ol">
              { orderItems.filter((o: IOrderItem) => (showAllOrderItems || o.quantityOpen > 0)).map((orderItem: IOrderItem, index) => (
                <OrderItem
                  orderItem={orderItem}
                  id={orderItem.id}
                  key={`${orderItem.id}-order-item`}
                  showItem={showAllOrderItems || orderItem.quantityOpen > 0}
                />
              ))}
            </DragListOl>
          </ReactDragListView>
        ) : (
          <p style={{
            fontSize: 16, position: 'relative', top: -8, fontWeight: 'light',
          }}
          >
            No items to display.
          </p>
        )}
      </OrderPartFieldWrapper>
    </>
  );
};

export default React.memo(OrderItemList);
