import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import theme from 'shared/theme';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { customerPartsAtom } from 'shared/state/pricingState';
import { useRecoilValue } from 'recoil';
import { IInventoryPart } from 'shared/types/dbRecords';
import PartNumberSearchSelect from 'pages/Orders/Components/OrderItems/OrderItemDetail/PartNumberSearchSelect';
import { ICustomerPart } from 'shared/types/parts';

const ItemContainer = styled.div`
  background: ${theme.palette.neutral.white};
  // border: 1px solid ${theme.palette.neutral[200]};
  border-radius: 8px;
  // padding: 16px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailColumn = styled(FlexColumn)`
  align-items: flex-start;
  width: 100%;
`;

const DeleteIcon = styled(DeleteFilled)`
  width: 20px;
  height: 20px;
  color: ${theme.palette.error.hue};
  display: block;
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemId = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.neutral[900]};
`;

const ItemDescription = styled.div`
  color: #6b7280;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 1.4;
  word-break: break-word;
  padding: 0 8px;
`;

const DesktopDescriptionWrapper = styled.div`
  display: none;
  @media ${theme.device.laptopL} {
    display: block;
  }
`;

const MobileDescriptionWrapper = styled.div`
  display: block;
  @media ${theme.device.laptopL} {
    display: none;
  }
`;

const ItemDeleteButton = styled(Button)`
  margin-top: 2px;
  height: 28px !important;
  width: 28px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 4px !important;
  color: ${theme.palette.neutral.white};
  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
  }
`;

const ItemEditButton = styled(Button)`
  height: 28px !important; 
`;

const ItemFooter = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
`;

interface BomItemProps {
  id: string;
  description: string;
  quantity: string;
  onDelete: () => void;
  editCallback: (quantity: number) => void;
  partChangeCallback: (part: IInventoryPart) => void;
}

const BomItem: React.FC<BomItemProps> = ({
  id,
  description,
  quantity,
  onDelete,
  editCallback,
  partChangeCallback,
}) => {
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const customerParts = useRecoilValue(customerPartsAtom);
  const [_description, setDescription] = useState(description);
  const [_descriptionPrimary, setDescriptionPrimary] = useState<string|null>(null);
  const [_descriptionSecondary, setDescriptionSecondary] = useState<string|null>(null);
  const [_quantity, setQuantity] = useState(quantity);

  const onPartNumberEdit = (val: string) => {
    const part = inventoryItems.find((item: IInventoryPart) => item.Sku === val) || customerParts.find((item: ICustomerPart) => item.Sku === val);
    if (part) {
      setDescription(part.Description);
    }
    partChangeCallback(part);
  };
  const onQuantityEdit = (val: string) => {
    editCallback(parseFloat(val));
  };

  useEffect(() => {
    const broken = description.split('__');
    setDescriptionPrimary(broken[0]);
    setDescriptionSecondary(broken[1]);
    setDescription(description);
  }, [description]);

  return (
    <ItemContainer>
      <ItemHeader>
        <DetailColumn>
          <FlexRow justify="space-between" align="center" style={{ gap: 8, width: '100%' }}>
            <PartNumberSearchSelect open partNumber={id} changeCallback={onPartNumberEdit} selectWidth="120px" />
            <FlexRow justify="flex-end" align="center" style={{ gap: 8, marginTop: 2 }}>
              <DetailInputWithCallback
                id="part-bom-quantity"
          // label="Qty."
                noLabel
                value={_quantity}
          // inline
                width="64px"
                minWidth="64px"
                callback={(val) => onQuantityEdit(val)}
                noMargin
              />
              <ItemDeleteButton
                onClick={onDelete}
                type="primary"
                icon={<DeleteIcon />}
              />
            </FlexRow>
          </FlexRow>
          <DesktopDescriptionWrapper>
            <ItemDescription style={{ marginBottom: _descriptionSecondary ? 0 : 16 }}>{_description}</ItemDescription>
          </DesktopDescriptionWrapper>
          <MobileDescriptionWrapper>
            <ItemDescription style={{ marginBottom: _descriptionSecondary ? 0 : 16 }}>{_descriptionPrimary}</ItemDescription>
            {_descriptionSecondary && <ItemDescription style={{ marginLeft: 4 }}>{_descriptionSecondary}</ItemDescription>}
          </MobileDescriptionWrapper>
        </DetailColumn>
      </ItemHeader>
    </ItemContainer>
  );
};

export default BomItem;
