'use client';

import type React from 'react';
import {
  useContext, useEffect, useRef, useState, useMemo,
} from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find, includes, noop } from 'lodash';
import firebase from 'firebase';
import type { IBomItem, IOrderItem, IInventoryPart } from 'shared/types/dbRecords';
import { customerPartsAtom, partEditModeAtom } from 'shared/state/pricingState';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import {
  bomEditedAtom,
  PART_BOM_COLLECTION,
  partBomItemsAtom,
  PART_VIEWER_COLLECTION,
} from 'shared/state/partViewState';
import { orderItemEditModalAtom, orderItemFormStateAtom, orderItemsAtom } from 'shared/state/orderItemState';
import { stripPartNotes } from 'shared/text';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import DetailActiveSelectorWithCallback from 'shared/components/Input/DetailActiveSelectorWithCallback';
import useFirebase from 'vendor/Firebase';
import type { AuthContextType } from 'vendor/Firebase/AuthProvider';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import UniversalDensityCalculatorByPart from 'pages/Util/DensityCalculator/UniversalDensityCalculatorByPart';
import theme from 'shared/theme';
import DensityCalcButton from 'pages/PartDetail/Components/Buttons/DensityCalcButton';
import { Calculate } from '@styled-icons/material';
import { ICustomerPart } from 'shared/types/parts';
import customer from 'shared/data/QBO/customer';
import { configToDescription } from 'shared/partParser/util';
import calculatePartDensity from 'shared/data/densityCalculator';
import PartBomList from '../../../PartDetail/Components/PartBom/PartBomList';
import PartNumberSearchSelect from './OrderItemDetail/PartNumberSearchSelect';
import * as S from './styles';

const StyledModal = styled(Modal)`
  width: 85vw !important;
  
  &.ant-modal-wrap {
    outline: none;
  }
  
  .ant-modal-content {
    border-radius: 16px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    position: relative; // Ensure proper stacking context
  }
  
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
    padding: 24px 32px;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;
  }
  
  .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .ant-modal-body {
    padding: 0;
    display: flex;
    height: var(--viewport-height, calc(100vh - 300px));
    max-height: 800px;
  }
  
  .ant-modal-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0 0 16px 16px;
    padding: 20px 32px;
    border-top: 1px solid #f0f0f0;
    background: #fff;
  }

  .ant-input, .ant-input-number {
    border-radius: 8px;
    border-color: #e5e7eb;
    
    &:hover, &:focus {
      border-color: #2563eb;
      box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
    }
  }

  .ant-input-number-handler-wrap {
    border-radius: 0 8px 8px 0;
  }

  textarea.ant-input {
    border-radius: 8px;
    min-height: 120px;
  }

  .ant-btn {
    border-radius: 8px;
    height: 40px;
    padding: 0 20px;
    font-weight: 500;
    
    &.ant-btn-primary {
      background: #2563eb;
      border-color: #2563eb;
      
      &:hover {
        background: #1d4ed8;
        border-color: #1d4ed8;
      }
    }
  }
`;

const ModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 32px;
  overflow-y: auto;
`;

const Sidebar = styled.div`
  width: 50%; // Increased from 380px
  background: #f8fafc;
  border-left: 1px solid #e5e7eb;
  padding: 0 24px 24px 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 0; // Add to ensure proper flex behavior
`;

interface FormSectionProps {
  flex?: number;
}

const FormSection = styled.div<FormSectionProps>`
  margin-bottom: 32px;
  flex: ${({ flex = 1 }) => flex};
  min-height: 0;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SkuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  // margin-bottom: 24px;
  @media ${theme.device.laptopL} {
    flex-direction: row;
    gap: 16px;
  }
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0 0 16px 0;
`;

const FormRow = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const OrderItemsRow = styled(FormRow)`
  margin-bottom: 0;
`;

const NotesRow = styled(FormRow)`
  margin-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  @media ${theme.device.laptopL} {
    flex-direction: row;
  }
`;

const FormColumn = styled.div<{ width?: string }>`
  flex: ${(props) => props.width || 1};
`;

const ToggleGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  gap: 12px;
  padding: 16px;
  margin-top: -16px;
  background: #f9fafb;
  border-radius: 12px;
  margin-bottom: 24px;
  @media ${theme.device.laptopL} {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
`;

const StyledDetailInput = styled(DetailInputWithCallback)`
  .ant-input {
    background: #fff;
  }
`;

const StyledDetailNumberInput = styled(DetailNumberInputWithCallback)`
  .ant-input-number {
    background: #fff;
  }
`;

const Spacer = styled.div`
  width: 50%;
  height: 1px;
  @media ${theme.device.laptopL} {
    height: 50%;
    width: 1px;
  }
  background-color: ${theme.palette.neutral[200]};
`;

const DensityCalcLinkButton = styled(Button)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 32px !important;
  `;

const DensityCalcWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 12px;
`;

interface IProps {
  orderId: string
  orderComplete: boolean
  onItemSaveCallback: (orderItem: IOrderItem) => void
  onNextItemCallback: () => void
  onPreviousItemCallback: () => void
}

interface IOrderItemWithCost extends IOrderItem {
  unitCost: number;
  totalCost: number;
}

interface IFormState {
  Sku: string;
  quantity: number;
  notes: string;
  price: number;
  description: string;
  internalNotes: string;
}

const OrderItemEditModal: React.FC<IProps> = ({
  orderId, orderComplete, onItemSaveCallback, onNextItemCallback, onPreviousItemCallback,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const [showDensityCalculator, setShowDensityCalculator] = useState(false);
  const [modalState, setModalState] = useRecoilState(orderItemEditModalAtom);
  const [formState, setFormState] = useRecoilState<IFormState>(orderItemFormStateAtom);
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);
  const [bomEdited, setBomEdited] = useRecoilState(bomEditedAtom);
  const customerParts = useRecoilValue(customerPartsAtom);
  const [customerPart, setCustomerPart] = useState<ICustomerPart|IInventoryPart|undefined|null>(null);
  const [targetDensity, setTargetDensity] = useState<number | null>(null);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const partBomDbString = useRecoilValue(PART_BOM_COLLECTION);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const setPartEditMode = useSetRecoilState(partEditModeAtom);
  const orderItems = useRecoilValue(orderItemsAtom);

  // Store last focused element before modal opens
  const lastFocusedElement = useRef<HTMLElement | null>(null);

  // Find current item index
  const currentItemIndex = useMemo(() => {
    if (!modalState.item) return -1;
    const index = orderItems.findIndex((item) => item.id === modalState.item?.id);
    return index;
  }, [modalState.item, orderItems]);

  // Determine if next/previous buttons should be disabled
  const isFirstItem = useMemo(() =>
    // Only disable if we have items and this is the first one
    orderItems.length > 0 && currentItemIndex === 0,
  [currentItemIndex, orderItems.length]);

  const isLastItem = useMemo(() =>
    // Only disable if we have items and this is the last one
    orderItems.length > 0 && currentItemIndex === orderItems.length - 1,
  [currentItemIndex, orderItems.length]);

  useEffect(() => {
    if (modalState.visible) {
      // Store the currently focused element when modal opens
      lastFocusedElement.current = document.activeElement as HTMLElement;
    } else if (lastFocusedElement.current) {
      // Restore focus when modal closes
      lastFocusedElement.current.focus();
    }
  }, [modalState.visible]);

  const partType = (modalState.item?.Description || '').match(/^[G|B]N/) ? 'neck' : 'body';

  const handleClose = async () => {
    if (bomEdited) {
      Modal.confirm({
        title: 'Apply BOM update to part?',
        content: 'Would you like to update the part BOM with the changes made here?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          if (modalState.item) {
            updatePartBom({ ...modalState.item, bom: bomItems });
            onItemSaveCallback({
              ...modalState.item,
              Sku: formState.Sku,
              quantityOrdered: formState.quantity,
              unitPrice: formState.price,
              Description: formState.description,
              internalNotes: formState.internalNotes,
              notes: formState.notes,
              bom: bomItems,
            } as IOrderItemWithCost);
          }
        },
        onCancel: () => {
          setModalState({ visible: false, item: null });
          if (modalState.item) {
            onItemSaveCallback({
              ...modalState.item,
              Sku: formState.Sku,
              quantityOrdered: formState.quantity,
              unitPrice: formState.price,
              Description: formState.description,
              internalNotes: formState.internalNotes,
              notes: formState.notes,
              bom: bomItems,
            } as IOrderItemWithCost);
          }
        },
      });
    } else {
      if (modalState.item) {
        onItemSaveCallback({
          ...modalState.item,
          Sku: formState.Sku,
          quantityOrdered: formState.quantity,
          unitPrice: formState.price,
          Description: formState.description,
          internalNotes: formState.internalNotes,
          notes: formState.notes,
          bom: bomItems,
        } as IOrderItemWithCost);
      }
      setModalState({ visible: false, item: null });
    }
  };

  const updatePartBom = (item: IOrderItem) => {
    firestore
      .collection(partBomDbString)
      .doc(item.Sku)
      .set({ bom: item.bom })
      .then(() => {
        setBomEdited(false);
      });
  };

  const onPartNumberChange = async (partNumber: string) => {
    const isCustomerPart = !!partNumber.match(/[A-Z]{5}_[0-9]{5}/);
    const itemList: IInventoryPart[] | ICustomerPart[] = isCustomerPart ? customerParts : inventoryItems;
    const part = find(itemList, (p) => partNumber === p.Sku);
    const description = isCustomerPart ? configToDescription((part as ICustomerPart)?.config) : part?.Description;

    if (part) {
      const doc = await firestore.collection(partBomDbString).doc(part.Sku).get();
      const data = doc.data();

      const partNotes = part.showNotesOnOrder && part.notes ? part.notes : '';
      const newOrderItemNotes = `${partNotes}${part.oneTimeNote || ''}`.trim();
      const existingCustomNotes = stripPartNotes(formState.notes, part.notes || '');
      const notes = `${newOrderItemNotes}${existingCustomNotes ? `\n${existingCustomNotes}` : ''}`.trim();

      setFormState({
        ...formState,
        Sku: part.Sku,
        notes,
        description: description || '',
        price: part.price || part.UnitPrice,
        internalNotes: part.internalNotes || '',
      });

      if (data?.bom) {
        setBomItems(data.bom as IBomItem[]);
      }

      if (part.oneTimeNote) {
        firestore.collection(partViewerDbString).doc(part.Sku).update({ oneTimeNote: null });
      }

      if (isCustomerPart) {
        setCustomerPart(part);
      }
    }
  };

  const openCalc = (e: any) => {
    e.preventDefault();
    setShowDensityCalculator(true);
  };

  const closeCalc = (e: any) => {
    e.preventDefault();
    setShowDensityCalculator(false);
  };

  const onBomChange = (_bomItems: IBomItem[]) => {
    setBomItems(_bomItems);
  };

  useEffect(() => {
    if (modalState.item) {
      setPartEditMode(true);
      setFormState({
        Sku: modalState.item.Sku || '',
        quantity: modalState.item.quantityOrdered,
        notes: modalState.item.notes,
        price: modalState.item.unitPrice,
        description: modalState.item.Description,
        internalNotes: modalState.item.internalNotes || '',
      });

      const part = find(customerParts, (p) => p.Sku === modalState?.item?.Sku);
      setCustomerPart(part);
      setBomItems(modalState.item.bom);
    }
  }, [modalState.item, setFormState, setBomItems]); // Removed modalState.visible from dependencies

  const DensityDisplay = () => {
    // console.log('customerPart', customerPart);
    if (!customerPart) return null;

    if (!includes(['BB', 'GB'], customerPart.type)) return null;

    const density = calculatePartDensity(customerPart as ICustomerPart)?.density;

    return (
      <DensityCalcWrapper>
        <StyledDetailInput
          label="Volume"
          value={customerPart.volume}
          isLabel
          disabled
          callback={noop}
          width="80px"
          minWidth="80px"
        />
        <StyledDetailInput
          label="Target Density"
          value={density}
          isLabel
          disabled
          callback={noop}
          width="80px"
          minWidth="80px"
        />
        <DensityCalcLinkButton type="ghost" onClick={openCalc}>
          Calculator
          <Calculate style={{ width: 20, marginLeft: 8, marginBottom: 1 }} />
        </DensityCalcLinkButton>
      </DensityCalcWrapper>
    );
  };

  if (!modalState.item) return null;

  return (
    <>
      {modalState.item && (
        <S.CalcDrawer
          key={`${modalState.item.Sku}-density-calc-drawer`}
          placement="right"
          width="calc(100% - 92px)"
          closable
          open={showDensityCalculator}
          title="Mini Density Calculator"
          onClose={closeCalc}
        >
          <UniversalDensityCalculatorByPart part={modalState.item.Sku} />
        </S.CalcDrawer>
      )}
      <StyledModal
        title={modalState.item?.Sku ? `Edit order item: ${modalState.item.Sku}` : 'Add part to order'}
        open={modalState.visible}
        onCancel={() => setModalState({ visible: false, item: null })}
        onOk={handleClose}
        destroyOnClose
        footer={[
          <div style={{ marginRight: 'auto' }}>
            <Button key="previous" onClick={onPreviousItemCallback} disabled={isFirstItem}>
              Previous
            </Button>
            <Button key="next" type="primary" onClick={onNextItemCallback} disabled={isLastItem}>
              Next
            </Button>
          </div>,
          <Button key="cancel" onClick={() => setModalState({ visible: false, item: null })}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleClose}>
            Save Changes
          </Button>,
        ]}
      >
        <ModalLayout>
          <MainContent>
            <FormSection>
              <SectionTitle>Part Details</SectionTitle>
              <SkuWrapper>
                <PartNumberSearchSelect
                  open={!orderComplete}
                  partNumber={modalState.item.Sku}
                  changeCallback={onPartNumberChange}
                />
                <FormColumn>
                  <StyledDetailInput
                    id="part-details-description"
                    label="Description"
                    placeholder="e.g., GB_Tele..."
                    value={formState.description}
                    callback={(val) => setFormState({ ...formState, description: val })}
                  // blurCallback={(val) => setFormState({ ...formState, description: val })}
                    extend
                    isLabel={false}
                  />
                  {customerPart && <DensityDisplay />}
                </FormColumn>
              </SkuWrapper>
            </FormSection>

            <FormSection flex={0}>
              <SectionTitle>Order Information</SectionTitle>
              <FormRow>
                <ToggleGroup>
                  <OrderItemsRow>
                    <StyledDetailNumberInput
                      label="Quantity"
                      value={formState.quantity}
                      callback={(val) => setFormState({ ...formState, quantity: val })}
                      id="quantity"
                      placeholder="Enter quantity"
                      width="140px"
                    />
                    <StyledDetailNumberInput
                      label="Unit Price"
                      value={formState.price}
                      callback={(val) => setFormState({ ...formState, price: val })}
                      disabled={orderComplete}
                      id="price"
                      placeholder="Enter price"
                      width="140px"
                      margin="0 24px 0 0"
                    />
                  </OrderItemsRow>

                  <Spacer />

                  <OrderItemsRow style={{ marginBottom: 12 }}>
                    <DetailActiveSelectorWithCallback
                      id="part-details-house-sample"
                      initialState={modalState.item.houseSample}
                      checkedLabel="YES"
                      uncheckedLabel="NO"
                      callback={(val) =>
                        setModalState({
                          ...modalState,
                          item: { ...modalState.item!, houseSample: val },
                        })}
                      componentLabel="House sample?"
                      componentMargin="0"
                      disabled={false}
                    />

                    <DetailActiveSelectorWithCallback
                      id="part-details-materials-confirmed"
                      initialState={!!modalState.item.materialsConfirmed}
                      checkedLabel="YES"
                      uncheckedLabel="NO"
                      callback={(val) => {
                        const timestamp = val ? firebase.firestore.Timestamp.now() : null;
                        setModalState({
                          ...modalState,
                          item: {
                            ...modalState.item!,
                            materialsConfirmed: timestamp,
                            materialsConfirmedBy: val ? currentUser.email : null,
                          },
                        });
                      }}
                      componentLabel="Materials confirmed?"
                      componentMargin="0"
                      disabled={false}
                    />
                  </OrderItemsRow>
                </ToggleGroup>
              </FormRow>
            </FormSection>

            <FormSection>
              <SectionTitle>Notes</SectionTitle>
              <NotesRow>
                <DetailTextAreaWithCallback
                  id="part-details-notes"
                  label="Customer Notes"
                  value={formState.notes}
                  changeCallback={(val) => setFormState({ ...formState, notes: val })}
                  blurCallback={(val) => setFormState({ ...formState, notes: val })}
                  style={{ width: '100%' }}
                />
                <DetailTextAreaWithCallback
                  id="part-internal-notes"
                  label="Internal Notes"
                  placeholder="Add notes that will not be visible to the customer"
                  value={formState.internalNotes}
                  changeCallback={(val) => setFormState({ ...formState, internalNotes: val })}
                  blurCallback={(val) => setFormState({ ...formState, internalNotes: val })}
                  style={{ width: '100%' }}
                />
              </NotesRow>
            </FormSection>
          </MainContent>

          <Sidebar>
            <FormSection>
              <PartBomList
                open={!orderComplete}
                partType={partType}
                partId={modalState.item.Sku}
                partDescription={modalState.item.Description}
                partPrice={modalState.item.unitPrice}
                bom={modalState.item.bom}
                onBomChange={onBomChange}
              />
            </FormSection>
          </Sidebar>
        </ModalLayout>
      </StyledModal>
    </>
  );
};

export default OrderItemEditModal;
