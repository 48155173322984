import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Button, DatePicker, Divider, Dropdown, InputNumber, Menu, Modal, Radio,
} from 'antd';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { FlexRow } from 'shared/containers/FlexContainer';

import { salesOrderEditedAtom } from 'shared/state/routingState';
import { DownOutlined } from '@ant-design/icons';
import { BODY_DATA_PATH, NECK_DATA_PATH } from 'shared/state/partViewState';
import { useTestDataAtom } from 'shared/state/pricingState';
import { IUnitOption } from 'shared/types/pricingTool';
import { isProduction, resolveRealtimePath } from 'shared/util';
import {
  AddRunnerColumn, RunnerHeaderRow, RunnerPageTitle, DeleteSalesOrderButton, CopySalesOrderButton,
} from '../../ProductionSchedule/styledComponents';
import {
  DescInput,
  FieldWrapper,
  FieldRow,
  InputCaption,
  SOInput,
} from '../../ProductionSchedule/Components/styledComponents';

const _ = require('lodash');
const shortid = require('shortid');
const qs = require('qs');

export default () => {
  const { database } = useFirebase();
  const [partName, setPartName] = useState('');
  const [price, setPrice] = useState(0);
  const [cost, setCost] = useState('0');
  const [partNumber, setPartNumber] = useState(1);
  const [type, setType] = useState('');
  const [edited, setEdited] = useRecoilState(salesOrderEditedAtom);
  const isTest = useRecoilValue(useTestDataAtom);
  const [optionMenu, setOptionMenu] = useState<React.ReactElement<any>>();
  const [selectedOptionType, setSelectedOptionType] = useState('');

  const { partType, optionType, optionId } = qs.parse(window.location.search.replace('?', ''));
  const partDb = partType.match(/neck/i) ? NECK_DATA_PATH : BODY_DATA_PATH;

  const addRecordToOptionList = (dbPath, newRecord, callback) => {
    database.ref(dbPath).once('value').then((snap) => {
      const data = snap.val() as IUnitOption[];
      database.ref(dbPath).set([...data, newRecord]).then(() => {
        callback();
      });
    });
  };

  const updateRecordInOptionList = (dbPath, newRecord, callback) => {
    database.ref(dbPath).once('value').then((snap) => {
      const data = snap.val() as IUnitOption[];
      const oldRecordIndex = _.findIndex(data, (r: IUnitOption) => r.id === newRecord.id);
      if (oldRecordIndex === -1) {
        Modal.error({
          title: 'Something went wrong, please try again. If issue persists, let Keith know.',
        });
      } else {
        const _record = {
          id: data[oldRecordIndex].id,
          name: newRecord.name,
          type: newRecord.type,
          partNumber: newRecord.partNumber,
          price: newRecord.price,
          cost: newRecord.cost,
        };

        const newRecords = [...data.slice(0, oldRecordIndex), _record, ...data.slice(oldRecordIndex + 1)];

        database.ref(dbPath).set(newRecords).then(() => {
          if (!dbPath.match(/Test|Local/)) {
            let testDbPath = dbPath.replace(/body/, 'bodyTest');
            if (partType === 'neck') testDbPath = dbPath.replace(/neck/, 'neckTest');

            database.ref(testDbPath).once('value').then((devSnap) => {
              const testData = devSnap.val() as IUnitOption[];
              const testRecordIndex = _.findIndex(data, (r: IUnitOption) => r.id === _record.id);
              const newTestRecord = testRecordIndex === -1 ? _record : {
                ...testData[testRecordIndex],
                name: _record.name,
                type: _record.type,
                partNumber: _record.partNumber,
              };
              const newTestRecords = [...testData.slice(0, testRecordIndex), newTestRecord, ...testData.slice(testRecordIndex + 1)];
              database.ref(testDbPath).set(newTestRecords).then(() => {
                callback();
              });
            });
          } else {
            callback();
          }
        });
      }
    });
  };

  const handlers = {
    change: {
      partName: (e: any) => {
        if (!edited) setEdited(true);
        setPartName(e.target.value);
      },
      price: (e: any) => {
        if (!edited) setEdited(true);
        if (!e.target.value) setPrice(0);
        else setPrice(parseFloat(e.target.value));
      },
      cost: (e: any) => {
        if (!edited) setEdited(true);
        if (!e.target.value) setCost(0);
        else setCost(e.target.value);
      },
      partNumber: (e: any) => {
        if (!edited) setEdited(true);
        if (!e.target.value) setPartNumber(1);
        else setPartNumber(parseInt(e.target.value, 10));
      },
    },
    delete: (e: any) => {
      e.preventDefault();
      Modal.confirm({
        content: 'Are you sure you want to delete this option?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          database.ref(`/${resolveRealtimePath(partDb)}/options/${optionType}/items`).once('value').then((snap) => {
            const data = snap.val() as IUnitOption[];
            database.ref(`/${resolveRealtimePath(partDb)}/options/${optionType}/items`).set(data.filter((i: IUnitOption) => i.id !== optionId)).then(() => {
              if (!isTest) {
                database.ref(`/${resolveRealtimePath(partDb, 'Test')}/options/${optionType}/items`).set(data.filter((i: IUnitOption) => i.id !== optionId)).then(() => {
                  window.location.href = '/pricing/edit';
                });
              } else {
                window.location.href = '/pricing/edit';
              }
            });
          });
        },
      });
    },
    copy: (e: any) => {
      const newId = shortid.generate();
      const record = {
        id: newId,
        price,
        type: optionType,
        selected: false,
        name: `Copy of ${partName}`,
      };
      addRecordToOptionList(`/${resolveRealtimePath(partDb)}/options/${optionType}/items`, record, () => {
        if (!isTest) {
          addRecordToOptionList(`/${resolveRealtimePath(partDb, 'Test')}/options/${optionType}/items`, record, () => {
            window.location.href = `/pricing/option/edit?partType=${partType}&optionType=${optionType}&optionId=${newId}`;
          });
        } else {
          window.location.href = `/pricing/option/edit?partType=${partType}&optionType=${optionType}&optionId=${newId}`;
        }
      });
    },
    update: (e: any) => {
      e.preventDefault();
      const record = {
        id: optionId,
        price,
        cost: parseFloat(cost || '0'),
        partNumber,
        type: optionType,
        selected: false,
        name: partName,
      };

      // @ts-ignore
      if (optionType.match(/labor/i)) record.rate = 'hr';
      updateRecordInOptionList(`/${resolveRealtimePath(partDb)}/options/${optionType}/items`, record, () => {
        window.location.href = '/pricing/edit';
      });
    },
    // eslint-disable-next-line consistent-return
    submit: (e: any) => {
      e.preventDefault();
      if (optionId) return handlers.update(e);

      let errorText = null;
      if (partName.length === 0) {
        errorText = 'Please add a label for this option';
      }
      if (!price) {
        const unit = optionType.match(/labor/i) ? 'rate' : 'price';
        errorText = `Please add a ${unit} for this option`;
      }
      if (errorText) {
        return Modal.error({
          content: errorText,
          okText: 'OK',
        });
      }
      const record = {
        id: shortid.generate(),
        price,
        cost: parseFloat(cost || '0'),
        partNumber,
        type: selectedOptionType,
        selected: false,
        name: partName,
      };

      addRecordToOptionList(`/${resolveRealtimePath(partDb)}/options/${optionType}/items`, record, () => {
        if (isProduction()) {
          addRecordToOptionList(`/${resolveRealtimePath(partDb)}Test/options/${optionType}/items`, record, () => {
            window.location.href = '/pricing/edit';
          });
        } else {
          window.location.href = '/pricing/edit';
        }
      });
    },
  };

  useEffect(() => {
    database.ref(`/${resolveRealtimePath(partDb)}/options`).once('value').then((snap) => {
      const data = snap.val();
      const recordTypes = Object.entries(data).map((c: any) => ({ type: c[0], label: c[1].label }));
      setOptionMenu(
        <Menu
          onClick={(e: any) => {
            const record = _.find(recordTypes, (o: {type: string, label: string}) =>
              o.type === e.key.replace('-menu-option', ''));
            if (!record) return;
            setType(record.label);
            setSelectedOptionType(record.type);
          }}
        >
          <Menu.ItemGroup title="Option types">
            {Object.entries(data).map((c: any) => <Menu.Item key={`${c[0]}-menu-option`}>{c[1].label}</Menu.Item>)}
          </Menu.ItemGroup>
        </Menu>,
      );

      if (optionId) {
        const optionSet = _.find(Object.entries(data), (i: any) => i[0] === optionType);
        if (!optionSet) return;

        const o = _.find(optionSet[1].items, (i: IUnitOption) => i.id === optionId) as IUnitOption;
        if (!o) return;

        setPartName(o.name);
        setPartNumber(o.partNumber || partNumber);
        setCost(o.cost);
        setPrice(o.price);
        setType(_.find(recordTypes, (i: { type: string, label: string }) => i.type === o.type).label);
        setSelectedOptionType(_.find(recordTypes, (i: { type: string, label: string }) => i.type === o.type).label);
      } else {
        const currentType = _.find(recordTypes, (t: { type: string, label: string }) => t.type === optionType);
        if (!currentType) return;
        setType(currentType.label);
        setSelectedOptionType(currentType.type);
      }
    });
  }, []);

  return (
    <ConfigurationCol>
      <RunnerHeaderRow>
        <RunnerPageTitle>{`${optionId ? 'Edit' : 'Add'} Part Option`}</RunnerPageTitle>
        <FlexRow style={{ flexGrow: 2, marginTop: 4, gap: 20 }} justify="flex-start">
          <Button type="primary" shape="round" onClick={handlers.submit}>{optionId ? 'Save' : 'Create'}</Button>
          {optionId
          && <CopySalesOrderButton type="primary" onClick={handlers.copy}>Copy</CopySalesOrderButton>}
          {!!optionId
          && <DeleteSalesOrderButton type="primary" onClick={handlers.delete}>Delete</DeleteSalesOrderButton>}
        </FlexRow>
      </RunnerHeaderRow>
      <AddRunnerColumn>
        <Divider key={shortid.generate()} orientation="left">Option type</Divider>
        <Dropdown
          disabled={!!optionId}
          placement="bottomLeft"
          // @ts-ignore
          overlay={optionMenu}
          trigger={['click']}
          overlayClassName="part-dropdown-menu"
        >
          <Button key={shortid.generate()}>
            {type}
            {' '}
            <DownOutlined />
          </Button>
        </Dropdown>
        <Divider key={shortid.generate()} orientation="left">Option details</Divider>
        <FieldRow>
          <FieldWrapper style={{ width: 100 }}>
            <InputCaption>Part number</InputCaption>
            <DescInput
              onChange={handlers.change.partNumber}
              defaultValue="1"
              value={partNumber}
              placeholder="e.g., 1500006"
            />
          </FieldWrapper>
          <FieldWrapper style={{ width: 456 }}>
            <InputCaption>Option name</InputCaption>
            <DescInput
              onChange={handlers.change.partName}
              defaultValue=""
              value={partName}
              placeholder="e.g., No frets"
            />
          </FieldWrapper>
        </FieldRow>
        <FieldRow>
          <FieldWrapper>
            <InputCaption>{optionType.match(/labor/i) ? 'Rate' : 'Price'}</InputCaption>
            <SOInput
              onChange={handlers.change.price}
              defaultValue=""
              value={price || ''}
              placeholder="e.g., 50"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Cost</InputCaption>
            <SOInput
              onChange={handlers.change.cost}
              defaultValue="0"
              value={cost}
              placeholder="e.g., 50"
            />
          </FieldWrapper>
        </FieldRow>
      </AddRunnerColumn>
    </ConfigurationCol>
  );
};
