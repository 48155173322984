import React, { useContext, useState } from 'react';
import firebase from 'firebase';
import {
  find, includes, omit,
} from 'lodash';
import { CSVDownload } from 'react-csv';
import {
  Progress, Modal, Tooltip,
  Menu, Button,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { CaretDown } from '@styled-icons/fa-solid';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import theme from 'shared/theme';
import {
  INVENTORY_ADJUSTMENT_COLLECTION, INVENTORY_ITEMS_COLLECTION, INVENTORY_SNAPSHOT_COLLECTION, inventoryItemsAtom, showQuickbooksSyncModalAtom,
} from 'shared/state/inventoryState';
import { productCodesAtom } from 'shared/state/utilState';
import { VENDOR_COLLECTION } from 'shared/state/vendorState';
import { IVendor } from 'shared/types/vendor';
import { IInventoryPart, IUploadItem, IProductCode } from 'shared/types/dbRecords';
import QBOItem from 'shared/data/QBO/item';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import { reorderPointNotification } from 'shared/messaging';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from 'shared/state/messageState';
import { IQBOItem } from 'shared/types/qbo';
import { UploadButton } from 'shared/styledComponents/inputs';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { userFirstName } from 'shared/text';
import AdjustQBOInventoryModal from '../Panels/AdjustQBOInventoryModal';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const ModalButtonGroup = styled(Button.Group)`
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: center;
  gap: 16px;
`;
const ModalButton = styled(Button)`
  flex: 1;
  border-radius: 8px !important;
  &:last-child {
    border-left-color: ${theme.palette.neutral[200]} !important;
  }
  &:hover {
    &:last-child {
      border-left-color: ${theme.palette.primary.hue} !important;
    }
  }
`;
const SaveSpinner = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-left: 32px;

  border-radius: 50%;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};

  animation: ${rotation} 1s linear infinite;
`;

interface IComponent {
  uploadTypeCallback: (type: 'items' | 'eom') => void;
}

const ImportInventoryButton = ({ uploadTypeCallback }: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const inventoryItemsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const inventoryAdjustmentsDbString = useRecoilValue(INVENTORY_ADJUSTMENT_COLLECTION);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const productCodes = useRecoilValue(productCodesAtom);
  const vendorsDbString = useRecoilValue(VENDOR_COLLECTION);
  const inventorySnapshotsDbString = useRecoilValue(INVENTORY_SNAPSHOT_COLLECTION);
  const setShowQboSyncModal = useSetRecoilState(showQuickbooksSyncModalAtom);

  const [processedPercent, setProcessedPercent] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [qboCsvData, setQboCsvData] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onClickDownload = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      setQboCsvData([]);
      Modal.confirm({
        title: 'Refresh Inventory Data',
        content: 'Reload the page to see changes?',
        onOk: () => {
          window.location.reload();
        },
      });
    }, 1000);
  };

  const onUpload = (file: RcFile, isEomCount?: boolean) => {
    uploadTypeCallback(isEomCount ? 'eom' : 'items');
    const reader = new FileReader();
    const adjustmentDate = new Date();
    if (isEomCount) {
      // if this is EOM, set date to last possible moment of previous month.
      adjustmentDate.setDate(0);
      adjustmentDate.setHours(23, 59, 59, 999);
    }

    reader.onload = async (e: any) => {
      const allQBOInventory = await QBOItem.fetchAllInventoryParts();
      const vendorDocs = await firestore.collection(vendorsDbString).get();
      const vendors = vendorDocs.docs.map((d) => d.data());
      const shopSupplyProductCode = find(productCodes, (p: IProductCode) => p.productCode === 'SHOPSUP');
      const data = e.target.result
        .split('\n')
        .slice(1) // discard headers
        .map((d: string) => d.trim().replace(/"/g, '').split(','))
        .map((d: string[]) => {
          const Sku = parseFloat(d[DATA_COLUMNS.SKU]).toString();
          const ProductCode = find(productCodes, (p) => p.productCode === d[DATA_COLUMNS.PRODUCT_CODE]);
          const vendor = find(vendors, (v: IVendor) => v.DisplayName === d[DATA_COLUMNS.VENDOR]) as IVendor;
          const PurchaseCost = Number.isNaN(parseFloat(d[DATA_COLUMNS.PURCHASE_COST])) ? 0 : parseFloat(d[DATA_COLUMNS.PURCHASE_COST]);
          const UnitPrice = Number.isNaN(parseFloat(d[DATA_COLUMNS.UNIT_PRICE])) ? 0 : parseFloat(d[DATA_COLUMNS.UNIT_PRICE]);
          const reorderQty = Number.isNaN(parseFloat(d[DATA_COLUMNS.REORDER_QTY])) ? 0 : parseFloat(d[DATA_COLUMNS.REORDER_QTY]);

          const qboItem = find(allQBOInventory, (i: IQBOItem) => i.Sku === Sku) as IQBOItem;
          /*
            QtyOnHand is a special case, because if we are uploading a counted item, the value will be in the "QTY_COUNTED" column.
            If we are uploading a sheet for some other reason, the value of "QTY_ON_HAND" needs to be used. Ultimately,
            the "QTY_COUNTED" column takes precedence over "QTY_ON_HAND" so resolve the value in that order.
           */
          let QtyOnHand = 0;
          const qtyOnHand = parseFloat(d[DATA_COLUMNS.QTY_ON_HAND]);
          const qtyCounted = parseFloat(d[DATA_COLUMNS.QTY_COUNTED]);
          if (!Number.isNaN(qtyOnHand)) QtyOnHand = qtyOnHand;
          if (!Number.isNaN(qtyCounted)) QtyOnHand = qtyCounted;

          const uploadItem = {
            Active: includes(['Y', 'YES', 'FOR SURE', 'FUCKING TOTALLY', 'TRUE'], d[DATA_COLUMNS.ACTIVE]),
            Sku,
            Name: Sku,
            FullyQualifiedName: Sku,
            Description: d[DATA_COLUMNS.DESCRIPTION] || 0,
            ProductCode: ProductCode || shopSupplyProductCode,
            PurchasingUnit: d[DATA_COLUMNS.PURCHASE_UNIT] || 'EA',
            PurchaseCost,
            UnitPrice,
            QtyOnHand,
            reorderQty,
            adjustmentQuantity: QtyOnHand - (qboItem?.QtyOnHand || 0),
          } as IUploadItem;
          if (vendor) {
            uploadItem.PrefVendorRef = { name: vendor.DisplayName, value: vendor.Id };
          }
          return uploadItem;
        });

      const partCount = data.length;
      let processedCount = 0;
      setUploadInProgress(true);

      const finishUp = () => {
        setProcessedPercent(Math.ceil((processedCount / partCount) * 100));
        if (processedCount === partCount) {
          if (isEomCount) {
            takeInventorySnapshot(data).then(() => {
              setShowQboSyncModal(true);
            });
          } else {
            setShowQboSyncModal(true);
          }
          setTimeout(() => {
            setUploadInProgress(false);
          }, 2000);
        }
      };

      data.forEach((o: IUploadItem) => {
        const part = find(inventoryItems, (p: IInventoryPart) => p.Sku === o.Sku);
        if (!part) {
          processedCount += 1;
          finishUp();
        } else {
          const helmUpdateObject = omit(o, ['adjustmentQuantity']);
          // @ts-ignore -- we are handling the case where part is undefined above.
          firestore.collection(inventoryItemsDbString).doc(part.Sku).update({ ...helmUpdateObject }).then(() => {
            firestore.collection(inventoryAdjustmentsDbString).doc(part.Sku).set({
              Sku: part.Sku,
              QtyOnHand: o.QtyOnHand,
              adjustmentQuantity: o.QtyOnHand - part.QtyOnHand,
              timestamp: new firebase.firestore.Timestamp(adjustmentDate.getTime(), 0),
              Type: `${isEomCount ? `EOM Inventory Adjustment - ${userFirstName(currentUser.email)}` : `Manual Inventory Adjustment - ${userFirstName(currentUser.email)}`}`,
            });
            processedCount += 1;
            if (o.QtyOnHand <= part.reorderQty && part.reorderQty > 0) {
              reorderPointNotification(database, messageDbString, reorderItemNotificationUsers, { ...part, QtyOnHand: o.QtyOnHand });
            }
            // @ts-ignore -- we are handling the case where part is undefined above.
            // qboDownloadData.push([part.Name, part.Sku, part.QtyOnHand, 'Yes', part.ProductCode.AssetAccountRef.name, part.ProductCode.ExpenseAccountRef.name]);
            finishUp();
          });
        }
      });
    };

    reader.readAsText(file);

    return false;
  };

  const takeInventorySnapshot = async (inventoryData: IInventoryPart[]): Promise<void> => {
    const lastDayOfMonth = new Date();
    lastDayOfMonth.setDate(0); // Setting to day 0 of current month gets last day of previous month
    lastDayOfMonth.setHours(0, 0, 0, 0);
    let globalTotal = 0;

    const snapshot = inventoryData.reduce((acc, i) => {
      const total = Math.round((i.PurchaseCost * i.QtyOnHand) * 1000) / 1000;
      const productCode = i.ProductCode ? i.ProductCode.productCode : '';
      globalTotal += total;
      return { ...acc, [i.Sku]: { Value: total, QtyOnHand: i.QtyOnHand, ProductCode: productCode } };
    }, {});

    await firestore.collection(inventorySnapshotsDbString).doc(lastDayOfMonth.getTime().toString()).set({
      timestamp: lastDayOfMonth.getTime(),
      total: globalTotal,
      inventoryItems: snapshot,
    });
  };

  const handleImportType = (type: 'items' | 'eom') => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.txt, .csv';

    fileInput.onchange = (e: any) => {
      const file = e.target.files[0];
      onUpload(file, type === 'eom');
    };

    fileInput.click();
    setIsModalVisible(false);
  };

  const importMenu = (
    <Menu onClick={handleImportType}>
      <Menu.Item key="items">
        Inventory Items
      </Menu.Item>
      <Menu.Item key="eom">
        EOM Adjustment
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {uploadInProgress ? (
        <>
          {processedPercent >= 1 ? (
            <Progress type="circle" percent={processedPercent} width={64} style={{ marginLeft: 24 }} />
          ) : (
            <SaveSpinner />
          )}
        </>
      ) : (
        <>
          {qboCsvData.length > 0 ? (
            <CSVDownload data={qboCsvData} target="_self" />
          ) : (
            <Tooltip placement="top" title="Upload an inventory list using the same format as the downloaded file. Items not present in the database will be ignored.">
              <UploadButton
                trigger={['click']}
                icon={<CaretDown style={{ width: 10, marginBottom: 4, marginLeft: -5 }} />}
                onClick={() => setIsModalVisible(true)}
                overlay={importMenu}
                type="default"
              >
                Upload .CSV
              </UploadButton>
            </Tooltip>
          )}
        </>
      )}

      <Modal
        title="Import Type"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <p>What are you uploading?</p>
        <ModalButtonGroup>
          <ModalButton type="primary" onClick={() => handleImportType('items')}>
            Inventory Items
          </ModalButton>
          <ModalButton type="default" onClick={() => handleImportType('eom')}>
            EOM Adjustment
          </ModalButton>
        </ModalButtonGroup>
      </Modal>
    </>
  );
};

export default ImportInventoryButton;
