import React from 'react';
import {
  StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { calculateCreditCardFee } from 'shared/data/QBO/invoice';
import { formatPrice } from '../../../../shared/data';

const style = StyleSheet.create({
  componentWrapper: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  totalLine: {
    // width: 175,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
    marginRight: 4,
  },
  invoiceTotal: {
    width: 100,
  },
  topBorder: {
    borderTop: '2px solid black',
  },
  totalColumn: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  totalSubLine: {
    width: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 4,
  },
  totalTypeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    width: '100%',
    paddingLeft: 24,
  },
  pageSeparator: {
    height: 1,
    width: '100%',
    flex: 1,
    backgroundColor: '#cecece',
  },
  totalTypeHeaderText: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 9,
  },
  totalHeaderColumn: {
    position: 'relative',
    right: 8,
  },
  totalCopy: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 9,
  },
  totalDiscountRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  totalDiscountCopy: {
    fontSize: 7,
    position: 'relative',
    bottom: 1,
  },
  totalValue: {
    marginLeft: 'auto',
  },
  total: {
    marginTop: 8,
  },
});

interface IComponent {
  discountShipping: boolean;
  total: number;
  tax: number;
  shipmentCost: number;
  creditCardFee: boolean;
}

const InvoiceFooter = ({
  discountShipping, total, tax, shipmentCost, creditCardFee,
}: IComponent) => (
  <View style={style.componentWrapper}>
    {creditCardFee && (
      <View style={style.totalColumn}>
        <View style={style.totalTypeHeader}>
          <View style={style.pageSeparator} />
          <Text style={style.totalTypeHeaderText}>If paid by bank/ACH</Text>
        </View>
      </View>
    )}
    <View style={style.totalRow}>
      <View style={[style.totalSubLine, style.totalHeaderColumn]}>
        <Text style={style.totalCopy}>Subtotal: </Text>
        <Text style={style.totalCopy}>Sales tax: </Text>
        <View style={style.totalDiscountRow}>
          <Text style={style.totalCopy}>Shipping charges</Text>
          {discountShipping && (
            <Text style={[style.totalCopy, style.totalDiscountCopy]}>&nbsp;(discounted 10%):</Text>
          )}
        </View>
        <View style={style.total}>
          <Text style={style.totalCopy}>Total: </Text>
        </View>
      </View>
      <View style={style.totalSubLine}>
        <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total)}</Text>
        <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(tax)}</Text>
        <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(shipmentCost)}</Text>
        <View style={[style.topBorder, style.total]}>
          <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total + tax + shipmentCost)}</Text>
        </View>
      </View>
    </View>
    {creditCardFee && (
      <View style={[style.totalColumn, { marginTop: 24 }]}>
        <View style={style.totalTypeHeader}>
          <View style={style.pageSeparator} />
          <Text style={style.totalTypeHeaderText}>If paid by credit card</Text>
        </View>
        <View style={style.totalRow}>
          <View style={[style.totalSubLine, style.totalHeaderColumn]}>
            <Text style={style.totalCopy}>Subtotal: </Text>
            <Text style={style.totalCopy}>Sales tax: </Text>
            <View style={style.totalDiscountRow}>
              <Text style={style.totalCopy}>Shipping charges</Text>
              {discountShipping && (
              <Text style={[style.totalCopy, style.totalDiscountCopy]}>&nbsp;(discounted 10%):</Text>
              )}
            </View>
            <View style={style.totalDiscountRow}>
              <Text style={style.totalCopy}>Credit card processing fee</Text>
              <Text style={[style.totalCopy, style.totalDiscountCopy]}>&nbsp;(1.5%):</Text>
            </View>
            <View style={style.total}>
              <Text style={style.totalCopy}>Total: </Text>
            </View>
          </View>
          <View style={style.totalSubLine}>
            <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total)}</Text>
            <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(tax)}</Text>
            <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(shipmentCost)}</Text>
            <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(calculateCreditCardFee(total + shipmentCost, shipmentCost))}</Text>
            <View style={[style.topBorder, style.total]}>
              <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total + (shipmentCost || 0) + calculateCreditCardFee(total, shipmentCost))}</Text>
            </View>
          </View>
        </View>
      </View>
    )}
  </View>
);

export default InvoiceFooter;
