import { atom } from 'recoil';
import shortid from 'shortid';
import { resolveFirestorePath, resolveRealtimePath } from 'shared/util';
import { IConfigEntry, ICustomerPart } from 'shared/types/parts';
import { IShopOrder } from 'shared/types/dbRecords';

const transluminousMode = localStorage.getItem('transluminousMode') === 'true';

export const interfaceLoadingAtom = atom({
  key: 'interfaceLoadingAtom',
  default: false,
});

export const currentCustomerPrices = atom({
  key: 'currentCustomerPricesAtom',
  default: [],
});

export const priceSearchQueryAtom = atom({
  key: 'priceSearchQueryAtom',
  default: sessionStorage.getItem('pricing.query.string') || '',
});

export const priceFilterQueryAtom = atom({
  key: 'priceFilterQueryAtom',
  default: '',
});

export const partSizeAtom = atom<'S'|'M'|'L'>({
  key: 'partSizeAtom',
  default: 'M',
});

export const addButtonVisibilityAtom = atom({
  key: 'addButtonVisibilityAtom',
  default: {
    archetype: true,
    bodyWood: true,
    topWood: true,
    neckWood: true,
    fretboardWood: true,
    laborOption: true,
    constructionOption: true,
    finishingOption: true,
    handlingOption: true,
    weightReductionOption: true,
    frettingOption: true,
    inlayOption: true,
    dotOption: true,
  },
});

export const customerPartAtom = atom<ICustomerPart>({
  key: 'customerPartAtom',
  default: {
    Sku: '',
    Id: '',
    SyncToken: '',
    active: true,
    childParts: [],
    config: [],
    createdBy: '',
    createdDate: undefined,
    customer: '',
    lastSold: undefined,
    lastModifiedBy: '',
    lastModifiedDate: undefined,
    materialsConfirmed: false,
    materialsConfirmedBy: '',
    notes: '',
    oneTimeNote: '',
    parent: '',
    price: 0,
    type: 'GB',
    volume: '',
  },
});

// TODO: DEPRICATE THIS ATOM
export const newNeckAtom = atom({
  key: 'newNeckAtom',
  default: {
    id: shortid.generate(),
    type: 'neck',
    active: true,
    partName: '',
    Sku: '',
    customerId: '',
    Description: '',
    notes: '',
    oneTimeNote: '',
    Id: '',
    childParts: [],
    parent: { Description: '', Sku: '' },
    qboId: null,
    archetype: {
      id: '',
      label: 'Select a neck type',
      type: 'Select a neck type',
      instrument: 'L',
      price: 0,
    },
    neckWood: {
      id: '',
      label: 'Select a neck wood',
      price: {
        L: 0,
        M: 0,
      },
      partNumber: {
        L: 0,
        M: 0,
      },
    },
    fretboardWood: {
      id: '',
      label: 'Select a fretboard wood',
      price: {
        L: 0,
        M: 0,
      },
      partNumber: {
        L: 0,
        M: 0,
      },
    },
    options: {
      trussRodOptions: [],
      frettingOptions: [],
      inlayOptions: [],
      dotOptions: [],
      accessoryOptions: [],
      constructionOptions: [],
      finishingOptions: [],
      handlingOptions: [],
      laborOptions: [],
    },
    revisionNeeded: false,
    showNotesOnOrder: false,
    priceAdjustment: 0,
    createdDate: null,
    createdBy: null,
    lastModified: null,
    lastModifiedBy: null,
  },
});
export const newNeckPricingAtom = atom({
  key: 'newNeckPricingAtom',
  default: {
    archetype: {
      id: '',
      label: 'Select a neck type',
      type: 'Select a neck type',
      instrument: 'L',
      price: 0,
    },
    neckWood: {
      id: '',
      label: 'Select a neck wood',
      price: {
        L: 0,
        M: 0,
      },
      partNumber: {
        L: 0,
        M: 0,
      },
    },
    fretboardWood: {
      id: '',
      label: 'Select a fretboard wood',
      price: {
        L: 0,
        M: 0,
      },
      partNumber: {
        L: 0,
        M: 0,
      },
    },
    options: {
      trussRodOptions: [],
      frettingOptions: [],
      inlayOptions: [],
      dotOptions: [],
      accessoryOptions: [],
      constructionOptions: [],
      finishingOptions: [],
      handlingOptions: [],
      laborOptions: [],
    },

  },
});
export const newBodyPricingAtom = atom({
  key: 'newBodyPricingAtom',
  default: {
    blankModification: {
      id: 'PrY63uVGgq',
      name: 'None',
      price: 0,
      multiplier: 0,
    },
    archetype: {
      id: '',
      label: 'Select a body type',
      type: 'Select a body type',
      instrument: 'L',
      price: 0,
    },
    bodyWood: {
      id: '', label: 'Select a body wood', price: { L: 0, M: 0, S: 0 }, useWeightClass: false,
    },
    topWood: { id: '', label: 'Select a top wood (optional)', price: { L: 0, M: 0, S: 0 } },
    options: {
      accessoryOptions: [],
      weightReductionOptions: [],
      constructionOptions: [],
      finishingOptions: [],
      handlingOptions: [],
      laborOptions: [],
    },
  },
});

// TODO: DEPRICATE THIS ATOM
export const newBodyAtom = atom({
  key: 'newBodyAtom',
  default: {
    id: shortid.generate(),
    type: 'body',
    active: true,
    partName: '',
    Sku: '',
    Description: '',
    notes: '',
    oneTimeNote: '',
    customerId: '',
    volume: '0',
    Id: '',
    childParts: [],
    parent: { Description: '', Sku: '' },
    qboId: null,
    blankModification: {
      id: 'PrY63uVGgq',
      name: 'None',
      price: 0,
      multiplier: 0,
    },
    archetype: {
      id: '',
      label: 'Select a body type',
      type: 'Select a body type',
      instrument: 'L',
      price: 0,
    },
    bodyWood: {
      id: '', label: 'Select a body wood', price: { L: 0, M: 0, S: 0 }, useWeightClass: false,
    },
    topWood: { id: '', label: 'Select a top wood (optional)', price: { L: 0, M: 0, S: 0 } },
    options: {
      accessoryOptions: [],
      weightReductionOptions: [],
      constructionOptions: [],
      finishingOptions: [],
      handlingOptions: [],
      laborOptions: [],
    },
    revisionNeeded: false,
    showNotesOnOrder: false,
    priceAdjustment: 0,
    createdDate: null,
    createdBy: null,
    lastModifiedDate: null,
    lastModifiedBy: null,
  },
});

export const bodyWeightClassAtom = atom({
  key: 'bodyWeightClassAtom',
  default: [{
    id: '',
    name: 'Standard',
    multiplier: 1,
  }],
});

export const bodyArchetypeAtom = atom({
  key: 'bodyArchetypeAtom',
  default: {
    label: 'Base Model',
    types: [],
    items: [],
  },
});

export const bodyWoodAtom = atom({
  key: 'bodyWoodAtom',
  default: {
    key: 'bodyWood',
    label: 'Body Wood',
    types: [],
    items: [],
  },
});

export const topWoodAtom = atom({
  key: 'topWoodAtom',
  default: {
    key: 'topWood',
    label: 'Top Wood',
    types: [],
    items: [],
  },
});

export const bodyCustomerChargesAtom = atom({
  key: 'bodyCustomerChargesAtom',
  default: {
    label: '',
    item: [],
  },
});

export const bodyDataAtom = atom({
  key: 'bodyDataAtom',
  default: {},
});

export const showDensityCalculatorAtom = atom<boolean>({
  key: 'showDensityCalculatorAtom',
  default: false,
});

export const neckDataAtom = atom({
  key: 'neckDataAtom',
  default: {},
});

export const neckCustomerChargesAtom = atom({
  key: 'neckCustomerChargesAtom',
  default: {
    label: '',
    item: [],
  },
});

export const neckArchetypeAtom = atom({
  key: 'neckArchetypeAtom',
  default: {
    label: 'Base Neck',
    types: [],
    items: [],
  },
});

export const neckWoodAtom = atom({
  key: 'neckWoodAtom',
  default: {
    key: 'neckWood',
    label: 'Neck Wood',
    types: [],
    items: [],
  },
});

export const fretboardWoodAtom = atom({
  key: 'fretboardWoodAtom',
  default: {
    key: 'fretboardWood',
    label: 'Fretboard Wood',
    types: [],
    items: [],
  },
});

export const unitOptionAtom = atom({
  key: 'unitOptionAtom',
  default: {
    label: '',
    items: [],
  },
});

export const rateOptionAtom = atom({
  key: 'rateOptionAtom',
  default: {
    label: '',
    items: [],
  },
});

export const weightReductionOptionsAtom = atom({
  key: 'weightReductionOptionsAtom',
  default: {
    label: 'Weight Reduction Options',
    items: [],
  },
});

export const frettingOptionsAtom = atom({
  key: 'frettingOptionsAtom',
  default: {
    label: 'Fretting Options',
    items: [],
  },
});

export const inlayOptionsAtom = atom({
  key: 'inlayOptionsAtom',
  default: {
    label: 'Inlay Options',
    items: [],
  },
});

export const dotOptionsAtom = atom({
  key: 'dotOptionsAtom',
  default: {
    label: 'Dot Options',
    items: [],
  },
});

export const trussRodOptionsAtom = atom({
  key: 'trussRodOptionsAtom',
  default: {
    label: 'Truss Rod Options',
    items: [],
  },
});

export const accessoryOptionsAtom = atom({
  key: 'accessoryOptionsAtom',
  default: {
    label: 'Accessory Options',
    items: [],
  },
});

export const constructionOptionsAtom = atom({
  key: 'constructionOptionsAtom',
  default: {
    label: 'Construction Options',
    items: [],
  },
});

export const finishingOptionsAtom = atom({
  key: 'finishingOptionsAtom',
  default: {
    label: 'Finishing Options',
    items: [],
  },
});

export const handlingOptionsAtom = atom({
  key: 'handlingOptionsAtom',
  default: {
    label: 'Handling Options',
    items: [],
  },
});

export const laborOptionsAtom = atom({
  key: 'laborOptionsAtom',
  default: {
    label: 'Labor Options',
    items: [],
  },
});

export const hideUnselectedOptionsAtom = atom({
  key: 'hideUnselectedOptionsAtom',
  default: true,
});

export const documentSavingProgressAtom = atom({
  key: 'documentSavingProgress',
  default: false,
});
export const partEditModeAtom = atom({
  key: 'partEditModeAtom',
  default: false,
});

export const woodListEpochAtom = atom({
  key: 'woodListEpochAtom',
  default: 'woodList',
});

export const archetypeEpochAtom = atom({
  key: 'archetypeEpochAtom',
  default: 'archetype',
});

export const optionEpochAtom = atom({
  key: 'optionEpochAtom',
  default: 'option',
});

export const useTestDataAtom = atom({
  key: 'useTestDataAtom',
  default: localStorage.getItem('useTestData') === 'true',
});

export const useTransluminousModeAtom = atom({
  key: 'useTransluminousMode',
  default: localStorage.getItem('transluminousMode') === 'true',
});

export const csvDataAtom = atom({
  key: 'csvDataAtom',
  default: [],
});

export const badPartNumberAtom = atom({
  key: 'badPartNumberAtom',
  default: false,
});

export const copyDataAtom = atom<any>({
  key: 'copyDataAtom',
  default: null,
});

// add extra atom to represent whether data is loaded as a boolean
export const copyDataLoadedAtom = atom({
  key: 'copyDataLoadedAtom',
  default: false,
});

export const dataLoadingAtom = atom({
  key: 'dataLoadingAtom',
  default: false,
});

export const currentPartTypeAtom = atom<'global'|'body'|'neck'>({
  key: 'currentPartType',
  default: 'body',
});
export const pricingExportDataAtom = atom({
  key: 'pricingExportDataAtom',
  default: [],
});
export const currentPricingPageAtom = atom<number>({
  key: 'currentPricingPageAtom',
  default: (() => {
    const currentPage = sessionStorage.getItem('currentPricingPage');
    if (currentPage) return parseInt(currentPage, 10);
    return 1;
  })(),
});

export const customerPartsAtom = atom({
  key: 'customerPartsAtom',
  default: [],
});
export const currentPartAtom = atom<any>({
  key: 'currentPartAtom',
  default: {} as any,
});
export const BODY_DATA_PATH = atom({
  key: 'BODY_DATA_PATH',
  default: resolveRealtimePath('body'),
});
export const NECK_DATA_PATH = atom({
  key: 'NECK_DATA_PATH',
  default: resolveRealtimePath('neck'),
});
export const PRICING_DB_COLLECTION = atom({
  key: 'PRICING_DB_COLLECTION',
  default: resolveFirestorePath('pricing'),
});

export const newPartsAtom = atom<IShopOrder[]>({
  key: 'newPartsAtom',
  default: [],
});

export interface ICartItem {
  Sku: string;
  Description: string;
  quantity: number;
  notes: string;
  price: number;
  config: IConfigEntry[];
}

// Get initial cart state from localStorage
const getInitialCart = (): ICartItem[] => {
  try {
    const savedCart = localStorage.getItem('shoppingCart');
    return savedCart ? JSON.parse(savedCart) : [];
  } catch (e) {
    console.error('Error loading cart from localStorage:', e);
    return [];
  }
};

export const shoppingCartAtom = atom<ICartItem[]>({
  key: 'shoppingCart',
  default: getInitialCart(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem('shoppingCart', JSON.stringify(newValue));
      });
    },
  ],
});

export const showPriceListPDFAtom = atom<boolean>({
  key: 'showPriceListPDFAtom',
  default: false,
});
