import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import qs from 'qs';
import { configToDescription } from 'shared/partParser/util';
import { nextCustomerPart } from 'shared/data';
import { useRecoilValue } from 'recoil';
import { currentCustomerAtom } from 'shared/state/customerState';
import { customerPartAtom } from 'shared/state/pricingState';
import { ICustomerPart } from 'shared/types/parts';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { PART_PRICING_COLLECTION } from 'shared/state/partViewState';

const CopyButton = styled(Button)`
  border-radius: 8px;
  margin-left: 16px;
  background-color: ${theme.palette.primary[900]};
  &:hover {
    background-color: ${theme.palette.primary.D200};
  }
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
  &:disabled {
    background-color: ${theme.palette.neutral[400]};
    border-color: ${theme.palette.neutral[400]};
    color: ${theme.palette.neutral[600]};
    box-shadow: none;
    &:hover {
      background-color: ${theme.palette.neutral[400]};
      border-color: ${theme.palette.neutral[400]};
      color: ${theme.palette.neutral[600]};
    } 
  }
`;

interface CopyPartDataButtonProps {
  customerParts: ICustomerPart[];
  editMode: boolean;
  isSaving: boolean;
}

const CopyPartDataButton = ({ customerParts, editMode, isSaving }: CopyPartDataButtonProps) => {
  const { firestore } = useFirebase();
  const pricingDbCollection = useRecoilValue(PART_PRICING_COLLECTION);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [_customerParts, setCustomerParts] = useState<ICustomerPart[]>(customerParts);
  const [isEditMode, setIsEditMode] = useState<boolean>(editMode);
  const partState = useRecoilValue(customerPartAtom);
  const { isCopy } = qs.parse(window.location.search);

  const onCopy = async (e: any) => {
    e.stopPropagation();
    const pricingData = await firestore.collection(pricingDbCollection).doc(partState.Sku).get();
    const pricing = pricingData.data();
    const partTypePattern = partState.type.match(/[G|B]N/) ? '[G|B]N' : '[G|B]B';
    const partType = partState.type.match(/[G|B]N/) ? 'neck' : 'body';
    const nextPart = nextCustomerPart(currentCustomer.id, _customerParts, partTypePattern);
    const newRecord = {
      ...partState,
      pricing,
      Sku: nextPart,
      Description: `COPY_${configToDescription(partState.config)}`,
      customer: currentCustomer.id,
      id: nextPart,
      oldPartNumber: partState.Sku,
    };
    localStorage.setItem('pricing.temp.part', JSON.stringify(newRecord));
    window.location.href = `/pricing/${partType}?customer=${currentCustomer.id}&copy=true`;
  };

  useEffect(() => {
    setCustomerParts(customerParts);
    setIsEditMode(editMode);
  }, [customerParts, editMode]);

  return (
    <>
      <CopyButton type="primary" onClick={onCopy} disabled={isEditMode || isCopy || isSaving}>
        Copy
      </CopyButton>
    </>
  );
};

export default CopyPartDataButton;
