import React, {
  useEffect, useMemo, useCallback, useState, useContext,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find, findIndex } from 'lodash';
import shortid from 'shortid';
import styled from 'styled-components';
import qs from 'qs';
import {
  Button, Divider,
} from 'antd';
import { customerPartsAtom } from 'shared/state/pricingState';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { adminUserEmailsAtom, salesOrderEditedAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import OrderConfirmationPDFDrawer from 'pages/PDF/Display/OrderConfimationPDFDrawer';
import WorkOrderPDFDrawer from 'pages/PDF/Display/WorkOrderPDFDrawer';
import InvoicePDFDrawer from 'pages/PDF/Display/InvoicePDFDrawer';
import {
  ICustomerRecord, IOrderItem, IShipment, IShippingAddress,
} from 'shared/types/dbRecords';
import { toppedOrBound, orderType } from 'shared/data/order';
import {
  currentShopOrderAtom, NEXT_ORDER_NUMBER_PATH,
  ORDER_ITEMS_DB_COLLECTION,
  orderItemsAtom, ORDERS_DB_COLLECTION, orderShipmentsAtom,
} from 'shared/state/orderState';
import theme from 'shared/theme';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWithCallback';
import { devLog } from 'shared/util/logging';
import { currentCustomerAtom, customersAtom } from 'shared/state/customerState';
import PackSlipPDFDrawer from 'pages/PDF/Display/PackSlipPDFDrawer';
import { isProduction, resolveFirestorePath } from 'shared/util';
import { orderItemEditModalAtom } from 'shared/state/orderItemState';
import {
  ActionRow,
  AddRunnerColumn,
  RunnerHeaderRow,
  RunnerPageTitle,
} from '../ProductionSchedule/styledComponents';
import {
  FieldRow,
  FieldWrapper, OrderShipmentsWrapper,
  WorkOrderWrapper,
} from '../ProductionSchedule/Components/styledComponents';
import WorkOrderList from './Components/WorkOrder/WorkOrderList';
import PrintDocumentButton from './Components/SalesOrderRecord/Buttons/PrintDocumentButton';
import OrderItemList from './Components/OrderItems/OrderItemList';
import OrderValue from './Components/SalesOrderRecord/SalesOrderDetail/OrderValue';
import PartCount from './Components/SalesOrderRecord/SalesOrderDetail/PartCount';
import OrderCustomerSearchSelect from './Components/SalesOrderRecord/OrderCustomerSearchSelect';
import OrderJobType from './Components/SalesOrderRecord/SalesOrderDetail/OrderJobType';
import OrderToppedOrBound from './Components/SalesOrderRecord/SalesOrderDetail/OrderToppedOrBound';
import OrderWeightReduction from './Components/SalesOrderRecord/SalesOrderDetail/OrderWeightReduction';
import OrderFinishingRequired from './Components/SalesOrderRecord/SalesOrderDetail/OrderFinishingRequired';
import OrderHouseSample from './Components/SalesOrderRecord/SalesOrderDetail/OrderHouseSample';
import OrderHoldStatus from './Components/SalesOrderRecord/SalesOrderDetail/OrderHoldStatus';
import DeleteOrderButton from './Components/SalesOrderRecord/Buttons/DeleteOrderButton';
import { IRunner, IShopOrder } from './types';
import SaveDocumentButton from './Components/SalesOrderRecord/Buttons/SaveDocumentButton';
import ConfirmOrderButton from './Components/SalesOrderRecord/Buttons/ConfirmOrderButton';
import OrderShipment from './Components/Shipments/OrderShipment';
import ConfirmMaterialsButton from './Components/SalesOrderRecord/Buttons/ConfirmMaterialsButton';
import { FlexColumn, FlexRow } from '../../shared/containers/FlexContainer';
import ShippingAddressSelector
  from '../Customer/Components/ShippingAddresses/ShippingAddressDetailFields/ShippingAddressSelector';
import OrderShippingAddress from './Components/SalesOrderRecord/ShipAddress/OrderShippingAddress';
import OrderItemEditDrawer from './Components/OrderItems/OrderItemEditDrawer';
import OrderItemEditModal from './Components/OrderItems/OrderItemEditModal';
import OrderJobTypePicker from './Components/SalesOrderRecord/SalesOrderDetail/OrderJobTypePicker';
import { IWeightReductionObject } from '../../shared/types/order';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import CNCLoad from './Components/SalesOrderRecord/SalesOrderDetail/CNCLoad';
import { userSettingsAtom } from '../../shared/state/siteState';
import DetailActiveSelectorWithCallback from '../../shared/components/Input/DetailActiveSelectorWithCallback';
import DraftWatermark from './Components/SalesOrderRecord/DraftWatermark';
import LinkedSalesOrder from './Components/SalesOrderRecord/LinkedSalesOrder';
import { UserContext } from '../../contexts/UserContext';
import { UtilityContext } from '../../contexts/UtilityContext';
import CopyToDevButton from './Components/SalesOrderRecord/Buttons/CopyToDevButton';

const ConfirmButtonRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

const CustomerDetailsRow = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 40px;
  justify-content: flex-start;
  align-items: flex-start;
    
  @media ${theme.device.laptopL} {
      flex-direction: row;
  }
`;
const CustomerOrderDetailsColumn = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 40px;
  gap: 24px;

  @media ${theme.device.laptopL} {
    width: 30%;
    flex-direction: column;
    margin-bottom: unset;
    gap: unset;
  }
`;
const ShipAddressWrapper = styled(FlexRow)`
    align-items: flex-start;
    justify-content: flex-start;
    
    @media ${theme.device.laptopL} {
        flex-direction: column;
    }
`;
const CustomerOrderDetailsRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 24px;
`;

const OrderDetailsRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
`;

interface IComponent {
  id: string;
  extend: boolean;
  isLabel: boolean;
  key: string;
  label: string;
  placeholder: string;
  value: string;
  callback: (value: string, blur?: boolean) => void;
  disabled?: boolean;
  width?: string;
}

const MemoizedOrderItemEditModal = React.memo(OrderItemEditModal);

const SalesOrderRecord = () => {
  const { firestore, database, firebase } = useFirebase();
  const userSettings = useRecoilValue(userSettingsAtom);
  const { edit, orderId, returnView } = qs.parse(window.location.search.replace('?', ''));

  // Recoil state
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const customerParts = useRecoilValue(customerPartsAtom);
  const orderShipments = useRecoilValue(orderShipmentsAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const setEdited = useSetRecoilState(salesOrderEditedAtom);
  const customers = useRecoilValue(customersAtom);
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbCollectionString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const nextOrderNumberPath = useRecoilValue(NEXT_ORDER_NUMBER_PATH);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const [modalState, setModalState] = useRecoilState(orderItemEditModalAtom);

  const updateOrderRecord = useCallback(async (updateObject: any, blur: boolean = false) => {
    const batchUpdates = () => {
      setCurrentShopOrder((prev) => ({ ...prev, ...updateObject }));
      setEdited(true);
    };

    if (blur && orderId) {
      await firestore.collection(ordersDbCollectionString).doc(orderId as string).update(updateObject);
    }
    batchUpdates();
  }, [firestore, orderId, ordersDbCollectionString, setCurrentShopOrder, setEdited]);

  const onCustomerChange = useCallback((e: any) => {
    const cust = find(customers, (c: ICustomerRecord) => c.DisplayName === e) as ICustomerRecord;
    setCurrentCustomer(cust);
    localStorage.setItem('currentCustomerId', cust.id);
  }, [customers, setCurrentCustomer]);

  const onChangeSalesOrder = useCallback((salesOrder: string, blur: boolean = false) => {
    if (blur) {
      if (salesOrder === '') {
        database.ref(nextOrderNumberPath).once('value').then((recordData) => {
          const orderNumber = recordData.val();
          updateOrderRecord({ salesOrder: orderNumber.toString() }, blur);
        });
      } else {
        updateOrderRecord({ salesOrder: salesOrder.replace(/\s/g, '') }, blur);
      }
    } else {
      updateOrderRecord({ salesOrder }, blur);
    }
  }, [database, nextOrderNumberPath, updateOrderRecord]);

  const onChangePO = (purchaseOrder: string, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ purchaseOrder }, blur);
  };
  const onChangeDescription = useCallback((description: string, blur: boolean = false) => {
    updateOrderRecord({ description: description.replace(/-/g, '—') }, blur);
  }, [updateOrderRecord]);
  const onChangeReleaseId = (releaseId: string, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ releaseId }, blur);
  };
  const onChangeOrderValue = (value: number, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ orderValue: value }, blur);
  };
  const onChangePartCount = (count: number, blur: boolean) => {
    setEdited(true);
    updateOrderRecord({ partCount: count }, blur);
  };
  const onChangeOrderDate = useCallback((date: Date, blur: boolean) => {
    const orderTimestamp = firebase.firestore.Timestamp.fromDate(date);
    updateOrderRecord({ orderDate: orderTimestamp }, blur);
  }, [firebase, updateOrderRecord]);
  const onChangeShipDate = useCallback((date: Date, blur: boolean) => {
    const shipTimestamp = firebase.firestore.Timestamp.fromDate(date);
    const completedDate = currentShopOrder.completed ? shipTimestamp : null;
    updateOrderRecord({ shipDate: shipTimestamp, completedDate }, blur);
  }, [currentShopOrder.completed, firebase, updateOrderRecord]);

  const onChangeShipAddress = useCallback((shipAddress: IShippingAddress, blur: boolean) => {
    updateOrderRecord({ customer: { ...currentShopOrder.customer, ShipAddr: shipAddress } }, blur);
  }, [currentShopOrder.customer, updateOrderRecord]);
  const onEditWorkOrders = useCallback((updatedWorkOrders: IRunner[], blur: boolean) => {
    const newRunners = updatedWorkOrders.length === 0 ? null : updatedWorkOrders;
    updateOrderRecord({ runners: newRunners }, blur);
  }, [updateOrderRecord]);

  const onChangeWeightReduction = useCallback((weightReduction: IWeightReductionObject, blur: boolean) => {
    updateOrderRecord({ weightReduction }, blur);
  }, [updateOrderRecord]);

  const onRefreshOrderItems = useCallback((refreshedItems: IOrderItem[]) => {
    setOrderItems(refreshedItems);

    const refreshedWorkOrders = currentShopOrder.runners.map((r: IRunner) => {
      const refreshedParts = r.parts.map((p: IOrderItem) => {
        const refreshedItem = find(refreshedItems, (i: IOrderItem) => i.Sku === p.Sku);
        if (!refreshedItem) return p;
        return {
          ...p,
          Description: refreshedItem.Description,
          unitPrice: refreshedItem.unitPrice,
          volume: refreshedItem.volume,
        };
      });
      return {
        ...r,
        parts: refreshedParts,
        value: Math.round(refreshedParts.map((p: IOrderItem) => p.quantityAssigned * p.unitPrice).reduce((a, b) => a + b, 0)),
      };
    });
    updateOrderRecord({ runners: refreshedWorkOrders });
  }, [currentShopOrder.runners, updateOrderRecord, setOrderItems]);

  const onReorderItems = useCallback((reorderedItems: IOrderItem[]) => {
    const runners = currentShopOrder.runners.map((r) => {
      const parts: IOrderItem[] = [];
      reorderedItems.forEach((i: IOrderItem, index) => {
        const runnerPart = find(r.parts, (p: IOrderItem) => p.id === i.id);
        if (runnerPart) parts[index] = runnerPart;
      });
      return {
        ...r,
        parts: parts.filter((p: IOrderItem) => p),
      };
    });
    updateOrderRecord({ runners });
  }, [currentShopOrder.runners, updateOrderRecord]);

  const onOrderItemSave = useCallback(async (orderItem: IOrderItem) => {
    if (!currentShopOrder.runners) return;

    const index = findIndex(orderItems, (i: IOrderItem) => i.id === orderItem.id);
    const updatedOrderItems = [...orderItems];
    if (index !== -1) {
      updatedOrderItems[index] = orderItem;
    } else {
      updatedOrderItems.push(orderItem);
    }
    await firestore.collection(orderItemsDbCollectionString).doc(currentShopOrder.id).set({
      completed: currentShopOrder.completed || false,
      orderId: currentShopOrder.id,
      orderItems: updatedOrderItems,
    });
    setOrderItems(updatedOrderItems);

    const newWorkOrders = currentShopOrder.runners.map((r: IRunner) => {
      const parts = r.parts.map((p: IOrderItem) => {
        if (p.id !== orderItem.id) return p;
        return {
          ...p,
          houseSample: orderItem?.houseSample || false,
          unitPrice: orderItem.unitPrice,
          description: orderItem.Description,
          notes: orderItem.notes,
          internalNotes: orderItem.internalNotes || null,
        };
      });
      return ({ ...r, parts });
    });

    await updateOrderRecord({ runners: newWorkOrders });
  }, [currentShopOrder.runners, updateOrderRecord]);

  const onNextItem = useCallback(() => {
    if (!modalState.item || !orderItems.length) return;
    const currentIndex = findIndex(orderItems, (item) => item.id === modalState.item?.id);
    console.log('Next clicked:', { currentIndex, totalItems: orderItems.length, orderItems });
    if (currentIndex >= 0 && currentIndex < orderItems.length - 1) {
      const nextItem = orderItems[currentIndex + 1];
      console.log('Moving to next item:', nextItem);
      setModalState((prev) => ({ ...prev, item: nextItem }));
    }
  }, [modalState.item, orderItems, setModalState]);

  const onPreviousItem = useCallback(() => {
    if (!modalState.item || !orderItems.length) return;
    const currentIndex = findIndex(orderItems, (item) => item.id === modalState.item?.id);
    console.log('Previous clicked:', { currentIndex, totalItems: orderItems.length, orderItems });
    if (currentIndex > 0) {
      const prevItem = orderItems[currentIndex - 1];
      console.log('Moving to previous item:', prevItem);
      setModalState((prev) => ({ ...prev, item: prevItem }));
    }
  }, [modalState.item, orderItems, setModalState]);

  const onResetAllocation = useCallback(async () => {
    const updatedWorkOrders = (currentShopOrder.runners || []).map((r: IRunner) => ({
      ...r,
      parts: r.parts.map((p: IOrderItem) => ({
        ...p,
        quantityAssigned: 0,
      })),
    }));
    await updateOrderRecord({ runners: updatedWorkOrders });
    return null;
  }, [currentShopOrder.runners, updateOrderRecord]);

  const onUpdateLinkedOrders = useCallback(async (linkedOrders: string[], removed: string|null) => {
    setCurrentShopOrder((prev) => ({ ...prev, linkedOrders }));
    await updateOrderRecord({ linkedOrders });

    if (linkedOrders.length === 0) {
      if (removed) {
        const order = await firestore.collection(ordersDbCollectionString).doc(removed).get();
        const orderData = order.data() as IShopOrder;
        const existingLinks = orderData.linkedOrders || [];
        const updatedOrderLinks = existingLinks.filter((id) => id !== currentShopOrder.id);
        await firestore.collection(ordersDbCollectionString).doc(removed).update({ linkedOrders: updatedOrderLinks });
      }
    } else {
      await Promise.all(linkedOrders.map(async (linkedOrderId) => {
        const order = await firestore.collection(ordersDbCollectionString).doc(linkedOrderId).get();
        const orderData = order.data() as IShopOrder;
        const existingLinks = orderData.linkedOrders || [];
        const updatedOrderLinks = existingLinks.includes(currentShopOrder.id)
          ? existingLinks
          : [...existingLinks, currentShopOrder.id];
        return firestore.collection(ordersDbCollectionString).doc(linkedOrderId).update({ linkedOrders: updatedOrderLinks });
      }));
    }
  }, [currentShopOrder.id, firestore, ordersDbCollectionString, updateOrderRecord]);

  // Add an effect to load order items if needed
  useEffect(() => {
    if (orderId && !orderItems.length && modalState.visible) {
      console.log('Loading order items...');
      firestore.collection(orderItemsDbCollectionString)
        .doc(orderId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data?.orderItems) {
              console.log('Setting order items:', data.orderItems);
              setOrderItems(data.orderItems);
            }
          }
        });
    }
  }, [orderId, orderItems.length, firestore, orderItemsDbCollectionString, setOrderItems, modalState.visible]);

  // Ensure we don't show the modal until order items are loaded
  const showModal = useMemo(() => {
    if (!modalState.visible) return false;
    if (!orderItems.length) return false;
    return true;
  }, [modalState.visible, orderItems.length]);

  useEffect(() => {
    devLog('salesOrderRecord', 278, 'load sales order');
    if (!orderId) { // CREATE MODE - no orderId
      database.ref(nextOrderNumberPath).once('value').then((recordData) => {
        const orderNumber = recordData.val();
        onChangeSalesOrder(orderNumber.toString());
      });
    }
  }, []);

  // Memoize props for child components
  const orderItemsProps = useMemo(() => ({
    orderItems,
    onReorderItems,
    onRefreshOrderItems,
    onOrderItemSave,
    onResetAllocation,
    showCNCLoad: userSettings?.boolean?.showCNCLoad && orderType(orderItems) === 1,
  }), [
    orderItems,
    onReorderItems,
    onRefreshOrderItems,
    onOrderItemSave,
    onResetAllocation,
    userSettings?.boolean?.showCNCLoad,
    orderType,
  ]);

  const orderHeaderProps = useMemo(() => ({
    currentShopOrder,
    currentCustomer,
    onCustomerChange,
    onChangeSalesOrder,
    onChangePO,
    onChangeDescription,
    onChangeReleaseId,
    onChangeOrderDate,
    onChangeShipDate,
    onChangeShipAddress,
  }), [
    currentShopOrder,
    currentCustomer,
    onCustomerChange,
    onChangeSalesOrder,
    onChangePO,
    onChangeDescription,
    onChangeReleaseId,
    onChangeOrderDate,
    onChangeShipDate,
    onChangeShipAddress,
  ]);

  const weightReductionProps = useMemo(() => ({
    currentShopOrder,
    onChangeWeightReduction,
  }), [currentShopOrder, onChangeWeightReduction]);

  return (
    <>
      {currentShopOrder.salesOrder === 'DRAFT' && <DraftWatermark />}
      {showModal && (
        <MemoizedOrderItemEditModal
          orderId={orderId as string}
          orderComplete={currentShopOrder.completed}
          onItemSaveCallback={onOrderItemSave}
          onNextItemCallback={onNextItem}
          onPreviousItemCallback={onPreviousItem}
        />
      )}
      <WorkOrderPDFDrawer orderId={orderId as string} />
      <InvoicePDFDrawer shipment={orderShipments[0]} />
      <PackSlipPDFDrawer shipment={orderShipments[0]} />
      <OrderConfirmationPDFDrawer />
      <ConfigurationCol>
        <RunnerHeaderRow>
          <RunnerPageTitle>{`${edit ? 'Edit' : 'Add'} Sales Order`}</RunnerPageTitle>
          <ActionRow>
            <SaveDocumentButton edit={!!edit} returnView={returnView as string} currentOrder={currentShopOrder} />
            <PrintDocumentButton orderId={orderId as string} />
            {isProduction() && (
              <ScopedComponent whitelist={superAdminUsers.emails}>
                <CopyToDevButton orderId={orderId as string} />
              </ScopedComponent>
            )}
            { orderId && !currentShopOrder?.completed
          && (
          <>
            <OrderHoldStatus />
            <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
              <ConfirmButtonRow>
                <ConfirmOrderButton />
                <ConfirmMaterialsButton />
              </ConfirmButtonRow>
              <DeleteOrderButton returnView={returnView as string} />
            </ScopedComponent>
          </>
          )}
          </ActionRow>
        </RunnerHeaderRow>
        <Divider key={shortid.generate()} orientation="left">Order Details</Divider>
        <AddRunnerColumn>
          <CustomerDetailsRow>
            <CustomerOrderDetailsColumn>
              <OrderCustomerSearchSelect customerId={currentShopOrder?.customer?.id || ''} changeCallback={onCustomerChange} />
              {!!currentShopOrder?.customer?.id && (
                <ShipAddressWrapper>
                  <ShippingAddressSelector label="Shipping Address" callback={onChangeShipAddress} showEdit />
                  <OrderShippingAddress />
                </ShipAddressWrapper>
              )}
            </CustomerOrderDetailsColumn>
            <FieldWrapper>
              <CustomerOrderDetailsRow>
                <DetailInputWithCallback
                  id="sales-order-input"
                  key="sales-order-input"
                  label="Sales Order"
                  placeholder="e.g., SO-00001"
                  value={currentShopOrder.salesOrder}
                  callback={onChangeSalesOrder}
                  extend={false}
                  isLabel={false}
                  disabled={false}
                />
                <DetailInputWithCallback
                  id="purchase-order-input"
                  key="purchase-order-input"
                  label="Purchase Order"
                  placeholder="e.g., PO-00001"
                  value={currentShopOrder.purchaseOrder}
                  callback={onChangePO}
                  extend={false}
                  isLabel={false}
                  disabled={false}
                />
                <DetailInputWithCallback
                  id="release-id-input"
                  key="release-id-input"
                  label="Release ID"
                  placeholder="e.g., REL-00001"
                  value={currentShopOrder.releaseId}
                  callback={onChangeReleaseId}
                  extend={false}
                  isLabel={false}
                  disabled={false}
                />
                <DetailInputWithCallback
                  id="description-input"
                  key="description-input"
                  label="Description"
                  placeholder="e.g., Initial order"
                  value={currentShopOrder.description}
                  callback={onChangeDescription}
                  extend={false}
                  isLabel={false}
                  disabled={false}
                />
                <DetailDateWithCallback
                  id="order-date-input"
                  key="order-date-input"
                  label="Order Date"
                  value={currentShopOrder.orderDate?.toDate()}
                  callback={onChangeOrderDate}
                  disableDates={false}
                />
                <DetailDateWithCallback
                  id="ship-date-input"
                  key="ship-date-input"
                  label="Ship Date"
                  value={currentShopOrder.shipDate?.toDate()}
                  callback={onChangeShipDate}
                  disableDates={false}
                />
                <ScopedComponent whitelist={superAdminUsers.emails}>
                  <LinkedSalesOrder
                    currentCustomerName={currentShopOrder.customer?.DisplayName}
                    linkedOrders={currentShopOrder.linkedOrders}
                    callback={onUpdateLinkedOrders}
                  />
                </ScopedComponent>
              </CustomerOrderDetailsRow>
              <OrderDetailsRow>
                <DetailInputWithCallback width="500px" key="order-description" label="Description" placeholder="e.g., 4 IRW Tele necks" value={currentShopOrder.description} callback={onChangeDescription} />
                { (edit || currentShopOrder.salesOrder === 'PH') && (
                  <>
                    <OrderValue shipped={currentShopOrder.completed} orderValue={currentShopOrder.orderValue} placeholder={currentShopOrder.salesOrder === 'PH'} callback={onChangeOrderValue} />
                    <PartCount />
                  </>
                )}
              </OrderDetailsRow>
              {(currentShopOrder.salesOrder === 'PH' || !orderItems.filter((i) => i).length) && (
                <OrderJobTypePicker />
              )}
              {(orderId && !!orderItems.filter((i) => i).length) && (
              <FieldRow align="flex-start">
                <OrderJobType />
                <OrderToppedOrBound />
                <OrderFinishingRequired />
                <OrderHouseSample />
                {(orderType(orderItems) === 0 && toppedOrBound(orderItems, customerParts)) && (
                <OrderWeightReduction callback={onChangeWeightReduction} />
                )}
                {(userSettings?.boolean?.showCNCLoad && orderType(orderItems) === 1) && (
                  <CNCLoad orderItems={orderItems} />
                )}
              </FieldRow>
              )}
            </FieldWrapper>
          </CustomerDetailsRow>

          {orderId && (
          <>
            {/*
            ORDER ITEMS ----------------------------------------------------------------------------------------------->
          */}
            <OrderItemList
              key="sales-order-order-items-list"
              customerId={currentShopOrder.customer.id}
              orderCompleted={currentShopOrder.completed}
              orderId={currentShopOrder.id}
              currentOrderType={currentShopOrder.type}
              salesOrderNumber={currentShopOrder.salesOrder}
              refreshCallback={onRefreshOrderItems}
              reorderCallback={onReorderItems}
              resetAllocationCallback={onResetAllocation}
            />
            {/*
            WORK ORDERS ----------------------------------------------------------------------------------------------->
          */}
            {(currentShopOrder.releaseConfirmed && currentShopOrder.materialsConfirmed && !currentShopOrder.completed) && (
            <>
              <Divider key={shortid.generate()} orientation="left">Work orders</Divider>
              <WorkOrderWrapper>
                <WorkOrderList
                  key={`work-order-list-${currentShopOrder.id}`}
                  editWorkOrderCallback={onEditWorkOrders}
                  orderItems={orderItems}
                  orderId={currentShopOrder.id}
                  releaseConfirmed={currentShopOrder.releaseConfirmed}
                  workOrders={currentShopOrder?.runners || []}
                />
              </WorkOrderWrapper>
            </>
            )}
            {!!orderShipments.length && (
            <OrderShipmentsWrapper>
              <Divider key={shortid.generate()} orientation="left">Shipments</Divider>
              {orderShipments.map((shipment: IShipment) => (<OrderShipment shipment={shipment} orderId={currentShopOrder.id} />))}
            </OrderShipmentsWrapper>
            )}

          </>
          )}
        </AddRunnerColumn>
      </ConfigurationCol>
    </>
  );
};

export default SalesOrderRecord;
