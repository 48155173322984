import styled from 'styled-components';
import { Button, Drawer } from 'antd';
import {
  Calculate, AttachMoney, Edit, DragHandle,
} from '@styled-icons/material';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

export const CalculateIcon = styled(Calculate)`
  width: 24px;
  margin-bottom: 3px;
`;

export const MoneyIcon = styled(AttachMoney)`
  width: 24px;
  margin-bottom: 4px;
`;

export const EditIcon = styled(Edit)`
  width: 20px;
  margin-bottom: 4px;
`;

export const CalcDrawer = styled(Drawer)`
  top: 0px;
  z-index: 100000;
`;

export const OrderItemActionButton = styled(Button)`
  border: 1px solid #EEEEEE;
`;

export const OrderItemEditButton = styled(OrderItemActionButton)<{ $confirmed: string }>`
  background-color: ${(props) => (props.$confirmed === 'true' ? theme.palette.success.hue : theme.palette.primary.hue)} !important;
  &:hover {
    background-color: ${(props) => (props.$confirmed === 'true' ? theme.palette.success.D100 : theme.palette.primary.D100)} !important;
  }
`;

export const OrderItemActionRow = styled(FlexRow)`
  justify-content: space-between;
  margin: 4px 12px;
  gap: 8px;
`;

export const ComponentWrapper = styled.li<{ $showOrderItem: boolean }>`
  width: 100%;
  display: ${(props) => (props.$showOrderItem ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 4px;
  background-color: ${theme.palette.neutral.white};
  border-bottom: 1px solid ${theme.palette.neutral[500]};
  gap: 16px;

  & p {
    text-wrap: wrap;
  }
`;

export const PartDetailsWrapper = styled(FlexColumn)`
  width: 150px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const ClosedStatus = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 0px 6px;
  background-color: ${theme.palette.neutral[200]};
  color: ${theme.palette.neutral.black};
  font-size: 12px;
  letter-spacing: 3px;
  text-align: center;
`;

export const MakeStatus = styled(ClosedStatus)`
  background-color: ${theme.palette.primary[200]};
  letter-spacing: unset;
  margin-bottom: 2px;
`;

export const AssignedStatus = styled(ClosedStatus)`
  background-color: ${theme.palette.primary.D200};
  color: white;
  letter-spacing: unset;
`;

export const ShippedStatus = styled(MakeStatus)`
  background-color: ${theme.palette.success[200]};
`;

export const RevisionStatus = styled(MakeStatus)`
  background-color: ${theme.palette.warning.hue};
  color: ${theme.palette.warning[200]};
  font-weight: bold;
`;

export const CanceledStatus = styled(MakeStatus)`
  background-color: ${theme.palette.error[200]};
`;

export const PartNumberWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
`;

export const PartNumber = styled.p`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const PartQuantity = styled.p`
  margin-bottom: 4px;
  letter-spacing: -1.25px;
`;

export const PartDescriptionWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
`;

export const PartPriceDataWrapper = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: flex-end;
  &:nth-of-type(2) {
    align-items: flex-start;
  }
`;

export const PartPriceLabel = styled.p<{ $bold?: boolean }>`
  margin: 0;
  font-weight: ${(props) => (props.$bold ? 'bold' : 'normal')};
  color: ${theme.palette.neutral[400]};
  text-transform: uppercase;
`;

export const PartPriceWrapper = styled(FlexRow)`
  gap: 8px;
  justify-content: flex-end;
`;

export const PartPrice = styled.p<{ $bold?: boolean }>`
  font-weight: ${(props) => (props.$bold ? 'bold' : 'normal')};
  margin: 0;
`;

export const PartDescription = styled.p`
  margin: 0;
`;

export const PartNotesWrapper = styled(PartDescriptionWrapper)`
  gap: 0;
`;

export const PartInternalNotesWrapper = styled(PartDescriptionWrapper)`
  gap: 0;
  & p {
    background-color: yellow;
    font-weight: bold;
  }
`;

export const PartJobNotes = styled.p`
  font-weight: 200;
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 0;
`;

export const DragHandleItem = styled.a`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-color: transparent;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 2px 4px -1px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

export const DragHandleIcon = styled(DragHandle)`
  width: 20px;
  margin-bottom: 2px;
  color: grey;
`;
