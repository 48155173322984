import React, { useState, useEffect } from 'react';
import {
  Document, Font, Page, PDFViewer, StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { useRecoilValue } from 'recoil';
import { ICustomerRecord, IShipment } from 'shared/types/dbRecords';
import {
  currentShopOrderAtom,
  orderShipmentsAtom,
} from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';
import { ICustomerPart } from 'shared/types/parts';
import InvoiceContinuation from './Components/Invoice/InvoiceContinuation';
import PackingSlipLogisticsDetail from './Components/PackingSlip/PackingSlipLogisticsDetail';
import PackingSlipHeader from './Components/PackingSlip/PackingSlipHeader';
import ShippingBillingInfo from './Components/Invoice/BillingShippingInfo';
import { PackingSlipItem, PackingSlipItemHeader } from './Components/PackingSlip/PackingSlipItem';
import PackingSlipNotes from './Components/PackingSlip/PackingSlipNotes';
import PriceListHeader from './Components/PriceList/Header';
import PricingTable from './Components/PriceList/PricingTable';

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const HORIZONTAL_PADDING = 18;
const VERTICAL_PADDING = 36;

const style = StyleSheet.create({
  viewer: {
    width: '75vw',
    height: '100vh',
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: VERTICAL_PADDING * 1.5,
    paddingLeft: HORIZONTAL_PADDING,
    paddingRight: HORIZONTAL_PADDING * 2,
    paddingBottom: VERTICAL_PADDING,
  },
  priceListBody: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: -4,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 24,
    left: 0,
    right: 24,
    textAlign: 'right',
    color: 'grey',
  },
});

interface IComponent {
  customer: ICustomerRecord;
  customerParts: ICustomerPart[];
}

const PriceListPDF = ({ customer, customerParts }: IComponent) => {
  const [_customer, setCustomer] = useState<ICustomerRecord>(customer);
  const [_customerParts, setCustomerParts] = useState<ICustomerPart[]>(customerParts);

  useEffect(() => {
    setCustomer(customer);
    setCustomerParts(customerParts);
  }, [customer, customerParts]);

  return (
    <>
      {(_customerParts.length > 0 && (
        <>
          <PDFViewer showToolbar style={style.viewer}>
            <Document title={`Price List - ${customer.CompanyName} - ${new Date().toLocaleDateString()}`}>
              <Page size="LETTER" style={style.page}>
                <PriceListHeader customer={_customer} />
                <View style={style.priceListBody}>
                  <PricingTable customerParts={_customerParts} />
                </View>
                <Text
                  style={style.pageNumber}
                  render={({ pageNumber, totalPages }) => (
                    totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
                  )}
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </>
      ))}
    </>
  );
};

export default PriceListPDF;
