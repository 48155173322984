import React, { useState } from 'react';
import shortid from 'shortid';
import firebase from 'firebase';
import styled from 'styled-components';
import { Button, Popover, Tooltip } from 'antd';
import { MoveUp, MoveDown } from '@styled-icons/material';
import theme from 'shared/theme';
import { IShopOrder } from 'pages/Orders/types';
import { releaseDate } from 'shared/util/order';
import { firstOfNextWorkWeek, lastOfPrevWorkWeek } from 'shared/data/calendar';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue } from 'recoil';
import { includes } from 'lodash';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { Spinner } from '../../../../shared/components/Utility/Loader';
import { customerUserEmailsAtom } from '../../../../shared/state/routingState';
import SearchGeometricPartButton
  from '../../../Customer/Components/PriceList/PricedItem/Buttons/SearchGeometricPartButton';
import SeeParentPartButton from '../../../Customer/Components/PriceList/PricedItem/Buttons/SeeParentPartButton';
import CopyDescriptionButton from '../../../Orders/Components/SalesOrderRecord/Buttons/CopyDescriptionButton';
import ScopedComponent from '../../../../shared/components/Utility/ScopedComponent';
import DeleteItemButton from '../../../Customer/Components/PriceList/PricedItem/Buttons/DeleteItemButton';
import { FlexRow } from '../../../../shared/containers/FlexContainer';
import OtherActionsButton from '../../../../shared/buttons/OtherActionsButton';
import { cleanCurrentUrl } from '../../../../shared/util';

const MoveUpIcon = styled(MoveUp)`
  color: ${theme.palette.primary.hue};
  width: 24px;
`;
const MoveDownIcon = styled(MoveDown)`
    color: ${theme.palette.primary.hue};
    width: 24px;
`;

const BumpButton = styled(Button)`
  border: none; 
  &:hover {
    background-color: ${theme.palette.primary.hue};
    & svg {
      color: ${theme.palette.neutral.white};
    }
  }
`;

const ButtonPopover = styled(Popover)`
  & .ant-popover-inner {
    border-radius: 12px !important;
  }
`;

const MoreButtonsWrapper = styled(FlexRow)`
    gap: 4px;
`;

interface IComponent {
  order: IShopOrder;
}

const BumpButtons = ({ order }: IComponent) => {
  const { firestore } = useFirebase();
  const [previousWorking, setPreviousWorking] = useState<boolean>(false);
  const [nextWorking, setNextWorking] = useState<boolean>(false);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);

  const onBumpOrder = (reverse: boolean) => async (e: any) => {
    if (reverse) {
      setPreviousWorking(true);
    } else {
      setNextWorking(true);
    }
    const newShipDate = reverse ? lastOfPrevWorkWeek(order.shipDate.toDate()) : firstOfNextWorkWeek(order.shipDate.toDate());
    // @ts-ignore
    const newReleaseDate = (order.runners || []).length > 0 ? firebase.firestore.Timestamp.fromDate(order.releaseDate) : releaseDate({ ...order, shipDate: newShipDate });
    const updatedOrder = { ...order };
    updatedOrder.shipDate = newShipDate;
    if (!(updatedOrder.runners || []).length) updatedOrder.releaseDate = newReleaseDate;

    await firestore.collection(ordersDbString).doc(order.id).update({ shipDate: newShipDate, releaseDate: newReleaseDate });
    setPreviousWorking(false);
    window.location.href = `${cleanCurrentUrl()}#${order.id}`;
  };

  return (
    <MoreButtonsWrapper>
      <Tooltip placement="top" title="Bump order to previous week">
        {!previousWorking ? (
          <BumpButton
            key={shortid.generate()}
            shape="circle"
            onClick={onBumpOrder(true)}
            icon={<MoveUpIcon />}
          />

        ) : (
          <Spinner radius={20} />
        )}
      </Tooltip>
      <Tooltip placement="top" title="Bump order to next week">
        {!nextWorking ? (
          <BumpButton
            key={shortid.generate()}
            shape="circle"
            onClick={onBumpOrder(false)}
            icon={<MoveDownIcon />}
          />

        ) : (
          <Spinner radius={20} />
        )}
      </Tooltip>
    </MoreButtonsWrapper>
  );
};

const OrderBumpButton = ({ order }: IComponent) => (
  <OtherActionsButton
    MoreButtons={<BumpButtons order={order} />}
    placement="left"
    iconColor={theme.palette.primary.hue}
    width="20px"
    noBorder
  />
);
export default OrderBumpButton;
