import { find, includes } from 'lodash';
import {
  IModel, IWood, IBinding, INeckComposition, ISimpleConfig, INeckShape, IFretwire, IInlay, INeckFeatures, ICustomerPart, IModelScale,
} from 'shared/types/parts';
import { cleanMeta } from 'shared/text';
import { BODY_PICKUP_MODIFIERS, BODY_PICKUPS } from './parsingTerms';

// Universal resolvers
export const resolveArchetype = (partConfig: any): { text: string } => {
  const archetype = find(partConfig, (t) => t.type === 'Archetype');
  return archetype ? { text: archetype.terms[0] } : { text: '' };
};

export const resolveModel = (partConfig: any): IModel => {
  const superModel = find(partConfig, (t) => t.type === 'Model_Supermodel');
  const _model = find(partConfig, (t) => t.type === 'Model');
  const subModel = find(partConfig, (t) => t.type === 'Model_Submodel');
  const modifiers = find(partConfig, (t) => t.type === 'Model_Modifier');
  return {
    superModel: superModel ? superModel.terms[0] : '',
    model: _model ? _model.terms[0] : '',
    subModel: subModel ? subModel.terms.join('_') : '',
    modifiers: modifiers ? modifiers.terms : [],
    text: [(_model ? _model.terms[0] : ''), (subModel ? subModel.terms.join('_') : ''), (modifiers ? modifiers.terms.join('_') : '')].filter((t) => t !== '').join('_'),
  };
};

export const resolveModelScale = (partConfig: any): IModelScale => {
  const scale = find(partConfig, (t) => t.type === 'Instrument_Profile');
  if (!scale) return { stringCount: 0, fretCount: 0, scaleLength: 0 };
  let [stringCount, fretCount, scaleLength] = [0, 0, 0];
  if (scale.terms.length === 1) {
    const split = scale.terms[0].split('/');
    stringCount = parseInt(split[0], 10);
    fretCount = parseInt(split[1], 10);
    scaleLength = parseInt(split[2], 10);
  } else {
    stringCount = parseInt(scale.terms[0], 10);
    fretCount = parseInt(scale.terms[1], 10);
    scaleLength = parseInt(scale.terms[2], 10);
  }
  return { stringCount: stringCount || 0, fretCount: fretCount || 0, scaleLength: scaleLength || 0 };
};

export const resolveBodyFeatures = (partConfig: any): { terms: string[] } => {
  const bodyFeatures = find(partConfig, (t) => t.type === 'Body_Feature');
  return bodyFeatures ? { terms: bodyFeatures.terms } : { terms: [] };
};

export const resolveSpreadDimensions = (partConfig: any): { spreadLength: number, spreadWidth: number, spreadThick: number } => {
  const archetype = resolveArchetype(partConfig);
  const model = resolveModel(partConfig);
  let width = 14;
  let length = 20;
  const thick = 1.85;

  if (archetype.text.match(/BB/)) {
    width = 15;
    length = 22;
  }
  if (archetype.text.match(/GB/)) {
    if (model.text.match(/Jazzmaster|Telemaster|Jaguar/)) {
      width = 15;
      length = 22;
    } else if (model.text.match(/TL|Tele|CE|Esquire|Macon/i)) {
      width = 14;
      length = 18;
    } else {
      width = 14;
      length = 20;
    }
  }
  return { spreadLength: length, spreadWidth: width, spreadThick: thick };
};

export const resolveBodyConstructionModifiers = (partConfig: any): { terms: string[] } => {
  const bodyConstruction = find(partConfig, (t) => t.type === 'Body_Construction_Modifier');
  return bodyConstruction ? { terms: bodyConstruction.terms } : { terms: [] };
};

export const resolveWood = (partConfig: any): IWood => {
  const archetype = resolveArchetype(partConfig);
  const wood = find(partConfig, (t) => t.type === 'Wood');
  if (!wood) return { core: '', top: '', veneer: '' };

  // if wood has already been split into discrete terms, return them
  if (wood.terms.length > 1) {
    const isBodyArchetype = ['BB', 'GB'].includes(archetype.text);

    if (wood.terms.length === 3) {
      return isBodyArchetype
        ? { core: wood.terms[0], top: wood.terms[2] || '', veneer: wood.terms[1] || '' }
        : { core: wood.terms[0], top: wood.terms[1] || '', veneer: wood.terms[2] || '' };
    }

    return { core: wood.terms[0], top: wood.terms[1] || '', veneer: '' };
  }

  const parts = wood.terms[0].split('/').filter(Boolean);

  // Handle body wood (GB/BB)
  if (['GB', 'BB'].includes(archetype.text)) {
    if (parts.length === 3) {
      const [core, veneer, top] = parts;
      return { core, top: top || '', veneer: veneer || '' };
    }
    const [core, top] = parts;
    return { core, top: top || '', veneer: '' };
  }

  // Handle neck wood
  if (parts.length === 3) {
    const [core, top, veneer] = parts;
    return { core, top: top || '', veneer: veneer || '' };
  }
  const [core, top] = parts;
  return { core, top: top || '', veneer: '' };
};

// Body resolvers
export const resolveBodyThickness = (partConfig: any): { text: string, value: number } => {
  const thickness = find(partConfig, (t) => t.type === 'Body_Thickness');
  return thickness ? { text: thickness.terms[0], value: parseFloat(thickness.terms[0]) } : { text: '', value: 0 };
};

export const resolvePickups = (partConfig: any): { neck: string; middle: string; bridge: string; text: string } => {
  const pickups = find(partConfig, (t) => t.type === 'Pickups');
  if (!pickups) {
    return {
      neck: '', middle: '', bridge: '', text: '',
    };
  }

  if (pickups.terms.length === 1) {
    return {
      neck: pickups.terms[0],
      middle: '',
      bridge: '',
      text: pickups.terms[0],
    };
  }
  if (pickups.terms.length === 2) {
    return {
      neck: pickups.terms[0],
      middle: '',
      bridge: pickups.terms[1],
      text: `${pickups.terms[0]}/${pickups.terms[1]}`,
    };
  }
  if (pickups.terms.length === 3) {
    return {
      neck: pickups.terms[0],
      middle: pickups.terms[1],
      bridge: pickups.terms[2],
      text: `${pickups.terms[0]}/${pickups.terms[1]}/${pickups.terms[2]}`,
    };
  }

  const pickupTypes = Object.values(BODY_PICKUPS).flat();
  const pickupModifiers = Object.values(BODY_PICKUP_MODIFIERS).flat();

  // Special case for Nashville pickup config, which is S/S/H
  if (pickups?.terms[0]?.match('Nashville')) {
    return {
      neck: 'S',
      bridge: 'H',
      middle: 'S',
      text: 'Nashville S/S/H',
    };
  }

  const pickupPattern = new RegExp(`((${pickupModifiers.join('|')})?(${pickupTypes.join('|')}\/?)){1,3}`, 'gi');
  const match = pickups ? pickups.terms[0].match(pickupPattern) : null;
  if (!match) {
    return {
      neck: '', middle: '', bridge: '', text: '',
    };
  }

  const [neck = '', middle = '', bridge = ''] = match;

  const cleanedPickups = {
    neck: neck.replace(/[_]|\/$/g, ''),
    middle: middle.replace(/[_]|\/$/g, ''),
    bridge: bridge.replace(/[_]|\/$/g, ''),
  };

  // If neck and middle are defined, and bridge is empty or undefined,
  // move middle to bridge and clear middle
  if (cleanedPickups.neck !== '' && cleanedPickups.middle !== '' && (cleanedPickups.bridge === '' || cleanedPickups.bridge === '')) {
    cleanedPickups.bridge = cleanedPickups.middle;
    cleanedPickups.middle = '';
  }

  const text = Object.values(cleanedPickups)
    .filter((pickup) => pickup !== '')
    .join('/');

  return { ...cleanedPickups, text };
};
export const resolveWeight = (partConfig: any): { text: string, value: number } => {
  const weightRegex = /(\d+)\s*Lb[s]?\s*(\d*)/i;

  // Step 1: Check for Weight type
  const weightConfig = find(partConfig, (t) => t.type === 'Weight');
  if (weightConfig) {
    const match = weightConfig.terms[0].match(weightRegex);
    if (match) {
      const [fullText, pounds, ounces] = match;
      return {
        text: fullText,
        value: (parseInt(pounds, 10) || 0) + ((parseInt(ounces, 10) || 0) / 16),
      };
    }
  }

  // Step 2: Search all config elements for weight pattern
  const configMatch = find(partConfig, (t) => t.terms.some((term: string) => weightRegex.test(term)));
  if (configMatch) {
    const match = configMatch.terms.find((term: string) => weightRegex.test(term))?.match(weightRegex);
    if (match) {
      const [fullText, pounds, ounces] = match;
      return {
        text: fullText,
        value: (parseInt(pounds, 10) || 0) + ((parseInt(ounces, 10) || 0) / 16),
      };
    }
  }

  // Step 3: Return default if no matches found
  return {
    text: '',
    value: 0,
  };
};

export const resolveWeightReductionType = (partConfig: any): { text: string } => {
  const modelModifier = find(partConfig, (t) => t.type === 'Model_Modifier');
  if (!modelModifier) return { text: 'None' };
  const weightReductionType = find(modelModifier.terms, (t) => t.match(/^WR/i));
  return weightReductionType ? { text: weightReductionType } : { text: 'None' };
};
// Neck resolvers
export const resolveBinding = (partConfig: any): IBinding => {
  const binding = find(partConfig, (t) => t.type === 'Binding');
  if (!binding) return { material: 'None', type: 'None', text: 'None' };

  const material = binding.terms[0].replace(/bind(ing)?/i, '');
  return {
    material: material === 'Shelf' ? 'None' : material,
    type: binding.terms[0].match(/shelf/i) ? 'Shelf' : 'Binding',
    text: binding.terms[0],
  };
};

export const resolveNeckComposition = (partConfig: any): INeckComposition => {
  const neckComposition = find(partConfig, (t) => t.type === 'Neck_Composition');
  return neckComposition ? { terms: neckComposition.terms } : { terms: [] };
};

export const resolveTrussRod = (partConfig: any): ISimpleConfig => {
  const trussRod = find(partConfig, (t) => t.type === 'Truss_Rod');
  return trussRod ? { text: trussRod.terms[0] } : { text: '' };
};

export const resolveNeckShape = (partConfig: any): INeckShape => {
  const neckShapeNut = find(partConfig, (t) => t.type === 'Neck_Shape_Nut');
  const neckShapeCarve = find(partConfig, (t) => t.type === 'Neck_Shape_Carve');
  const neckShapeRadius = find(partConfig, (t) => t.type === 'Neck_Shape_Radius');
  if (!neckShapeNut && !neckShapeCarve && !neckShapeRadius) {
    return {
      nut: '', carve: '', radius: '', isCompoundRadius: false,
    };
  }

  const nutWidth = find(neckShapeNut?.terms, (t) => t.match(/N$/)) || '';
  const nutType = find(neckShapeNut?.terms, (t) => !t.match(/N$/)) || 'Standard';

  return {
    nutWidth,
    nutType,
    carve: neckShapeCarve?.terms.join('_') || '',
    radius: neckShapeRadius?.terms.join('_') || '',
    isCompoundRadius: !!neckShapeRadius?.terms[0]?.match(/CR/),
  };
};

export const resolveFretwire = (partConfig: any): IFretwire => {
  const fretwire = find(partConfig, (t) => includes(['Fretwire_Prefix', 'Fretwire', 'Fretwire_Suffix'], t.type));
  if (!fretwire) return { prefix: '', wire: '', suffix: '' };
  return {
    prefix: find(fretwire, (s) => s.type === 'Fretwire_Prefix')?.terms[0].split('_')[0] || '',
    wire: find(fretwire, (s) => s.type === 'Fretwire')?.terms[0].split('_')[0] || '',
    suffix: find(fretwire, (s) => s.type === 'Fretwire_Suffix')?.terms[0].split('_')[0] || '',
  };
};

export const resolveInlay = (partConfig: any): IInlay => {
  const inlay = find(partConfig, (t) => t.type === 'Inlay');
  if (!inlay) return { front: { material: 'None', shape: 'None' }, side: { material: 'None', shape: 'None' }, text: 'None' };

  const frontShapeMatcher = /Block[s]?|(Open)?Frame[s]?|Crown[s]?|Leaves|Oval[s]?|F$/i;
  const sideShapeMatcher = /S$/;

  // Join all terms with '/' to handle both single and multiple term cases
  const inlayString = inlay.terms.join('/');

  // Find face and side inlays using matchers
  const frontInlay = inlayString.split('/').find((t) => frontShapeMatcher.test(t));
  const sideInlay = inlayString.split('/').find((t) => sideShapeMatcher.test(t));

  // Extract shape and material for front inlay
  const frontShape = frontInlay?.match(frontShapeMatcher)?.[0] || '';
  const frontMaterial = frontInlay?.replace(frontShapeMatcher, '') || '';

  // Extract shape and material for side inlay
  const sideShape = sideInlay?.match(sideShapeMatcher)?.[0] || '';
  const sideMaterial = sideInlay?.replace(sideShapeMatcher, '') || '';

  return {
    front: { material: frontMaterial, shape: frontShape },
    side: { material: sideMaterial, shape: sideShape },
    text: inlay.terms[0],
  };
};

export const resolveNeckFeatures = (partConfig: any): INeckFeatures => {
  const neckFeatures = find(partConfig, (t) => t.type === 'Neck_Features');
  return neckFeatures ? { terms: neckFeatures.terms } : { terms: [] };
};
