import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useTestData, isDevSite } from 'shared/util';
import { INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import useFirebase from 'vendor/Firebase';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { IInventoryPart } from 'shared/types/dbRecords';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const ConsumeMaterialsButton = () => {
  const { database, firestore } = useFirebase();
  const [locked, setLocked] = useState<boolean>(true);
  const [progressLabel, setProgressLabel] = useState<string>('');
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);

  const updateHelmInventory = async (sku: string, quantity: number) => {
    const helmInventoryDoc = await firestore.collection(inventoryDbString).doc(sku).get();
    const helmInventory = helmInventoryDoc.data() as IInventoryPart;
    if (!helmInventory) return;
    const newQuantity = helmInventory.QtyOnHand - quantity;
    if (newQuantity < 0) return;
    await firestore.collection(inventoryDbString).doc(sku).update({ QtyOnHand: newQuantity });
  };

  const onComplete = () => {
    Modal.success({
      title: 'Complete',
      content: 'All locked inventory adjustments have been processed.',
      onOk: () => {
        setProgressLabel('');
      },
    });
  };

  const onClick = async (e: any) => {
    const adjustmentDataDoc = await database.ref('/util/adjustmentData').once('value');
    const adjustmentData = adjustmentDataDoc.val();

    const inventoryAdjustments = Object.values(adjustmentData)
      .filter((a) => typeof a !== 'boolean')
      .reduce((acc: { [key: string]: number }, adjustments: any[]) => {
        adjustments.forEach((adjustment) => {
          const key = adjustment.Name;
          acc[key] = (acc[key] || 0) + adjustment.adjustmentQuantity;
        });
        return acc;
      }, {});

    await Promise.all(Object.entries(inventoryAdjustments).map(([key, value]) => updateHelmInventory(key as string, value as number)));
    await database.ref('/util/adjustmentData').set({ lock: false });
    return onComplete();
  };

  useEffect(() => {
    database.ref('/util/adjustmentData').once('value').then((data) => {
      const lockedData = data.val();
      if (!lockedData) return;
      setLocked(lockedData.lock || false);
      if (Object.keys(lockedData.data || {}).length > 0) {
        const itemsToConsume = Object.keys(lockedData.data).length;
        setProgressLabel(`${itemsToConsume} item${itemsToConsume > 1 ? 's' : ''} to consume`);
      }
    });
  }, []);

  return (
    <FlexColumn align="flex-end" justify="flex-start">
      <ComponentButton type="default" onClick={onClick} disabled={!locked || useTestData || isDevSite()}>Unlock Consumption & Consume Material</ComponentButton>
      {!!progressLabel.length && (
        <p>{progressLabel}</p>
      )}
    </FlexColumn>
  );
};

export default ConsumeMaterialsButton;
