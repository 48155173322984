import React, { useContext, useState, useEffect } from 'react';
import { AddCircle, Search } from '@styled-icons/material';
import styled from 'styled-components';
import {
  useRecoilState, useRecoilValue, useSetRecoilState,
} from 'recoil';
import {
  every, find, flattenDeep, includes, reverse, sortBy,
} from 'lodash';
import {
  searchModalAtom,
  salesOrdersAtom,
  scheduleViewStartDateAtom,
  scheduleViewEndDateAtom,
  orderWeeksAtom,
  adminUserEmailsAtom, superAdminUserEmailAtom,
} from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import moment from 'moment';
import firebase from 'firebase';
import {
  getOrderWeeks, SALES_ORDER_END_KEY, SALES_ORDER_START_KEY,
} from 'shared/data/calendar';
import { salesRecord } from 'shared/router/clientRunner';
import {
  ORDER_ITEMS_DB_COLLECTION,
  orderPartViewTypeAtom,
  ORDERS_DB_COLLECTION,
} from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';
import { DatePickerNextIcon, DatePickerPrevIcon } from 'shared/styledComponents/inputs';
import { useParams } from 'react-router-dom';
import { CREDIT_MEMO_DB_COLLECTION } from 'shared/state/siteState';
import Loader from 'shared/components/Utility/Loader';
import { listFilterQuery, stageRedirect } from 'shared/util';
import { ButtonIsland } from 'shared/styledComponents/containers';
import theme from 'shared/theme';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { PageTitle } from 'shared/styledComponents/typographicElements';
import { IOrderItem } from 'shared/types/dbRecords';
import ItemListFilter from 'shared/components/Input/ItemListFilter';
import { ICreditMemo } from 'shared/types/qbo';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { currentCustomerAtom } from 'shared/state/customerState';
import {
  NewOrderButton,
  OrderRangePicker,
  RunnerHeaderRow,
} from './styledComponents';
import SalesOrderByWeek from './SalesOrderByWeek';
import SalesOrderByCustomer from './SalesOrderByCustomer';
import SalesOrdersComingSoon from './SalesOrderComingSoon';
import DownloadWeekOrdersButton from './Components/Buttons/DownloadWeekOrdersButton';
import { IRunner, IShopOrder } from '../Orders/types';
import ScheduleViewSelector from './Components/Buttons/ScheduleViewSelector';
import SalesOrderRequirements from './SalesOrderRequirements';
import PartTypeSelector from './Components/Buttons/PartTypeSelector';
import HidePositiveQuantitiesFilter from './Components/JobRequirements/Filters/HidePositiveQuantitiesFilter';
import SeriesCreationModal from '../Orders/Components/SalesOrderRecord/SeriesCreationModal';

const shortid = require('shortid');

const SalesOrderComponentWrapper = styled.div`
    width: calc(100vw - 48px);
    margin: 0 auto;
    @media ${theme.device.laptopL} {
        width: 100%;
    }
`;
const HeaderRow = styled(RunnerHeaderRow)`
    align-items: center;
`;

const PageWrapper = styled(ConfigurationCol)`
    width: 100%;
    @media ${theme.device.laptop} {
        padding: 0;
        margin: 0 auto 16px auto;
    }
    @media ${theme.device.laptopL} {
        padding: 0 24px;
        width: calc(90vw - 48px);
    }
`;

const NewIcon = styled(AddCircle)`
    width: 20px;
    position: relative;
    left: -4px;
    margin: 0 4px 3px 0px;
`;

const OrderFilterWrapper = styled(FlexRow)`
    flex-grow: 2;
    gap: 16px;
    justify-content: flex-start;
`;

const WeekFilterWrapper = styled(FlexRow)`
    gap: 8px;
    margin-left: 12px;
    justify-content: flex-end;
`;
const SearchIcon = styled(Search)`
    width: 20px;
    position: relative;
`;

const NewSeriesButton = styled(NewOrderButton)`
  margin-left: 8px;
`;

interface IComponent {
  type: 'week'|'customer'|'soon'|'requirement';
}

export default () => {
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  // const { customerId } = useParams();

  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const setShowSearchModal = useSetRecoilState(searchModalAtom);
  const [salesOrders, setSalesOrders] = useState<IShopOrder[]>([]);
  const setOrderWeeks = useSetRecoilState(orderWeeksAtom);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const creditMemosDbString = useRecoilValue(CREDIT_MEMO_DB_COLLECTION);
  const currentCustomer = useRecoilValue(currentCustomerAtom);

  const [, setHighlightRefreshDates] = useState(false);
  const orderItemsDbCollection = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);

  const [startDate, setStartDate] = useRecoilState(scheduleViewStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(scheduleViewEndDateAtom);

  const orderViewTypeKey = 'salesOrderViewType';
  const [orderType, setOrderType] = useState<'week'|'customer'|'soon'|'requirements'>(localStorage.getItem(orderViewTypeKey) as 'week'|'customer'|'soon'|'requirements' || 'week');
  const [ordersLoaded, setOrdersLoaded] = useState<boolean>(false);
  const [renderItems, setRenderItems] = useState<IShopOrder[]>([]);
  const [credits, setCredits] = useState<ICreditMemo[]>([]);
  const sessionKey = 'order.query.string';
  const sessionRequirementsKey = 'jobRequirement.query.string';
  const sessionViewTypeKey = 'partViewType';
  const [orderQuery, setOrderQuery] = useState<string>(sessionStorage.getItem(sessionKey) || '');
  const [requirementsQuery, setRequirementsQuery] = useState<string>(sessionStorage.getItem(sessionRequirementsKey) || '');
  const [_partViewType, setPartViewType] = useState<'all'|'body'|'neck'>(localStorage.getItem(sessionViewTypeKey) as 'all'|'body'|'neck' || 'all');
  const [showBodies, setShowBodies] = useState<boolean>(includes(['all', 'body'], _partViewType));
  const [showNecks, setShowNecks] = useState<boolean>(includes(['all', 'neck'], _partViewType));
  const [loading, setLoading] = useState<boolean>(false);
  const [showSeriesModal, setShowSeriesModal] = useState<boolean>(false);

  const updatePageData = (searchQuery: string, orders: IShopOrder[] = salesOrders): void => {
    const _query = listFilterQuery(searchQuery);
    // @ts-ignore
    const items = reverse(sortBy(orders, (o: IShopOrder) => o.shipDate.toDate()))
      .filter((o: IShopOrder) => {
        if (_query.length === 0) return o;

        const jobNotes = o.runners ? flattenDeep(o.runners.map((r: IRunner) => r.parts?.map((p: IOrderItem) => p.notes))).join('') : '';
        let matchString = `${o.purchaseOrder}${o.salesOrder}${o.description}${o.type}${(o.orderValue || 0).toString()}${o.customer.id}${o.customer.CompanyName}${jobNotes}`;

        const partNames = o.orderItems.filter((i: IOrderItem) => i).map((i) => i.Sku).join('');
        const descriptions = o.orderItems.filter((i: IOrderItem) => i).map((i) => i.Description).join('');
        matchString += `${partNames}${descriptions}`;

        if (o.orderDate) matchString += o.orderDate.toLocaleString();
        if (o.shipDate) matchString += o.shipDate.toDate().toLocaleString();
        const matches = _query.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      });
    setOrdersLoaded(true);
    // @ts-ignore
    setRenderItems(items);
  };
  const onAddSalesOrder = (e: any) => {
    localStorage.removeItem('currentShopOrder.id');
    localStorage.removeItem('currentOrder');
    stageRedirect();
    window.location.href = '/orders/create';
  };

  const onAddSalesOrderSeries = () => {
    setShowSeriesModal(true);
  };

  const onChangeDates = (date: any) => {
    setSalesOrders([]);
    const [sDate, eDate] = date;
    setHighlightRefreshDates(true);
    const newStartDate = new Date(sDate.year(), sDate.month(), sDate.date());
    setStartDate(newStartDate);
    localStorage.setItem(SALES_ORDER_START_KEY, newStartDate.getTime().toString());

    const newEndDate = new Date(eDate.year(), eDate.month(), eDate.date());
    setEndDate(newEndDate);
    localStorage.setItem(SALES_ORDER_END_KEY, newEndDate.getTime().toString());
    fetchOrders(orderType, newStartDate, newEndDate);
  };

  const onChangeFilterTerms = (query: string) => {
    setOrdersLoaded(false);
    setOrderQuery(query);
    sessionStorage.setItem(sessionKey, query);
    updatePageData(query, salesOrders);
  };

  const onChangeRequirementFilterTerms = (query: string) => {
    sessionStorage.setItem(sessionKey, query);
    setRequirementsQuery(query);
  };

  const onChangeViewType = (viewType: 'week'|'customer'|'soon'|'requirements') => {
    setOrderType(viewType);
    fetchOrders(viewType);
  };

  const onChangePartViewType = (partViewType: 'all'|'body'|'neck') => {
    setPartViewType(partViewType);
    localStorage.setItem(sessionViewTypeKey, partViewType);
    setShowBodies(includes(['all', 'body'], partViewType));
    setShowNecks(includes(['all', 'neck'], partViewType));
  };

  const fetchOrders = async (viewType: 'week'|'customer'|'soon'|'requirements', _start: Date = startDate, _end: Date = endDate) => {
    setLoading(true);
    setSalesOrders([]);
    setRenderItems([]);
    const routerStepDocs = await database.ref('/router/steps').once('value');
    const routerSteps = routerStepDocs.val();

    const sDate = new Date(_start.getFullYear(), _start.getMonth(), 1);
    sDate.setDate(sDate.getDate() - 1);
    const eDate = new Date(_end.getFullYear(), _end.getMonth(), _end.getDate());
    eDate.setDate(eDate.getDate() + 1);

    const _orderWeeks = getOrderWeeks(_start, _end);
    setOrderWeeks(_orderWeeks);

    let query;
    if (window.location.href.match(/requirements/)) {
      const firstOfCurrentMonth = new Date();
      firstOfCurrentMonth.setHours(0, 0, 0, 0);
      firstOfCurrentMonth.setDate(1);

      const endOfThirdMonth = new Date(firstOfCurrentMonth);
      endOfThirdMonth.setMonth(firstOfCurrentMonth.getMonth() + 3);
      endOfThirdMonth.setDate(endOfThirdMonth.getDate() - 1);

      query = firestore.collection(ordersDbString)
        .where('shipDate', '>', firebase.firestore.Timestamp.fromDate(firstOfCurrentMonth))
        .where('shipDate', '<', firebase.firestore.Timestamp.fromDate(endOfThirdMonth));
    } else if (window.location.href.match(/customer/) && currentCustomer.id) {
      query = firestore.collection(ordersDbString)
        .where('customer.DisplayName', '==', currentCustomer.id)
        .where('shipDate', '>', firebase.firestore.Timestamp.fromDate(sDate))
        .where('shipDate', '<', firebase.firestore.Timestamp.fromDate(eDate));
    } else if (window.location.href.match(/up-next/)) {
      const upcomingReleaseDate = new Date();
      upcomingReleaseDate.setDate(upcomingReleaseDate.getDate() + 14);

      query = firestore.collection(ordersDbString)
        .where('completed', '==', false)
        .where('runners', '==', [])
        .where('releaseDate', '<=', upcomingReleaseDate);
    } else {
      query = firestore.collection(ordersDbString)
        .where('shipDate', '>', firebase.firestore.Timestamp.fromDate(sDate))
        .where('shipDate', '<', firebase.firestore.Timestamp.fromDate(eDate));
    }

    const orderDocs = await query.get();

    const orders = orderDocs.docs.map((doc) => {
      let d = doc.data() as IShopOrder;
      const shipDate = d.shipDate.toDate();
      shipDate.setHours(0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0);
      // Setting Ship Date if ship date is behind current day
      // don't include any orders that are complete, where the ship date is
      // equal to or great than today, and don't do this if today is a weekend.
      const autoUpdateShipDate = shipDate < today && !d.completed && !includes([0, 6], today.getDay());
      if (autoUpdateShipDate) {
        d = {
          ...d,
          shipDate: autoUpdateShipDate ? firebase.firestore.Timestamp.fromDate(today) : d.shipDate,
        };

        firestore.collection(ordersDbString).doc(d.id).update({ shipDate: d.shipDate });
      }
      return salesRecord(routerSteps, d, doc.id);
    });
    const creditMemoDocs = await firestore.collection(creditMemosDbString)
      .where('TxnDate', '>', firebase.firestore.Timestamp.fromDate(sDate))
      .where('TxnDate', '<', firebase.firestore.Timestamp.fromDate(eDate))
      .get();
    const creditMemos = creditMemoDocs.docs.map((cmDoc) => cmDoc.data() as ICreditMemo);
    const orderItems: any[] = [];

    const itemDocs = await Promise.all(orders.map((o) => firestore.collection(orderItemsDbCollection).doc(o.id).get()));
    itemDocs.forEach((doc) => {
      const items = doc.data()?.orderItems;
      const order = find(orders, (o) => o.id === doc.id);
      orderItems.push({ ...(order || {}), orderItems: (items?.filter((i) => i) || []) });
    });

    const orderViewType = localStorage.getItem(orderViewTypeKey);
    const _orderQuery = (orderViewType === 'requirements' ? sessionStorage.getItem(sessionRequirementsKey) : sessionStorage.getItem(sessionKey)) || '';
    setCredits(creditMemos);
    setOrderQuery(_orderQuery);
    setSalesOrders(orderItems);
    updatePageData(_orderQuery, orderItems);
    setLoading(false);
  };

  const onCloseSeriesModal = (refresh: boolean = false) => {
    setShowSeriesModal(false);
    if (refresh) {
      fetchOrders(orderType);
    }
  };

  useEffect(() => {
    fetchOrders(orderType);
  }, [currentCustomer]);
  // @ts-ignore
  return (
    <>
      <SeriesCreationModal
        visible={showSeriesModal}
        onClose={onCloseSeriesModal}
        database={database}
        firestore={firestore}
      />
      <PageWrapper key={shortid.generate()}>
        <HeaderRow key={shortid.generate()}>
          <PageTitle key={shortid.generate()}>Schedule</PageTitle>
          <ButtonIsland noMinWidth expand padding="8px 16px 8px 16px">
            <OrderFilterWrapper>
              {includes([...superAdminEmails.emails, ...adminEmails.emails], currentUser.email) && (
                <>
                  <NewOrderButton
                    key={shortid.generate()}
                    type="primary"
                    shape="round"
                    onClick={onAddSalesOrder}
                    icon={<NewIcon />}
                  >
                    New
                  </NewOrderButton>
                  <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}>
                    <NewSeriesButton
                      key={shortid.generate()}
                      type="primary"
                      shape="round"
                      onClick={onAddSalesOrderSeries}
                      icon={<NewIcon />}
                    >
                      New Series
                    </NewSeriesButton>
                  </ScopedComponent>
                </>
              )}
              {window.location.pathname.match(/requirements/i) ? (
                <>
                  <ItemListFilter
                    id="job-requirement-filter"
                    callback={onChangeRequirementFilterTerms}
                    callbackOnChange={false}
                    initialValue={requirementsQuery}
                    prompt="Filter requirements using comma-separated value, e.g., BBK, Ash, STEMAC"
                    width="75%"
                  />
                  <HidePositiveQuantitiesFilter />
                </>
              ) : (
                <ItemListFilter
                  id="order-record-filter"
                  callback={onChangeFilterTerms}
                  callbackOnChange={false}
                  initialValue={orderQuery}
                  currentValue={orderQuery}
                  prompt="Filter orders using comma-separated terms, e.g. NASH_00041, GN, Alder"
                  width="100%"
                />
              )}
            </OrderFilterWrapper>
            <FlexRow justify="center">
              <ScheduleViewSelector callback={onChangeViewType} viewType={orderType} />
              <ScopedComponent blacklist={['keithh@wildwoodmfg.com']}>
                <PartTypeSelector partViewType={_partViewType} callback={onChangePartViewType} />
              </ScopedComponent>
            </FlexRow>
            <WeekFilterWrapper>
              <DownloadWeekOrdersButton weekStart={startDate} weekEnd={endDate} context="ordersPage" type="all" />
              <OrderRangePicker
                // disabled={orderType === 'requirements'}
                onChange={onChangeDates}
                value={[moment(startDate), moment(endDate)]}
                nextIcon={<DatePickerNextIcon />}
                prevIcon={<DatePickerPrevIcon />}
              />
            </WeekFilterWrapper>
          </ButtonIsland>
          {/* </RunnerActionRow> */}
        </HeaderRow>
        {loading ? (
        // {(renderItems.length === 0 && salesOrders.length === 0) && (
          <FlexColumn
            key="sales-order-loader"
            style={{
              width: '100%', height: '50vw', position: 'absolute', top: 64,
            }}
          >
            <Loader />
          </FlexColumn>
        ) : (
          <SalesOrderComponentWrapper>
            {window.location.pathname.match(/week/i)
            && (
            <SalesOrderByWeek
              key="sales-order-by-week"
              salesOrders={salesOrders}
              renderItems={renderItems}
              ordersLoaded={ordersLoaded}
              creditMemos={credits}
              showBodies={showBodies}
              showNecks={showNecks}
            />
            )}
            {window.location.pathname.match(/customer/i)
            && <SalesOrderByCustomer key="sales-order-by-customer" renderItems={renderItems} showBodies={showBodies} showNecks={showNecks} />}
            {window.location.pathname.match(/up-next/i)
            && <SalesOrdersComingSoon key="sales-order-coming-soon" items={renderItems} showBodies={showBodies} showNecks={showNecks} />}
            {window.location.pathname.match(/requirements/i)
            && <SalesOrderRequirements key="sales-order-requirements" items={renderItems} query={requirementsQuery} />}
          </SalesOrderComponentWrapper>
        )}
      </PageWrapper>
    </>
  );
};
