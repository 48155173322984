import React from 'react';
import { Button, Modal } from 'antd';
import useFirebase from 'vendor/Firebase';
import styled from 'styled-components';

interface CopyToDevButtonProps {
  orderId: string;
}

const CopyButton = styled(Button)`
  border-radius: 8px;
`;

const CopyToDevButton: React.FC<CopyToDevButtonProps> = ({ orderId }) => {
  const { firestore } = useFirebase();
  const onCopy = async () => {
    const orderDoc = await firestore.collection('orders').doc(orderId).get();
    const orderData = orderDoc.data();
    const orderItemsDoc = await firestore.collection('order-items').doc(orderId).get();
    const orderItemsData = orderItemsDoc.data();

    await firestore.collection('orders-dev').doc(orderId).set(orderData);
    await firestore.collection('order-items-dev').doc(orderId).set(orderItemsData);
    Modal.success({
      content: 'Order copied to dev',
    });
  };

  return (
    <CopyButton type="default" onClick={onCopy}>
      Copy to Dev
    </CopyButton>
  );
};

export default CopyToDevButton;
