import React, { useContext } from 'react';
import { Button } from 'antd';
import useFirebase from 'vendor/Firebase';
import firebase from 'firebase/app';
import styled from 'styled-components';
import 'firebase/firestore';
import _, {
  find,
  includes,
  pad,
  update,
  flattenDeep,
  flatten,
  trim,
  differenceBy,
} from 'lodash';
import QBOItem from 'shared/data/QBO/item';
import {
  IBomItem,
  ICustomerRecord,
  IInventoryPart,
  IOrderItem,
  IProductCode,
  IQATicket,
  IShipment,
  IShipmentItem,
} from 'shared/types/dbRecords';
import axios from 'axios';
// import firebase from 'firebase';
import shortid from 'shortid';
import {
  aggregateTermsByType,
  isSamePart,
  sanitizePartDescription,
  configToDescription,
  descriptionToConfig,
  updateConfig,
} from 'shared/partParser/util';
import neckParser from 'shared/partParser/neckParser';
import { partBom } from 'shared/data/jb_api';
import { IRunner, ISalesOrder, IShopOrder } from 'pages/Orders/types';
import bodyParser, {
  bodyWood,
  parseBodiesFromPartData,
  pickups,
} from 'shared/partParser/bodyParser';
import {
  resolveDescription,
  resolveArchetype,
  resolveModel,
  resolveWeight,
  resolvePickups,
  resolveWood,
  resolveInlay,
  resolveNeckShape,
} from 'shared/partParser/partResolver';
import { updateOrderItemBomConsumption } from 'shared/data/order';
import models from 'shared/partParser/models';
import { termTypes, terms } from 'shared/partParser/terms';
import { extractSpecies, partType } from 'shared/partParser/helpers';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import compareParts, {
  establishPartLineages,
  findParent,
} from 'shared/partParser/partComparator';
import {
  partConfigTermsAtom,
  partConfigTermTypesAtom,
} from 'shared/state/partViewState';
import { cleanMeta } from 'shared/text';
import { useRecoilValue } from 'recoil';
import { IConfigEntry, IConfigTerm, ICustomerPart } from 'shared/types/parts';
import { IPartWood } from 'shared/types/pricingTool';
import { resolveFirestorePath } from 'shared/util';

const AdHocButton = styled(Button)`
  border-radius: 8px;
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-right: 8px;
`;

interface ParsedTerms {
  [key: string]: string[];
}

export default () => {
  const { functions, firestore, database } = useFirebase();
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const configTermTypes = useRecoilValue(partConfigTermTypesAtom);
  const { currentUser } = useContext(AuthContext);

  const fetchBom = async (sku: string) => {
    const bomDoc = await firestore.collection('part-bom-data').doc(sku).get();
    if (!bomDoc.exists) return null;
    // @ts-ignore
    return bomDoc.data().bom as IBomItem[];
  };

  const splitTerms = async () => {
    const termDocs = await firestore.collection('part-config-terms-dev').get();
    const _terms = termDocs.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    const existingTerms = new Map(
      termDocs.docs.map((doc) => [
        doc.data().term,
        { id: doc.id, ...doc.data() },
      ]),
    );

    // Helper function to split compound terms
    const splitCompoundTerms = async (tterms: any[]) => {
      const processedTerms: IConfigTerm[] = [];

      tterms.forEach((term) => {
        // Split terms that contain '/' or other separators
        const splitVariants = term.data.term
          .split(/[/+]/)
          .map((t: string) => t.trim());

        // If it's a compound term, we'll delete it
        if (splitVariants.length > 1) {
          console.log(`Deleting compound term: ${term.data.term}`);
          firestore.collection('part-config-terms-dev').doc(term.id).delete();
          return;
        }

        splitVariants.forEach((variant: string) => {
          // Check if the term already exists in the database
          const existingTerm = existingTerms.get(variant);

          if (existingTerm) {
            // Use the existing term, prioritizing coercion if it exists
            const finalTerm: IConfigTerm = {
              id: existingTerm.id,
              term: existingTerm.coercion || variant,
              type: term.data.type,
              english: existingTerm.english,
              coercion: existingTerm.coercion,
            };

            processedTerms.push(finalTerm);
          } else {
            // If the term doesn't exist, add it
            const newTerm: IConfigTerm = {
              id: shortid.generate(), // Generate a new ID
              term: variant,
              type: term.data.type,
            };

            processedTerms.push(newTerm);
          }
        });
      });

      // Remove duplicates while preserving the most complete term
      const uniqueTerms = Array.from(
        new Map(
          processedTerms.map((term) => [
            term.term,
            processedTerms.find((t) => t.term === term.term) || term,
          ]),
        ).values(),
      );

      return uniqueTerms;
    };

    // Process each term type
    const _termTypes = ['Wood', 'Inlay', 'Pickups'];
    _termTypes.forEach(async (type) => {
      const typedTerms = _terms.filter((t) => t.data.type === type);
      const processedTerms = await splitCompoundTerms(typedTerms);

      console.log(`Processing ${type} terms:`);
      console.log('Original count:', typedTerms.length);
      console.log('Processed count:', processedTerms.length);

      // Batch write processed terms back to Firestore
      const batch = firestore.batch();
      processedTerms.forEach((term) => {
        // Only write if the term doesn't already exist
        if (!existingTerms.has(term.term)) {
          const docRef = firestore
            .collection('part-config-terms-dev')
            .doc(term.id);
          batch.set(docRef, {
            term: term.term,
            type,
            ...(term.english && { english: term.english }),
            ...(term.coercion && { coercion: term.coercion }),
          });
        }
      });

      // Commit the batch write
      await batch.commit();
    });
  };

  const establishLineages = async () => {
    const partDocs = await firestore
      .collection('parts')
      .where('customer', '==', 'LULLG')
      .get();
    const parts = partDocs.docs.map((doc) => doc.data()) as ICustomerPart[];
    const lineage = establishPartLineages(parts, configTerms);

    console.log(lineage);

    // const skusToUpdate: string[] = [];
    const BATCH_SIZE = 500;

    // Convert lineage to array of entries
    const lineageEntries = Object.entries(lineage);

    // Prepare chunks for processing
    const chunks = [];
    for (let i = 0; i < lineageEntries.length; i += BATCH_SIZE) {
      chunks.push(lineageEntries.slice(i, i + BATCH_SIZE));
    }

    // Process chunks sequentially
    await chunks.reduce(async (previousPromise, chunk) => {
      await previousPromise;
      const chunkBatch = firestore.batch();
      const chunkSkusToUpdate: string[] = [];

      // First, get all documents in the chunk
      const docSnapshots = await Promise.all(
        chunk.map(([sku]) => firestore.collection('parts-dev').doc(sku).get()),
      );

      // Then process the snapshots
      docSnapshots.forEach((doc, index) => {
        const [sku, { parent, childParts }] = chunk[index];

        if (doc.exists) {
          const docRef = firestore.collection('parts-test').doc(sku);
          chunkBatch.update(docRef, { parent, childParts });
          chunkSkusToUpdate.push(sku);
        } else {
          console.warn(`Document for SKU ${sku} does not exist`);
        }
      });

      try {
        await chunkBatch.commit();
        console.log(`Updated ${chunkSkusToUpdate.length} parts in this chunk`);
        skusToUpdate.push(...chunkSkusToUpdate);
      } catch (error) {
        console.error('Error updating parts lineage chunk:', error);
      }

      return Promise.resolve();
    }, Promise.resolve());

    console.log(`Total parts updated: ${skusToUpdate.length}`);
  };

  const removeUndefinedTerms = (config: any[]): any[] =>
    config
      .map((configEntry) => {
        // If the entry has terms, filter out undefined terms
        if (configEntry.terms && Array.isArray(configEntry.terms)) {
          return {
            ...configEntry,
            terms: configEntry.terms.filter(
              (term: string) =>
                term !== 'undefined'
                && term !== 'undefinedundefined'
                && !term.match(/undefined/i),
            ),
          };
        }
        return configEntry;
      })
      .filter((configEntry) => {
        // If the entry has terms, ensure it's not completely empty after filtering
        if (configEntry.terms) {
          return configEntry.terms.length > 0;
        }
        return true; // Keep entries without terms
      });

  const checkMaterialsConfirmed = async (order: any) => {
    const itemsDoc = await firestore.collection('order-items').doc(order.id).get();
    const items = itemsDoc.data();
    const hasItems = items?.orderItems?.filter((i) => i).length > 0;
    const itemsConfirmed = hasItems && _.every(items?.orderItems?.map((i) => i.materialsConfirmed), (t) => t);
    if (!hasItems && !itemsConfirmed && order.materialsConfirmed) {
      console.log(`Order ${order.id} has no items but is confirmed`);
      firestore.collection('orders').doc(order.id).update({ materialsConfirmed: null, materialsConfirmedBy: null }).then(() => {
        console.log(`Updated ${order.id}`);
      });
    }
  };

  const cloneCollections = async (path: string, envs: string[] = ['prod', 'dev', 'test']) => {
    const collections = envs.map((env) => ({
      source: path,
      dest: `_environments/${env}/${path}`,
    }));

    const BATCH_SIZE = 500;

    await collections.reduce(async (promise, { source, dest }) => {
      await promise;
      console.log(`Starting migration of ${source} to ${dest}`);

      const processChunks = async (documents: any[], _source: string) => {
        const chunks = Array.from(
          { length: Math.ceil(documents.length / BATCH_SIZE) },
          (foo, i) => documents.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE),
        );

        return Promise.all(chunks.map(async (chunk, index) => {
          const batch = firestore.batch();
          chunk.forEach((doc) => {
            const destRef = firestore.collection(dest).doc(doc.id);
            batch.set(destRef, doc.data());
          });

          await batch.commit();
          console.log(`Migrated chunk ${index + 1} of ${chunks.length} for ${_source}`);

          await new Promise((resolve) => setTimeout(resolve, 1000));
        }));
      };

      const sourceDocs = await firestore.collection(source).get();
      await processChunks(sourceDocs.docs, source);

      console.log(`Completed migration of ${source} to ${dest} (${sourceDocs.docs.length} documents)`);
    }, Promise.resolve());
  };

  const syncCollection = async (collection: string, env: string = 'prod') => {
    const oldOrderItemDocs = await firestore.collection(collection).get();
    const newOrderItemDocs = await firestore.collection(`_environments/${env}/${collection}`).get();

    oldOrderItemDocs.docs.forEach(async (doc) => {
      if (!_.includes(newOrderItemDocs.docs.map((d) => d.id), doc.id)) {
        firestore.collection(`_environments/${env}/${collection}`).doc(doc.id).set(doc.data()).then(() => {
          console.log(`Updated ${doc.id} in _environments/${env}/${collection}`);
        });
      }
    });
  };

  const main = async () => {
    await syncCollection('customer-contacts');
    await syncCollection('customer-shipping-addresses');
    await syncCollection('customers');
  };

  const onClick = (e: any) => {
    e.preventDefault();
    if (process.env.NODE_ENV !== 'development') return;
    console.log('Beginning ad hoc routine');

    main().then(() => {
      console.log('Ad hoc routine complete');
    });
  };

  return (
    // @ts-ignore
    <AdHocButton
      disabled={window.location.pathname.match('wildwood')}
      type="default"
      onClick={onClick}
    >
      Execute Ad-hoc function
    </AdHocButton>
  );
};
