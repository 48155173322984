import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { find, groupBy, sortBy } from 'lodash';
import { CloudDownload } from '@styled-icons/material';
import { useRecoilValue } from 'recoil';
import { CSVLink } from 'react-csv';
import theme from 'shared/theme';
import { customersAtom } from 'shared/state/customerState';
import { ICustomerRecord, IOrderItem } from 'shared/types/dbRecords';
import { formatPrice } from 'shared/data';
import { IShopOrder } from '../../types';

const DownloadButton = styled(CSVLink)`
  flexGrow: 2;
    height: ${theme.spacing(4)};
    padding: 0 16px;
    
    border: 1px solid ${theme.palette.success.hue};
    border-radius: ${theme.spacing(1)};
    background-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
    font-size: 14px;
    
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  
    
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
        color: ${theme.palette.neutral.white};
        background-color: ${theme.palette.success[900]};
        border: 1px solid ${theme.palette.success[900]};
    }
  
`;

const DownloadIcon = styled(CloudDownload)`
    width: 28px;
    margin-bottom: -4px;
`;
/**
 * Button component that is responsible for exporting order data as a csv file.
 */
interface IComponent {
  orders: IShopOrder[];
  startDate: Date;
  endDate: Date;
}
const ExportOrdersButton = ({ orders, startDate, endDate }: IComponent) => {
  const sheetHeaders = ['Period Begin', startDate.toLocaleDateString(), 'Period End', endDate.toLocaleDateString()];
  const csvHeaders = ['Customer', 'Name', 'Total', '', '', 'Neck Total', 'Neck Units', 'Avg. Neck', '', 'Body Total', 'Body Units', 'Avg. Body'];
  const customers = useRecoilValue(customersAtom);
  // @ts-ignore
  const [csvData, setCsvData] = useState<any>([]);

  useEffect(() => {
    const actualOrders = orders.filter((o: IShopOrder) => !o.salesOrder.match(/PH/));
    const ordersByCustomer = groupBy(actualOrders, (o: IShopOrder) => o.customer.id);

    if (Object.keys(ordersByCustomer).length > 0) {
      const data = [];
      let total = 0;
      sortBy(Object.entries(ordersByCustomer), (entry) => entry[0]).forEach((entry) => {
        const [customerId, sales] = entry;
        const customer = find(customers, (c: ICustomerRecord) => c.id === customerId);
        const neckUnits = sales.filter((o: IShopOrder) => o.type === 'neck').map((o: IShopOrder) => {
          const partCount = o.orderItems.filter((i: IOrderItem) => i.Sku.match(/^[A-Z]{5}/)).map((i: IOrderItem) => i.quantityOrdered).reduce((a, b) => a + b, 0);
          return partCount;
        }).reduce((a, b) => a + b, 0);
        const neckValue = sales.filter((o: IShopOrder) => o.type === 'neck').map((o: IShopOrder) => {
          const orderValue = o.orderItems.map((i: IOrderItem) => (o.completed ? i.quantityOrdered : i.quantityOpen) * i.unitPrice).reduce((a, b) => a + b, 0);
          return orderValue;
        }).reduce((a, b) => a + b, 0);
        const avgNeck = Math.round(neckValue / (neckUnits || 1));
        const bodyUnits = sales.filter((o: IShopOrder) => o.type === 'body').map((o: IShopOrder) => {
          const partCount = o.orderItems.filter((i: IOrderItem) => i.Sku.match(/^[A-Z]{5}/)).map((i: IOrderItem) => i.quantityOrdered).reduce((a, b) => a + b, 0);
          return partCount;
        }).reduce((a, b) => a + b, 0);
        const bodyValue = sales.filter((o: IShopOrder) => o.type === 'body').map((o: IShopOrder) => {
          const orderValue = o.orderItems.map((i: IOrderItem) => (o.completed ? i.quantityOrdered : i.quantityOpen) * i.unitPrice).reduce((a, b) => a + b, 0);
          return orderValue;
        }).reduce((a, b) => a + b, 0);
        const avgBody = Math.round(bodyValue / (bodyUnits || 1));

        data.push([customer?.DisplayName || customerId, customer?.CompanyName || '', formatPrice(neckValue + bodyValue), '', '', formatPrice(neckValue), neckUnits, formatPrice(avgNeck), '', formatPrice(bodyValue), bodyUnits, formatPrice(avgBody)]);
        total += (neckValue + bodyValue);
      });

      const totalLine = ['Total Sales', '', formatPrice(total)];

      setCsvData([sheetHeaders, [], [], csvHeaders, ...data, [], totalLine]);
    }
  }, [orders]);

  return (
    <DownloadButton
      disabled={csvData.length === 0}
      key={shortid.generate()}
      filename={`Wildwood Orders Export ${new Date().toLocaleString()}.csv`}
      data={csvData}
      onClick={() => {}}
    >
      <DownloadIcon />
    </DownloadButton>
  );
};

export default ExportOrdersButton;
