import React, { useEffect, useState } from 'react';
import {
  every, groupBy, find, flatten, includes, sortBy, zipObject, uniq, flattenDeep,
} from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { salesOrdersAtom, scheduleViewStartDateAtom } from 'shared/state/routingState';
import {
  IBomItem, IInventoryChild, IInventoryPart, IOrderItem, IPurchaseOrder, IPurchaseOrderItem,
} from 'shared/types/dbRecords';
import { ORDER_ITEMS_DB_COLLECTION } from 'shared/state/orderState';
import {
  inventoryItemsAtom,
  inventoryRequirementsAtom,
  jobRequirementSearchQueryAtom,
} from 'shared/state/inventoryState';
import {
  currentRequirementRecordAtom,
  hidePositiveQuantityAtom,
  showPartReqDrawerAtom,
} from 'shared/state/jobRequirementsState';
import { lastOfFutureMonth, parseYearMonthTag } from 'shared/data/calendar';
import { IConsumptionRecord } from 'shared/types/order';
import useFirebase from 'vendor/Firebase';
import { PURCHASE_ORDER_DB_COLLECTION } from 'shared/state/purchaseOrderState';
import { listFilterQuery } from 'shared/util';
import { updateOrderItemBomConsumption } from 'shared/data/order';
import {
  IOrderWithItems, IRunner, IShopOrder,
} from '../Orders/types';
import {
  PART_NUMBER_COLUMN,
  DESCRIPTION_COLUMN,
  CURRENT_QUANTITY_COLUMN,
  mapConsumptionColumns,
  CONSUMPTION_COLUMNS,
  QUANTITY_NEEDED_COLUMN,
  VENDOR_COLUMN,
} from './Components/JobRequirements/JobRequirementColumns';
import { SalesOrderTable } from './styledComponents';
import JobRequirementsFilters from './Components/JobRequirements/JobRequirementsFilters';
import PartRequirementsDrawer from './Components/JobRequirements/PartRequirementsDrawer';

interface IComponent {
  items: IShopOrder[];
  query: string;
}
const SalesOrderRequirements = ({ query, items }: IComponent) => {
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const purchaseOrdersDbString = useRecoilValue(PURCHASE_ORDER_DB_COLLECTION);
  const orderStartDate = useRecoilValue(scheduleViewStartDateAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const hidePositiveQuantities = useRecoilValue(hidePositiveQuantityAtom);
  const [requirements, setRequirements] = useRecoilState(inventoryRequirementsAtom);
  const [jobRequirementSearchQuery, setJobRequirementSearchQuery] = useState<string>(query);
  const setShowPartReqDrawer = useSetRecoilState(showPartReqDrawerAtom);
  const [currentRequirementRecord, setCurrentRequirementRecord] = useRecoilState(currentRequirementRecordAtom);
  const [displayRequiremnts, setDisplayRequirements] = useState([]);
  const [columns, setColumns] = useState<any[]>([]);

  const { firestore } = useFirebase();
  interface IOrderItemData {
    order: IShopOrder;
    orderItems: IOrderItem[];
    source: 'runner'|'order-item';
  }
  interface IDateBom extends IBomItem {
    salesOrder: string;
    customer: string;
    releaseDate: Date;
  }

  const fetchOrderItems = async (order: IShopOrder): Promise<IOrderItemData> => {
    if (order.runners) {
      const workOrderItems = flatten(order.runners.map((r: IRunner) => r.parts));
      return { order, orderItems: workOrderItems, source: 'runner' };
    }
    const doc = await firestore.collection(orderItemsDbString).doc(order.id).get();
    if (!doc.exists) {
      return null;
    }
    const data = doc.data();
    if (order.shipDate > new Date(2024, 2, 31)) console.log(data.orderItems);
    // @ts-ignore
    return { order, orderItems: data?.orderItems, source: 'order-item' } || {};
  };

  const updateDisplayedRecords = (partRequirements: any[] = requirements, searchQuery: string = jobRequirementSearchQuery) => {
    const _query = listFilterQuery(searchQuery);
    const newRequirements = partRequirements.filter((r: any) => {
      if (!hidePositiveQuantities) return true;
      const quantityAvailable = r.currentQuantity - Object.values(r.consumption).map((c) => c.quantityConsumed).reduce((a, b) => a + b) <= 0;
      return quantityAvailable;
    }).filter((r: any) => {
      const matchString = `${r.partNumber}${r.description}${r.vendor}${r.category}`;
      const matches = _query.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
      return every(matches, Boolean);
    });
    setDisplayRequirements(newRequirements);
  };

  const onRowClick = (record: IConsumptionRecord) => (e: any) => {
    setCurrentRequirementRecord(record);
    setShowPartReqDrawer(true);
  };

  const collapseOrderedParts = (groupedParts: any) => Object.entries(groupedParts).map((pair) => {
    const [Sku, ordered] = pair;
    const QtyOnOrder = ordered.map((o) => o.QtyOnOrder).reduce((a, b) => a + b, 0);
    const Description = ordered[0].Description;
    return { Sku, Description, QtyOnOrder };
  });

  const setRowClass = (record: any) => {
    const hash = window.location.hash.replace('#', '');
    return hash === record.partNumber ? 'sales-order-prev-nav' : 'sales-order-row';
  };

  useEffect(() => {
    firestore.collection(purchaseOrdersDbString).where('POStatus', '==', 'Open').get().then((poDocs) => {
      const purchaseOrders = poDocs.docs.map((doc) => doc.data() as IPurchaseOrder);
      const orderedItems = flatten(purchaseOrders.map((p) => {
        const lines = p.Line.map((l: IPurchaseOrderItem) => {
          if (!l.ItemBasedExpenseLineDetail) return null;
          return {
            Description: l.Description,
            Sku: l.ItemBasedExpenseLineDetail.ItemRef.name.split(':')[0],
            QtyOnOrder: l.ItemBasedExpenseLineDetail.Qty,
          };
        });
        return lines.filter((l) => l);
      }));
      const quantitiesOnOrder = collapseOrderedParts(groupBy(orderedItems, (o) => o.Sku));
      // max end date is last day of three months in the future
      const maxEndDate = lastOfFutureMonth(orderStartDate, 3);
      // @ts-ignore
      const openOrders = items.filter((o: IShopOrder) => !o.completed && o.releaseDate <= maxEndDate && o.releaseDate > orderStartDate) as IOrderWithItems[];
      const bomItems = flatten(openOrders.map((order: IOrderWithItems) => {
        const orderItems = updateOrderItemBomConsumption(order, order.orderItems);
        const openOrderItems = orderItems.filter((o: IOrderItem) => o.quantityOpen > 0);
        const orderItemBom = flatten(openOrderItems.map((i: IOrderItem) => i.bom.map((b: IBomItem) => ({
          ...b,
          endPartDescription: i.Description,
          totalQuantity: parseFloat(((b.quantity * i.quantityOrdered) - (b.quantityConsumed || 0)).toFixed(2)),
          totalCost: parseFloat((b.unitCost * i.quantityOrdered).toFixed(2)),
          totalPrice: parseFloat((b.unitPrice * i.quantityOrdered).toFixed(2)),
        }))));
        return orderItemBom.map((i: IBomItem) => ({
          ...i,
          releaseDate: order.releaseDate,
          // @ts-ignore
          consumptionTag: `${order.releaseDate.getFullYear()}.${order.releaseDate.getMonth()}`,
          salesOrder: order,
        }));
      }));
      const bomItemsByPart = groupBy(bomItems.filter((i: IDateBom) => i.Sku.length > 0), (i: IDateBom) => i.Sku);
      // @ts-ignore
      const partReqs: any[] = [];

      Object.entries(bomItemsByPart).forEach((group) => {
        const [partNumber, entries] = group;
        const monthEntries = groupBy(entries, (e: IDateBom) => `${e.releaseDate.getFullYear()}.${e.releaseDate.getMonth()}`);
        // @ts-ignore
        const totalNeeded = entries.map((e: IDateBom) => (e.totalQuantity || e.quantity)).reduce((a, b) => a + b, 0);
        // @ts-ignore
        const monthConsumption = zipObject(
          // }),
          Object.keys(monthEntries),
          // @ts-ignore
          Object.values(monthEntries).map((entryGroup: IDateBom[]) => ({
            salesOrders: uniq(entryGroup.map((e: any) => ({
              salesOrder: e.salesOrder,
              quantityNeeded: e.totalQuantity,
              forPart: e.endPartDescription,
            }))),
            quantityConsumed: entryGroup.map((e: IDateBom) => e.totalQuantity).reduce((a, b) => a + b, 0),
          })),
        );
        const inventoryPart = find(inventoryItems, (i) => i.Sku === partNumber) as IInventoryPart;
        if (!inventoryPart) return;
        let currentQuantity = inventoryPart.QtyOnHand;
        if (inventoryPart.Children) {
          const childParts = inventoryPart.Children.map((c: IInventoryChild) => c.sku);
          const children = inventoryItems.filter((i) => includes(childParts, i.Sku));
          currentQuantity = children.map((c: IInventoryPart) => {
            const factor = c.Parent?.ParentPerChild || 1;
            if (factor > 1) return c.QtyOnHand * factor;
            return c.QtyOnHand / factor;
          }).reduce((a, b) => a + b, 0);
        }
        // console.log({
        //   partNumber,
        //   consumption: monthConsumption,
        //   description: inventoryPart?.Description ?? '',
        //   category: inventoryPart?.ProductCode.productCode ?? '',
        //   vendor: inventoryPart?.PrefVendorRef?.name ?? '',
        //   currentQuantity: Math.round(currentQuantity),
        //   quantityNeeded: totalNeeded,
        // });
        partReqs.push({
          partNumber,
          consumption: monthConsumption,
          description: inventoryPart?.Description ?? '',
          category: inventoryPart?.ProductCode.productCode ?? '',
          vendor: inventoryPart?.PrefVendorRef?.name ?? '',
          currentQuantity: Math.round(currentQuantity),
          quantityNeeded: totalNeeded,
        });
      });

      const monthsConsumed = uniq(flatten(partReqs.map((p) => Object.keys(p.consumption))));
      const monthNames = monthsConsumed.map((m) => parseYearMonthTag(m)).filter((o) => o);
      const consumptionColumns = CONSUMPTION_COLUMNS(
        mapConsumptionColumns(
          sortBy(monthNames, (m) => m.tag).map((m) => m),
        ),
      );
      setColumns([
        PART_NUMBER_COLUMN,
        DESCRIPTION_COLUMN,
        VENDOR_COLUMN,
        QUANTITY_NEEDED_COLUMN,
        CURRENT_QUANTITY_COLUMN,
        consumptionColumns,
      ]);
      setRequirements(partReqs);
      updateDisplayedRecords(partReqs, jobRequirementSearchQuery);

      const timeout = setTimeout(() => {
        const el = document.getElementById(window.location.hash.replace('#', ''));
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 100);
      return () => {
        clearTimeout(timeout);
      };
    });
    return () => {};
  }, [items]);

  useEffect(() => {
    setJobRequirementSearchQuery(query);
    updateDisplayedRecords(requirements, query);
  }, [hidePositiveQuantities, query]);

  return (
    <>
      {!!Object.keys(currentRequirementRecord).length && (
        <PartRequirementsDrawer />
      )}
      {!!requirements.length && (
        <>
          <SalesOrderTable
            key="requirements-table"
            rowKey="partNumber"
            size="small"
            // @ts-ignore
            columns={columns}
            dataSource={displayRequiremnts}
            pagination={false}
            rowClassName={setRowClass}
            onRow={(record: IConsumptionRecord) => ({
              onClick: onRowClick(record),
            })}
          />
        </>
      )}
    </>
  );
};

export default SalesOrderRequirements;
