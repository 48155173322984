import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { chunk, find } from 'lodash';
import { useRecoilValue } from 'recoil';
import QBOItem from 'shared/data/QBO/item';
import { IInventoryPart } from 'shared/types/dbRecords';
import { IItemAdjustment, IQBOItem, IQuantityAdjustmentItem } from 'shared/types/qbo';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import { INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';

interface IComponent {
  showModal: boolean;
  uploadType: 'items' | 'eom';
}
const AdjustQBOInventoryModal = ({
  showModal,
  uploadType,
}: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const inventoryItemsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const [show, setShow] = useState<boolean>(showModal);
  const [modalText, setModalText] = useState<string>('Adjust item quantities in QBO at this time?');
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const onOk = async () => {
    setConfirmLoading(true);
    setModalText('Preparing inventory adjustments...');
    const [qboData, helmDocs] = await Promise.all([QBOItem.fetchAllInventoryParts(),
      firestore.collection(inventoryItemsDbString).where('Type', '==', 'Inventory').get()]);
    const helmData = helmDocs.docs.map((d) => d.data() as IInventoryPart).filter((d: any) => !d.Children || d.Children.length === 0);
    const quantityAdjustmentItems = helmData.map((i: IInventoryPart) => {
      const qboPart = find(qboData, ((p: IQBOItem) => p.Sku === i.Sku));
      const difference = !qboPart ? 0 : Math.round((i.QtyOnHand - qboPart.QtyOnHand) * 1000) / 1000;
      return { ...i, adjustmentQuantity: difference };
    }).filter((i: IQuantityAdjustmentItem) => i.adjustmentQuantity !== 0);

    const memo = `Bulk inventory update from HELM, - ${currentUser.email.split('@')[0]}`;
    /*
        To process all QBO inventory adjustments, we have to break the qboInventoryAdjustments list into sets of max 500,
        as this is the maximum number of inventory adjustments per transaction allowed by the QBO API.
       */
    const dataChunks = chunk(quantityAdjustmentItems, 500) as IQuantityAdjustmentItem[][];
    /*
      If the upload type is EOM, we need to set the adjustment date to the last possible moment of the previous month.
    */
    const adjustmentDate = new Date();
    if (uploadType === 'eom') {
      adjustmentDate.setDate(0);
      adjustmentDate.setHours(23, 59, 59, 999);
    }

    const qboInventoryAdjustments = await Promise.all(dataChunks
      .map((dataChunk: IQuantityAdjustmentItem[]) => QBOInventoryAdjustment.itemAdjustmentFromParts(dataChunk, qboData, adjustmentDate, memo)));

    // console.log(qboInventoryAdjustments);
    setModalText('Posting inventory adjustments to Quickbooks Online...');
    await Promise.all((qboInventoryAdjustments.map((i: IItemAdjustment) => QBOInventoryAdjustment.create(i))));
    setModalText('All done!');
    setTimeout(() => {
      setConfirmLoading(false);
      setShow(false);
    }, 1200);
  };

  const onCancel = () => {
    setShow(false);
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal
      title="Title"
      open={show}
      onOk={onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
    >
      <p>{modalText}</p>
    </Modal>
  );
};

export default AdjustQBOInventoryModal;
