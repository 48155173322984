import { atom, selector, selectorFamily } from 'recoil';
import { IOrderItem, IShipmentItem } from '../types/dbRecords';
import { IRunner } from '../../pages/Orders/types';

// Core atoms for order items
export const orderItemsAtom = atom<IOrderItem[]>({
  key: 'orderItems',
  default: [],
});

export const currentOrderItemAtom = atom<string|null>({
  key: 'currentOrderItem',
  default: null,
});

export const orderItemEditModalAtom = atom<{
  visible: boolean;
  item: IOrderItem | null;
}>({
  key: 'orderItemEditModal',
  default: {
    visible: false,
    item: null,
  },
});

// Selectors for derived state
export const openOrderItemsSelector = selector<IOrderItem[]>({
  key: 'openOrderItems',
  get: ({ get }) => {
    const items = get(orderItemsAtom);
    return items.filter((item) => item.quantityOpen > 0);
  },
});

export const orderItemByIdSelector = selectorFamily<IOrderItem | null, string>({
  key: 'orderItemById',
  get: (id) => ({ get }) => {
    const items = get(orderItemsAtom);
    return items.find((item) => item.id === id) || null;
  },
});

export const orderItemWorkOrderAssignmentsSelector = selectorFamily<number, string>({
  key: 'orderItemWorkOrderAssignments',
  get: (itemId) => ({ get }) => {
    const workOrders = get(workOrdersAtom);
    return workOrders.reduce((total, wo) => {
      const item = wo.parts.find((p) => p.id === itemId);
      return total + (item?.quantityAssigned || 0);
    }, 0);
  },
});

// Work Orders state
export const workOrdersAtom = atom<IRunner[]>({
  key: 'workOrders',
  default: [],
});

export const workOrderItemsSelector = selectorFamily<IOrderItem[], string>({
  key: 'workOrderItems',
  get: (workOrderId) => ({ get }) => {
    const workOrders = get(workOrdersAtom);
    const workOrder = workOrders.find((wo) => wo.id === workOrderId);
    return workOrder?.parts || [];
  },
});

// Shipping state
export const shipmentItemsAtom = atom<IShipmentItem[]>({
  key: 'shipmentItems',
  default: [],
});

export const shipmentItemsByOrderItemSelector = selectorFamily<IShipmentItem[], string>({
  key: 'shipmentItemsByOrderItem',
  get: (orderItemId) => ({ get }) => {
    const items = get(shipmentItemsAtom);
    return items.filter((item) => item.id === orderItemId);
  },
});

// Order item edit form state
export const orderItemFormStateAtom = atom<{
  quantity: number;
  notes: string;
  price: number;
  description: string;
  internalNotes: string;
}>({
  key: 'orderItemFormState',
  default: {
    quantity: 0,
    notes: '',
    price: 0,
    description: '',
  },
});
