import { includes } from 'lodash';
import qs from 'qs';
import { IInventoryPart } from '../types/dbRecords';
import { stepForPart } from '../router/utils';

export const MIN_WEIGHT = 3.75;
export const MAX_WEIGHT = 5;

export const MIN_VOL = 1.1;
export const MAX_VOL = 2.1;

export const DEFAULT_SPREAD_WIDTH = 14;
export const DEFAULT_SPREAD_LENGTH = 19;
export const DEFAULT_SPREAD_THICK = 1.94;

export const allWeights = (minWeight: number = MIN_WEIGHT, maxWeight: number = MAX_WEIGHT) => Array.from(
  { length: ((maxWeight - minWeight) * 16) + 1 },
  (_, i) => i,
)
  .map((i) => i / 16)
  .map((i) => minWeight + i);

export const allVolumes = Array.from(
  { length: ((MAX_VOL - MIN_VOL) * 100) + 1 },
  (_, i) => i,
)
  .map((i) => i / 100)
  .map((i) => Math.round((i + MIN_VOL) * 100) / 100);

export const weightsForVolumes = allVolumes.map((v) => ({
  volume: v,
  densities: allWeights().map((w) => ({ weight: w, density: Math.round((w / v) * 100) / 100 })),
}));

export const poundWeight = (weight: number) => Math.floor(weight);

export const ounceWeight = (weight: number) => (weight - poundWeight(weight)) * 16;

export const isMobile = () => /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const partNumberWithZeroes = (partNumber: string): string => partNumber.padStart(5, '0');

// export const TRANSPORTER = nodemailer.createTransport({
//   host: 'smtp.office365.com',
//   port: 587,
//   secure: false,
//   auth: {
//     user: process.env.SMTP_EMAIL,
//     pass: process.env.SMTP_PASS,
//   },
//   tls: {
//     ciphers: 'SSLv3',
//   },
// });

export const bufferToData = (buffer, title, toEmail = null, contentType = null) => ({
  from: '"Wildwood Manufacturing" <noreply@wildwoodmfg.com>',
  sender: '"Wildwood Manufacturing" <noreply@wildwoodmfg.com>',
  to: toEmail,
  subject: title,
  text: '',
  html: '',
  attachments: [
    {
      filename: title,
      content: buffer,
      contentType: contentType || '*/*',
    },
  ],
});

// export const sortList = (list: any[], sortKeys: any[]): [] => sortKeys.reduce((acc, key) => _.sortBy(acc, key), list);

export const sortList = (list: any[], sortKeys: string[]) => {
  if (sortKeys.length === 0) return list;
  return sortList(_.sortBy(list, (r: any) => r[sortKeys[0]]), sortKeys.slice(1));
};

export const inventoryPartToBom = (part: IInventoryPart) => {
  const partType = includes(['GN', 'BN'], part.Description.slice(0, 2)) ? 'neck' : 'body';
  // @ts-ignore
  const bomItem = {
    description: part.Description,
    isPurchased: true,
    lastModDate: part.MetaData.LastUpdatedTime,
    partNumber: part.Sku,
    quantity: 1,
    quantityConsumed: 0,
    stepId: stepForPart(part.Sku, partType),
    totalCost: part.PurchaseCost,
    totalPrice: part.UnitPrice,
    totalQuantity: 1,
    uniqueID: part.Id,
    unit: part.PurchasingUnit,
    unitCost: part.PurchaseCost,
    unitPrice: part.UnitPrice,
    vendor: part.Vendor.vendorCode,
  };

  return bomItem;
};

export const stageRedirect = (href: string|null = null) => {
  const redirectStorage = sessionStorage.getItem('redirectUrl') ?? '';
  const redirectUrl = href || `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const redirectStack = [encodeURIComponent(redirectUrl), ...redirectStorage.split('|')];
  sessionStorage.setItem('redirectUrl', redirectStack.join('|'));
};

export const cleanCurrentUrl = () => `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
export const clearRedirect = () => {
  sessionStorage.removeItem('redirectUrl');
};
export const popRedirect = () => {
  const redirectStorage = sessionStorage.getItem('redirectUrl') ?? '';
  const redirectStack = redirectStorage.split('|');
  if (!redirectStack.length) {
    sessionStorage.removeItem('redirectUrl');
    return null;
  }
  sessionStorage.setItem('redirectUrl', redirectStack.slice(1).join('|'));
  return decodeURIComponent(redirectStack[0]);
};
export const redirect = () => {
  const redirectStorage = sessionStorage.getItem('redirectUrl') ?? '';
  const redirectStack = redirectStorage.split('|').filter((s: string) => s.length > 0);

  if (redirectStack.length) {
    const redirectUrl = redirectStack[0];

    sessionStorage.setItem('redirectUrl', redirectStack.slice(1).join('|'));
    if (decodeURIComponent(redirectUrl) === window.location.href) {
      redirect();
    } else {
      window.location.href = decodeURIComponent(redirectUrl);
    }
  } else if (window.location.href.match(/orderId/)) {
    const { orderId } = qs.parse(window.location.href);
    const orderViewType = localStorage.getItem('salesOrderViewType') || 'week';
    window.location.href = `/schedule/${orderViewType}#${orderId}`;
  } else if (window.location.href.match(/partId/)) {
    const { partId } = qs.parse(window.location.href);
    window.location.href = `/pricing?partId=${partId}`;
  } else {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  }
};

export const urlQueryString = (url: string) => new URL(url).search.substring(1);

export const nullOp = () => {};

export const listFilterQuery = (query: string) => query
  .replace(/\bor\b/g, '|')
  // .replace(/\bnot\b/g, '^')
  .split(',')
  .map((s) => {
    if (s.match(/not\b/)) {
      return `${s.replace(/\bnot\b/, '^(')})`.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      // .replace(/\s/g, '')
    }
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // .replace(/\s/g, '')
  });

export const partTypeFromDesc = (description: string) => {
  if (includes(['GB', 'BB'], description.substring(0, 2))) return 'body';
  if (includes(['GN', 'BN'], description.substring(0, 2))) return 'neck';
  return 'other';
};

export const isDevSite = () => window.location.href.match(/localhost|127\.0\.0\.1/);
export const isStagingSite = () => window.location.href.match(/staging\.helm/i);
export const isTestSite = () => window.location.href.match(/test\.helm/i);
export const isProduction = () => !isStagingSite() && !isTestSite() && !isDevSite();

export const useTestData = localStorage.getItem('testData') === 'true' || isStagingSite() || isTestSite();
// will only be used to tint color on the staging and production sites when testData is true
export const useTestDataRemote = localStorage.getItem('testDataRemote') === 'true' || isStagingSite();

export const resolveFirestorePath = (path: string, env: 'dev'|'test'|'prod'|'backup'|null = null) => {
  if (env) return `_environments/${env}/${path}`;

  if (isDevSite()) return `_environments/dev/${path}`;
  if (useTestData) return `_environments/test/${path}`;
  return `_environments/prod/${path}`;

  // if (env) return `${path}-${env}`;
  // if (isDevSite()) return `${path}-dev`;
  // if (useTestData) return `${path}-test`;
  // return path;
};

export const resolveRealtimePath = (path: string, env: 'prod'|'dev'|'test'|'backup'|null = null) => {
  if (env) return `${path}${env[0].toUpperCase()}${env.slice(1)}`;

  if (isDevSite()) return `${path}Dev`;
  if (useTestData) return `${path}Test`;
  return path;
};
