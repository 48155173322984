import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom, shopUserEmailsAtom,
  superAdminUserEmailAtom, supervisorEmailsAtom,
} from 'shared/state/routingState';
import Loader from 'shared/components/Utility/Loader';
import {
  AdminToolsButtonWrapper, AdminToolsFilterWrapper, ButtonIsland, PageAdminToolsWrapper,
} from 'shared/styledComponents/containers';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import * as _ from 'lodash';
import { Button } from 'antd';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import PartList from './Components/PartList';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import ActiveSelector from '../Customer/Components/PriceListInputs/ActiveSelector';
import InventoryPartFilter from './Components/InventoryPartFilter';
import NewInventoryPartButton from './Components/Buttons/NewInventoryPartButtonn';
import ExportInventoryButton from './Components/Buttons/ExportInventoryButton';
import ImportInventoryButton from './Components/Buttons/ImportInventoryButton';
import { activePartDisplayAtom } from '../../shared/state/partViewState';
import { PageTitle } from '../../shared/styledComponents/typographicElements';
import ItemListFilter from '../../shared/components/Input/ItemListFilter';
import { listFilterQuery } from '../../shared/util';
import { IInventoryPart } from '../../shared/types/dbRecords';
import { inventoryItemsAtom, showQuickbooksSyncModalAtom } from '../../shared/state/inventoryState';
import { formatPrice } from '../../shared/data';
import AdjustQBOInventoryModal from './Components/Panels/AdjustQBOInventoryModal';
import { userSettingsAtom } from '../../shared/state/siteState';

const InventoryAdminWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: opx;
  z-index: 1000;
  padding: 2px 0 16px 0;
  gap: 32px;
  @media ${theme.device.laptopL} {
    align-items: center;
  }
`;

const ToolsWrapper = styled(PageAdminToolsWrapper)`
    gap: 24px;
    @media ${theme.device.laptopL} {
      gap: 12px;
    }
`;

const ButtonWrapper = styled(FlexRow)`
    align-items: center;
    margin: 2px 0 0 72px;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
      margin-left: 0;
    }
    
    & h2 {
      margin: 0;
    }
    & h3 {
        margin: 0;
    }
`;

const SyncToQboButton = styled(Button)`
    border-radius: 8px;
`;

/**
 *   Display component that lists and filters all inventory parts,
 *   e.g., not finished goods
 */
export default () => {
  // @ts-ignore
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const shopUsersEmails = useRecoilValue(shopUserEmailsAtom);
  const inventory = useRecoilValue(inventoryItemsAtom);
  const [showQboSyncModal, setShowQboSyncModal] = useRecoilState(showQuickbooksSyncModalAtom);
  const [userSettings, setUserSettings] = useRecoilState(userSettingsAtom);

  const sessionKey = 'inventory.query.string';
  const sessionPageKey = 'inventory.currentPage';
  const sessionActiveKey = 'inventory.activeDisplay';
  const [renderItems, setRenderItems] = useState<IInventoryPart[]>(inventory);
  const [uploadType, setUploadType] = useState<'items' | 'eom'>('items');
  const [query, setQuery] = useState<string>(sessionStorage.getItem(sessionKey) || '');
  const [activeDisplayType, setActiveDisplayType] = useState<'active'|'inactive'|'all'>(sessionStorage.getItem(sessionActiveKey) as 'active'|'inactive'|'all' || 'active');
  const [currentPage, setCurrentPage] = useState<number>(parseInt((sessionStorage.getItem(sessionPageKey) || '1'), 10));

  const updatePageData = (searchQuery: string = query, activeType: 'active'|'inactive'|'all' = activeDisplayType): void => {
    const _query = listFilterQuery(searchQuery);
    // @ts-ignore
    const items = _.sortBy(inventory, (i: IInventoryPart) => i.partNumber)
      .map((i: IInventoryPart) => ({
        ...i,
        showInventoryMargin: userSettings.boolean?.showInventoryMargin,
      }))
      .filter((i: IInventoryPart) => {
        if (activeType === 'inactive') return !i.Active;
        if (activeType === 'active') return i.Active;
        return i;
      })
      .filter((i: IInventoryPart) => {
        const matchString = `${i.Description}${i.Sku}${(i.UnitPrice || 0).toString()}${i.ProductCode?.productCode || ''}${i.PrefVendorRef?.name || ''}`;
        const matches = _query.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return _.every(matches, Boolean);
      });
    setRenderItems(items);
  };

  const onChangeFilter = (filterQuery: string) => {
    setQuery(filterQuery);
    sessionStorage.setItem(sessionKey, filterQuery);
    setCurrentPage(1);
    sessionStorage.setItem(sessionPageKey, '1');
    updatePageData(filterQuery, activeDisplayType);
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
    sessionStorage.setItem(sessionPageKey, newPage.toString());
  };

  const onChangeActiveType = (type: 'active'|'inactive'|'all') => {
    setActiveDisplayType(type);
    sessionStorage.setItem(sessionActiveKey, type);
    updatePageData(query, type);
  };

  const onClickQboSync = (e: any) => {
    e.preventDefault();
    setShowQboSyncModal(true);
  };

  const uploadTypeCallback = (type: 'items' | 'eom') => {
    setUploadType(type);
  };

  useEffect(() => {
    updatePageData(query, activeDisplayType);
  }, []);

  return (
    <>
      <AdjustQBOInventoryModal showModal={showQboSyncModal} uploadType={uploadType} />
      <PageHeaderWrapper>
        <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails, ...shopUsersEmails.emails, ...supervisorEmails.emails]}>
          <InventoryAdminWrapper id="customer-admin-inputs">
            <ToolsWrapper>
              <ButtonWrapper>
                <PageTitle>Inventory</PageTitle>
                <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
                  <ButtonIsland noMinWidth padding="8px 16px">
                    <NewInventoryPartButton />
                    <ExportInventoryButton />
                    <ImportInventoryButton />
                    <ScopedComponent whitelist={['keithh@wildwoodmfg.com', 'cathyh@wildwoodmfg.com']}>
                      <SyncToQboButton type="default" onClick={onClickQboSync}>Sync to QBO</SyncToQboButton>
                    </ScopedComponent>
                  </ButtonIsland>
                </ScopedComponent>
                <h2>{formatPrice(inventory.filter((i: IInventoryPart) => i.Type === 'Inventory' && !i.Children).map((i: IInventoryPart) => i.QtyOnHand * i.PurchaseCost).reduce((a, b) => a + b, 0))}</h2>
                <h3>total value</h3>
              </ButtonWrapper>
              <AdminToolsFilterWrapper justify="flex-start">
                <ItemListFilter
                  id="inventory-part-list-filter"
                  callback={onChangeFilter}
                  currentValue={query}
                  prompt="Filter inventory using comma-separated terms, e.g., JESENT, 55090"
                />
                {/* <InventoryPartFilter width="50%" /> */}
                <ActiveSelector activeType={activeDisplayType} callback={onChangeActiveType} />
                <h2>{formatPrice(renderItems.filter((i: IInventoryPart) => i.Type === 'Inventory' && !i.Children).map((i: IInventoryPart) => i.QtyOnHand * i.PurchaseCost).reduce((a, b) => a + b, 0))}</h2>
                <h3>filtered value</h3>
              </AdminToolsFilterWrapper>
            </ToolsWrapper>
            {/* <InventoryHeader /> */}
          </InventoryAdminWrapper>
        </ScopedComponent>
        <PricingCol>
          {loading
            ? (
              <>
                <FlexColumn style={{ width: '100%', height: '50vw' }}>
                  <Loader />
                </FlexColumn>
              </>
            ) : (
              <>
                <PartList renderItems={renderItems} currentPage={currentPage} onPageChange={onPageChange} />
              </>
            )}
        </PricingCol>
      </PageHeaderWrapper>
    </>
  );
};
