import React, { useState } from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';
import { Modal, Typography } from 'antd';
import shortid from 'shortid';
import { useRecoilValue } from 'recoil';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { currentCustomerAtom } from 'shared/state/customerState';
import { isHoliday, isWorkDay, workingDaysBefore } from 'shared/data/calendar';
import type { ICustomerRecord } from 'shared/types/dbRecords';
import { FlexColumn } from 'shared/containers/FlexContainer';
import DetailActiveSelectorWithCallback from 'shared/components/Input/DetailActiveSelectorWithCallback';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWithCallback';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';
import { resolveRealtimePath } from 'shared/util';
import OrderJobTypePicker from './SalesOrderDetail/OrderJobTypePicker';
import CustomerSearchSelect from './CustomerSearchSelect';

const { Title } = Typography;

const ModalContent = styled(FlexColumn)`
  width: 100%;
  gap: 24px;
  padding: 24px;
`;

const FormSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  gap: 16px;
  width: ${(props) => (props.width ? props.width : '100%')};
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    padding: 0;
  }

  .ant-modal-header {
    padding: 24px 24px 0;
    border-bottom: none;
    margin-bottom: 0;
  }

  .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
  }

  .ant-modal-footer {
    padding: 24px;
    border-top: 1px solid #f0f0f0;
    margin-top: 0;
  }

  .ant-input,
  .ant-select-selector,
  .ant-picker {
    border-radius: 6px;
    border-color: #d9d9d9;
    transition: all 0.2s;

    &:hover {
      border-color: #4096ff;
    }

    &:focus,
    &:focus-within {
      border-color: #4096ff;
      box-shadow: 0 0 0 2px rgba(64, 150, 255, 0.1);
    }
  }
`;

interface ISeriesCreationModal {
  visible: boolean
  onClose: () => void
  database: firebase.database.Database
  firestore: firebase.firestore.Firestore
}

const PERIODICITY_OPTIONS = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-weekly', value: 'biweekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
];

const SeriesCreationModal = ({
  visible, onClose, database, firestore,
}: ISeriesCreationModal) => {
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);

  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomerRecord | null>(currentCustomer);
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const [jobType, setJobType] = useState<'body' | 'neck'>('body');
  const [description, setDescription] = useState('');
  const [orderValue, setOrderValue] = useState(0);
  const [unitCount, setUnitCount] = useState(0);
  const [orderDate, setOrderDate] = useState<Date>(new Date());
  const [initialShipDate, setInitialShipDate] = useState<Date>(new Date());
  const [periodicity, setPeriodicity] = useState<string>('');
  const [recurrenceCount, setRecurrenceCount] = useState<number>(12);
  const [purchaseOrder, setPurchaseOrder] = useState<string>('');

  const getNextWorkDay = (date: Date): Date => {
    const nextDay = new Date(date);
    while (!isWorkDay(nextDay) || isHoliday(nextDay)) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay;
  };

  const calculateNextDate = (date: Date, period: string, index: number): Date => {
    const nextDate = new Date(date);
    switch (period) {
      case 'weekly':
        nextDate.setDate(nextDate.getDate() + (7 * index));
        break;
      case 'biweekly':
        nextDate.setDate(nextDate.getDate() + (14 * index));
        break;
      case 'monthly':
        nextDate.setMonth(nextDate.getMonth() + (1 * index));
        break;
      case 'quarterly':
        nextDate.setMonth(nextDate.getMonth() + (3 * index));
        break;
      default:
        break;
    }
    return getNextWorkDay(nextDate);
  };

  const createSeriesOrders = async () => {
    if (!selectedCustomer) return;

    const orders = [];

    const salesOrderDoc = isPlaceholder ? { val: () => ('PH') } : await database.ref(`${resolveRealtimePath('/recordNumbers')}/order`).once('value');
    const salesOrder = salesOrderDoc.val();

    for (let i = 0; i < recurrenceCount; i += 1) {
      const nextShipDate = calculateNextDate(initialShipDate, periodicity, i);
      const _salesOrder = isPlaceholder ? salesOrder : parseInt(salesOrder, 10) + i;
      const releaseDate = workingDaysBefore(nextShipDate, jobType === 'body' ? 5 : 11, jobType, false, false, false, false);
      const order = {
        completed: false,
        completedDate: null,
        customer: selectedCustomer,
        dateCreated: firebase.firestore.Timestamp.fromDate(new Date()),
        description,
        hold: false,
        id: shortid.generate(),
        ihs: false,
        isPlaceholder,
        materialsConfirmed: null,
        materialsConfirmedBy: null,
        orderDate: isPlaceholder ? calculateNextDate(orderDate, periodicity, i) : orderDate,
        orderValue,
        partCount: unitCount,
        purchaseOrder,
        releaseConfirmed: null,
        releaseConfirmedBy: null,
        releaseDate: firebase.firestore.Timestamp.fromDate(releaseDate),
        runners: [],
        salesOrder: _salesOrder.toString(),
        shipDate: nextShipDate,
        shipDateHistory: [nextShipDate],
        shipped: false,
        toppedOrBound: false,
        type: jobType,
        weightReduction: {
          carveTop: 0,
          cavity: 0,
          honeycomb: 0,
          none: 0,
          slot: 0,
        },
      };
      orders.push(order);
    }

    // Add orders to Firestore
    const batch = firestore.batch();
    orders.forEach((order) => {
      const orderRef = firestore.collection(ordersDbCollectionString).doc(order.id);
      batch.set(orderRef, order);
    });

    batch.commit().then(async () => {
      if (!isPlaceholder) {
        await database.ref(`${resolveRealtimePath('/recordNumbers')}/order`).set(parseInt(salesOrder, 10) + recurrenceCount);
      }
      onClose(true);
    });
  };

  const handleCustomerSelect = (customer: ICustomerRecord | null) => {
    setSelectedCustomer(customer);
  };

  const onDescriptionChange = (value: string, blur: boolean) => {
    setDescription(value);
    if (blur) {
      setDescription(value.replace(/-/g, '—'));
    }
  };

  return (
    <StyledModal
      title={<Title level={4}>Create Order Series</Title>}
      open={visible}
      onCancel={onClose}
      onOk={createSeriesOrders}
      width={600}
      maskClosable={false}
      destroyOnClose
    >
      <ModalContent>
        <FormSection>
          <FormRow alignItems="flex-end" justifyContent="flex-start">
            <CustomerSearchSelect selectedCustomerId={selectedCustomer?.id} onCustomerSelect={handleCustomerSelect} />
            <DetailActiveSelectorWithCallback
              id="placeholder-selector"
              initialState={isPlaceholder}
              callback={setIsPlaceholder}
              disabled={false}
              componentLabel="Placeholder?"
              checkedLabel="Yes"
              uncheckedLabel="No"

            />

          </FormRow>
        </FormSection>
        <FormSection>
          <FormRow>
            <DetailInputWithCallback
              id="purchase-order"
              label="PO Number"
              placeholder="e.g., em 1/1/24"
              value={purchaseOrder}
              callback={setPurchaseOrder}
              isLabel={false}
            />
            <DetailDateWithCallback
              id="order-date"
              label="Order Date"
              value={orderDate}
              callback={setOrderDate}
              disabled={false}
              disableDates={false}
            />
            <DetailDateWithCallback
              id="initial-ship-date"
              label="Initial Ship Date"
              value={initialShipDate}
              callback={setInitialShipDate}
              disabled={false}
              disableDates
            />
          </FormRow>
          <DetailInputWithCallback
            id="description"
            label="Description"
            placeholder="Enter order description"
            value={description}
            callback={onDescriptionChange}
            width="100%"
            extend
            isLabel={false}
          />
        </FormSection>

        <FormSection>
          <FormRow>
            <OrderJobTypePicker selectedType={jobType} onTypeSelect={setJobType} />
            <DetailNumberInputWithCallback
              id="order-value"
              label="Order Value"
              placeholder="Enter order value"
              value={orderValue}
              callback={setOrderValue}
              isCurrency
            />
            <DetailNumberInputWithCallback
              id="unit-count"
              label="Number of Units"
              placeholder="Enter unit count"
              value={unitCount}
              callback={setUnitCount}
            />
          </FormRow>
        </FormSection>

        <FormSection>
          <FormRow>
            <DetailNumberInputWithCallback
              id="recurrence-count"
              label="Orders in Series"
              placeholder="Enter number of orders to create"
              value={recurrenceCount}
              callback={setRecurrenceCount}
              min={1}
              max={52}
            />
            <DetailDropdownWithCallback
              id="periodicity"
              label="Periodicity"
              listPrompt="Select frequency"
              optionsList={PERIODICITY_OPTIONS}
              changeCallback={setPeriodicity}
              width="100%"
            />
          </FormRow>
        </FormSection>
      </ModalContent>
    </StyledModal>
  );
};

export default SeriesCreationModal;
