import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Tooltip, TooltipProps } from 'antd';
import shortid from 'shortid';
import { bomEditedAtom } from 'shared/state/partViewState';
import { superAdminUserEmailAtom, adminUserEmailsAtom } from 'shared/state/routingState';
import { orderItemEditModalAtom } from 'shared/state/orderItemState';
import { formatPrice } from 'shared/data';
import { cleanMeta } from 'shared/text';
import { IOrderItem, IBomItem } from 'shared/types/dbRecords';
import UniversalDensityCalculatorByPart from 'pages/Util/DensityCalculator/UniversalDensityCalculatorByPart';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { stageRedirect } from 'shared/util';
import * as S from './styles';

interface IComponent {
  id: string;
  orderItem: IOrderItem;
  showItem?: boolean;
}

const TooltipComponent = Tooltip as React.FC<TooltipProps & { children: React.ReactNode }>;

export const OrderItem: React.FC<IComponent> = ({ id, orderItem, showItem = true }) => {
  const type = orderItem.Description.match(/[G|B]N/) ? 'neck' : 'body';
  const [showDensityCalculator, setShowDensityCalculator] = useState(false);
  const setModalState = useSetRecoilState(orderItemEditModalAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const bomCost = orderItem.bom ? orderItem.bom.map((i: IBomItem) => i.unitCost * i.quantity)
    .reduce((a, b) => a + b, 0) : 0;

  const openCalc = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShowDensityCalculator(true);
  };

  const closeCalc = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShowDensityCalculator(false);
  };

  const redirectToPricing = (e: React.MouseEvent<HTMLButtonElement>) => {
    stageRedirect(window.location.href);
    window.location.href = `/pricing/${type}?partId=${orderItem.Sku}&redirect=true`;
  };

  const onEditOrderPart = (e: React.MouseEvent<HTMLButtonElement>) => {
    setBomEdited(false);
    setModalState({
      visible: true,
      item: orderItem,
    });
  };

  return (
    <>
      <S.CalcDrawer
        key={`${id}-density-calc-drawer`}
        placement="right"
        width="calc(100% - 92px)"
        closable
        open={showDensityCalculator}
        title="Mini Density Calculator"
        onClose={closeCalc}
      >
        <UniversalDensityCalculatorByPart part={orderItem.Sku} />
      </S.CalcDrawer>
      <S.ComponentWrapper
        key={`${id}-order-item-component`}
        $showOrderItem={showItem || orderItem.open}
      >
        <S.DragHandleItem key={`${id}-order-item-drag-handle`} href="#"><S.DragHandleIcon /></S.DragHandleItem>
        <S.PartDetailsWrapper key={`${id}-order-item-detail-wrapper`}>
          <S.PartNumberWrapper key={`${id}-order-item-number-wrapper`}>
            <S.PartNumber key={`${id}-order-item-part-number`}>{orderItem.Sku}</S.PartNumber>
          </S.PartNumberWrapper>
          {!!orderItem.pdRequirement && (
            <TooltipComponent title={orderItem.notes} placement="top" overlayClassName="tooltip">
              <S.RevisionStatus key={`${id}-order-item-revision-status`}>NEEDS DEV</S.RevisionStatus>
            </TooltipComponent>
          )}
          {!!orderItem.quantityOpen && (
          <S.MakeStatus key={`${id}-order-item-open-status`}>{`${orderItem.quantityOpen} OPEN`}</S.MakeStatus>
          )}
          {orderItem.quantityShipped > 0 && (
          <S.ShippedStatus key={`${id}-order-item-shipped-status`}>{`${orderItem.quantityShipped} SHIPPED`}</S.ShippedStatus>
          )}
          {orderItem.quantityCanceled > 0 && (
          <S.CanceledStatus key={`${id}-order-item-canceled-status`}>{`${orderItem.quantityCanceled} CANCELED / LOST`}</S.CanceledStatus>
          )}
        </S.PartDetailsWrapper>
        <S.PartDescriptionWrapper key={`${id}-order-item-description-wrapper`}>
          <S.PartDescription key={`${id}-order-item-description`}>{cleanMeta(orderItem.Description)}</S.PartDescription>
          {!!orderItem.notes && (
            <S.PartNotesWrapper key={`${id}-order-item-notes`}>
              { orderItem.notes.split('\n').map((note: string) => <S.PartJobNotes key={note}>{note}</S.PartJobNotes>)}
            </S.PartNotesWrapper>
          )}
          {!!orderItem.internalNotes && (
            <S.PartInternalNotesWrapper key={`${id}-order-item-internal-notes`}>
              { orderItem.internalNotes.split('\n').map((note: string) => <S.PartJobNotes key={note}>{note}</S.PartJobNotes>)}
            </S.PartInternalNotesWrapper>
          )}
        </S.PartDescriptionWrapper>
        <S.PartPriceWrapper>
          <S.PartPriceDataWrapper>
            <S.PartPrice $bold key={`${id}-order-item-unit-price`}>{formatPrice(orderItem.unitPrice)}</S.PartPrice>
            {!!orderItem.Sku.match(/^[A-Z]{5}/) && (
            <S.PartPrice key={`${id}-order-item-bom-cost`}>{formatPrice(bomCost)}</S.PartPrice>
            )}
          </S.PartPriceDataWrapper>
          <S.PartPriceDataWrapper>
            <S.PartPriceLabel $bold>Sell</S.PartPriceLabel>
            {!!orderItem.Sku.match(/^[A-Z]{5}/) && (
            <S.PartPriceLabel>BOM</S.PartPriceLabel>
            )}
          </S.PartPriceDataWrapper>
        </S.PartPriceWrapper>
        <S.OrderItemActionRow>
          {(type === 'body' && orderItem.Sku.match(/^[A-Z]/)) && (
          <TooltipComponent title="Check part density" placement="top" overlayClassName="tooltip">
            <S.OrderItemActionButton
              key={`${id}-order-item-open-density-calculator`}
              shape="circle"
              type="ghost"
              icon={<S.CalculateIcon />}
              onClick={openCalc}
            />
          </TooltipComponent>
          )}
          {(type === 'body' && orderItem.Sku.match(/^99/)) && (
            <S.OrderItemActionButton
              disabled
              style={{ opacity: 0, width: 0 }}
            />
          )}
          <TooltipComponent title="View/edit part" placement="top" overlayClassName="tooltip">
            <S.OrderItemActionButton
              key={`${id}-order-item-pricing-redirect`}
              shape="circle"
              type="ghost"
              icon={<S.MoneyIcon />}
              onClick={redirectToPricing}
            />
          </TooltipComponent>
          <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
            <TooltipComponent title="View/edit order item" placement="top" overlayClassName="tooltip">
              <S.OrderItemEditButton
                key={`${id}-order-item-edit-part`}
                shape="circle"
                type="primary"
                $confirmed={String(!!orderItem.materialsConfirmed || false)}
                icon={<S.EditIcon />}
                onClick={onEditOrderPart}
              />
            </TooltipComponent>
          </ScopedComponent>
        </S.OrderItemActionRow>
      </S.ComponentWrapper>
    </>
  );
};

export default OrderItem;
