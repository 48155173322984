import React, {
  useEffect, useState, useRef, useMemo, useCallback,
} from 'react';
import {
  Modal,
} from 'antd';
import * as _ from 'lodash';
import { FlexRow } from 'shared/containers/FlexContainer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentSpreadLengthAtom,
  currentSpreadThickAtom, currentSpreadWidthAtom,
  currentWeightChartRowAtom,
  mapleTopAdjustedWeightAtom,
} from 'shared/state/utilState';
import qs from 'qs';
import useFirebase from 'vendor/Firebase';
import { configToDescription } from 'shared/partParser/util';
import { customerPartAtom } from 'shared/state/pricingState';
import {
  resolveWeight, resolveArchetype, resolveModel, resolveWood, resolveWeightReductionType,
} from 'shared/partParser/partResolver';
import { PART_VIEWER_COLLECTION, partConfigTermsAtom } from 'shared/state/partViewState';
import {
  MIN_WEIGHT,
  MAX_WEIGHT,
  allWeights,
  ounceWeight,
  poundWeight,
  partNumberWithZeroes,

} from 'shared/util';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { ViewTypeRadioButton } from 'shared/styledComponents/inputs';
import { customersAtom } from 'shared/state/customerState';
import { ICustomerPart } from 'shared/types/parts';
import { devLog } from 'shared/util/logging';
import { DensityChartRow, DensityChartHeader } from '../Components/DensityChartRow';
import {
  CalcTypeRadioGroup,
  CalculateIcon,
  CalculatorColumn,
  CalculatorInputWrapper,
  CalculatorSectionWrapper,
  LumberInput,
  MinMaxWeightColumn,
  MinMaxWeightInputWrapper,
  PageWrapper, PartBoardFootage, PartDataColumn, PartDescription, PartHeaderRow,
  PartInput,
  PartInputWrapper, ResultsColumn, ResultsColumnWrapper,
  ToggleColumn,
  VolumeCalculateButton,
  VolumeColumnWrapper,
  VolumeInputWrapper,
  WeightInput,
} from './styledComponents';

export interface IDensityRecord {
  isTargetWeight: boolean;
  density: string;
  decimalWeight: number;
  ounceWeight: string;
  spreadWeight: string;
}

interface IComponent {
  part?: string;
}
export default ({ part = undefined }: IComponent) => {
  const { firestore } = useFirebase();
  const partState = useRecoilValue(customerPartAtom);
  const partId = part || qs.parse(window.location.search.replace('?', '')).partId || partState.Sku;
  const inputRef = useRef(null);
  const volInputRef = useRef(null);
  const [calcType, setCalcType] = useState<'Part'|'Volume'>('Part');
  const [currentPart, setCurrentPart] = useState<string>(partId || '');
  const [currentPartData, setCurrentPartData] = useState<any>(null);
  const [currentVolume, setCurrentVolume] = useState<string>('');
  const [customWeight, setCustomWeight] = useState<boolean>(!partId);
  const [maxWeightOunces, setMaxWeightOunces] = useState<number>(ounceWeight(MAX_WEIGHT));
  const [maxWeightPounds, setMaxWeightPounds] = useState<number>(poundWeight(MAX_WEIGHT));
  const [minWeightOunces, setMinWeightOunces] = useState<number>(ounceWeight(MIN_WEIGHT));
  const [minWeightPounds, setMinWeightPounds] = useState<number>(poundWeight(MIN_WEIGHT));
  const [spreadLength, setSpreadLength] = useRecoilState(currentSpreadLengthAtom);
  const [spreadThick, setSpreadThick] = useRecoilState(currentSpreadThickAtom);
  const [spreadWidth, setSpreadWidth] = useRecoilState(currentSpreadWidthAtom);
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const [weightDensities, setWeightDensities] = useState<IDensityRecord[]>([]);
  const customers = useRecoilValue(customersAtom);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const setCurrentWeightChartRow = useSetRecoilState(currentWeightChartRowAtom);
  const setMapleTopAdjustedWeight = useSetRecoilState(mapleTopAdjustedWeightAtom);

  const AVG_MAPLE_DENSITY = 3.5;
  const TOP_SOLID_PCT = 0.11;
  const WR_VOLUME = {
    SH: 0.596,
    SLOT: 0.313,
    HC: 0.35,
    TL: 0.596,
  };

  // Move debounced function to component level
  const debouncedSetWeightDensities = useCallback(
    _.debounce((_weights) => setWeightDensities(_weights), 150),
    [],
  );

  const onPartNumberChange = (e: any) => {
    setCustomWeight(false);
    setCurrentPart(e.target.value);
  };
  const onVolumeChange = (e: any) => {
    setCurrentVolume(e.target.value);
  };
  const onPartCalculate = async (_partNumber: any|string|null) => {
    /* SECTION: PARSE CUSTOMER PART NUMBER */

    const partCustomerId = (_partNumber || currentPart).match(/[a-z]+/i) || '';
    const partNumber = (_partNumber || currentPart).match(/[0-9]+/) || '';
    const customer = _.find(customers, (c: ICustomerRecord) => c.DisplayName.match(new RegExp(partCustomerId[0], 'i'))) as ICustomerRecord;

    // if we don't get any useful/meaningful info from the input field, raise the issue with the user.
    if (!partCustomerId || !partNumber || !customer) {
      return Modal.error({
        title: 'Unknown/inactive part or customer. Please try again.',
        onOk: () => null,
      });
    }

    // Compute full part number
    const fullPartNumber = partNumberWithZeroes(partNumber[0]);
    setCurrentPart(`${customer.DisplayName}_${fullPartNumber}`);

    let volume;
    let weight;
    let bodyData: ICustomerPart;
    let bodyDescription: string;

    // try to fetch part document from database—if success, set that as our current part
    const doc = await firestore.collection(partViewerDbString).doc(`${customer.id}_${fullPartNumber}`).get();

    if (!doc.exists) {
      setCurrentPartData(partState);
      bodyData = partState;
      volume = partState.volume;
      // @ts-ignore
      weight = resolveWeight(partState.config);
      bodyDescription = configToDescription(partState.config, configTerms);
      // return Modal.error({ title: 'Couldn\'t find that part -— please try again or chat with Keith.' });
    } else {
      bodyData = doc.data() as ICustomerPart;
      if (bodyData.type === 'BB' || resolveModel(bodyData.config).model.match(/jaguar|(jazz|tele)master/i)) {
        setSpreadLength(22);
        setSpreadWidth(15);
      }
      setCurrentPartData(bodyData);
      // all parts must have at least a volume or weight
      // @ts-ignore
      volume = bodyData.volume;
      // @ts-ignore
      weight = resolveWeight(bodyData.config);
      bodyDescription = configToDescription(bodyData.config, configTerms);
    }

    if (!volume || !weight) {
      return Modal.error({ title: 'Part does not have weight/volume information to calculate.' });
    }

    /*
      some topped bodies will have their weights dramaticallyl skewed by the top weight -- by about 2-4 ounces.
      here we will set a flag to denote whether or not to account for this down the road.
     */
    const bodyTopped = resolveWood(bodyData.config).top !== 'None';
    setCurrentWeightChartRow(weight.text ? `${weight.text}-row` : '');

    /*
      if we don't have a weight value for the part, we want to make sure the min/max weights
      are at the defaults. We do this because if we look up a part with a weight,
      the program will limit the number of steps away from the target weight.
      the next part may not relate to that target weight at all, so we want the defaults.
    */
    let minWeight;
    let maxWeight;
    // if the user has enabled a custom weight range and the entered part has a weight value
    // if (customWeight) {
    if (customWeight) {
      devLog('UniversalDensityCalculatorByPart', 126, 'custom weight');
      minWeight = minWeightPounds + (minWeightOunces / 16);
      maxWeight = maxWeightPounds + (maxWeightOunces / 16);
    // if either the user has enabled a custom weigh range or there is simply no weight value for the entered part
    // reset the min and max weights to the default weights
    } else if (!weight.value) {
      devLog('UniversalDensityCalculatorByPart', 132, 'no weight value');
      minWeight = poundWeight(MIN_WEIGHT) + (ounceWeight(MIN_WEIGHT) / 16);
      maxWeight = poundWeight(MAX_WEIGHT) + (ounceWeight(MAX_WEIGHT) / 16);
    // if no custom weight range is enabled, and the body has a weight value, set min and max based off of that value
    } else {
      devLog('UniversalDensityCalculatorByPart', 137, 'we have weight');
      minWeight = weight.value - 0.25;
      maxWeight = weight.value + 0.125;
    }
    devLog('UniversalDensityCalculatorByPart', 141, minWeight);
    devLog('UniversalDensityCalculatorByPart', 142, maxWeight);

    // once min and max weight are defined, set them into state
    setMinWeightPounds(Math.floor(minWeight));
    setMinWeightOunces((minWeight - Math.floor(minWeight)) * 16);
    setMaxWeightPounds(Math.floor(maxWeight));
    setMaxWeightOunces((maxWeight - Math.floor(maxWeight)) * 16);

    const calculateTopPercentage = (coreVolume: number, wrVolume: number) => {
      // calculate the initial volume of the top before weight reduction
      const topSolidVolume = (coreVolume + wrVolume) * TOP_SOLID_PCT;
      // calculate the new total volume of both parts
      const wRCoreVolume = coreVolume - topSolidVolume;
      // return the new percentage of part B
      return topSolidVolume / wRCoreVolume;
    };

    const weightReduction = resolveWeightReductionType(bodyData.config);
    let weightReductionVolume = 0;
    if (weightReduction.text.match(/sh/i)) {
      weightReductionVolume = WR_VOLUME.SH;
    } else if (weightReduction.text.match(/hc/i)) {
      weightReductionVolume = WR_VOLUME.HC;
    } else if (weightReduction.text.match(/tl/i)) {
      weightReductionVolume = WR_VOLUME.TL;
    } else if (!weightReduction.text.match(/tl|hc|sh/i)) {
      weightReductionVolume = WR_VOLUME.SLOT;
    }

    // Derivation of weight objects that are used to create the weight chart table
    const weights = allWeights(minWeight, maxWeight).map((w) => {
      const totalVolume = parseFloat(volume);
      const spreadVolume = (spreadLength * spreadThick * spreadWidth) / 144;
      let density = (w / totalVolume);
      // boardYield is the percentage of a given blank that will be in the final body shape
      const boardYield = parseFloat(volume) / spreadVolume;
      if (bodyTopped
        && resolveArchetype(bodyData.config).text === 'GB'
        && resolveModel(bodyData.config).text.match(/tl|tx|tele|t69|t72|ce|70s/i)
        && resolveWood(bodyData.config).top?.match(/map|flame|flm|quilt|qlt/i)) {
        // calculate the percentage of the reduced body occupied by the top, knowing that if it were solid,
        // the top would compose 11% of the total volume. NOTE: if body is solid, amountReduced will equal 0.
        const topPercentage = calculateTopPercentage(totalVolume, weightReductionVolume);
        // next, get the final density of the top with respect to the whole volume, knowing that
        // it is some percentage of the whole body
        const topDensity = topPercentage * AVG_MAPLE_DENSITY;
        // core percentage is simply the inverse of the top percentage
        const corePercentage = 1 - topPercentage;
        // re-calculate density. This is based on the proof:
        //    (densityTOP * topPercentage) + (densityCORE * corePercentage) = densityTOTAL
        //     --> (densityCORE * corePercentage) = densityTOTAL - (densityTop * topPercentage)
        //     --> densityCORE = (densityTOTAL - (densityTop * topPercentage)) / corePercentage
        density = (density - topDensity) / corePercentage;
        devLog('UniversalDensityCalculatorByPart', 211, `Amount reduced: ${weightReductionVolume}`);
        devLog('UniversalDensityCalculatorByPart', 212, `Top %: ${topPercentage}`);
        devLog('UniversalDensityCalculatorByPart', 213, `Top Density: ${topDensity}`);
        devLog('UniversalDensityCalculatorByPart', 214, `Core %: ${corePercentage}`);
        devLog('UniversalDensityCalculatorByPart', 215, `Adjusted density: ${density}`);
        setMapleTopAdjustedWeight(true);
      } else {
        setMapleTopAdjustedWeight(false);
      }

      const ounceWeightText = `${poundWeight(w)}Lb ${ounceWeight(w)}`;

      return {
        // @ts-ignore
        isTargetWeight: weight.text === ounceWeightText,
        decimalWeight: w,
        ounceWeight: ounceWeightText,
        density: density.toFixed(2),
        spreadWeight: (w / boardYield).toFixed(2),
      };
    }) as IDensityRecord[];

    // Use the debounced function
    debouncedSetWeightDensities(weights);
    const el = document.getElementById('results-column');
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const onPartCalculateClick = (e: any) => {
    onPartCalculate(e.target.value); // send the value of the field to the calculate function
  };
  const onVolumeCalculate = (e: any) => {
    /*
     A simpler version of the onPartCalculate method, this function will always derive the weight chart
     using the current min and max weights, whether set by default or a custom range.
     */
    const minWeight = minWeightPounds + minWeightOunces / 16;
    const maxWeight = maxWeightPounds + maxWeightOunces / 16;
    // volume comes from the input field
    const volume = parseFloat(currentVolume);
    // const bodyTopped = bodyData.top !== 'None';

    // Derivation of weight objects that are used to create the weight chart table
    const weights = allWeights(minWeight, maxWeight).map((w) => {
      const density = (w / volume);
      const spreadVolume = ((spreadLength * spreadThick * spreadWidth) / 144);
      const boardYield = volume / spreadVolume;
      setMapleTopAdjustedWeight(false);
      return {
        decimalWeight: w,
        ounceWeight: `${poundWeight(w)}Lb ${ounceWeight(w)}`,
        density: density.toFixed(2),
        spreadWeight: (w / boardYield).toFixed(2),
      };
    }) as IDensityRecord[];

    // Use the debounced function
    debouncedSetWeightDensities(weights);
  };
  const onWeightChange = (maxOrMin: 'max'|'min', type: 'pounds'|'ounces') => (e: any) => {
    setCustomWeight(true);
    if (maxOrMin === 'max') {
      if (type === 'pounds') setMaxWeightPounds(parseFloat(e));
      else setMaxWeightOunces(parseFloat(e));
    } else if (type === 'pounds') setMinWeightPounds(parseFloat(e));
    else setMinWeightOunces(parseFloat(e));
  };
  // const onToggleUseLumber = (e: any) => {
  //   setUseLumber(!useLumber);
  // };
  // const onToggleWeight = (e: any) => {
  //   setShowWeight(!showWeight);
  // };
  const onSpreadChange = (type: 'width'|'length'|'thick') => (e: any) => {
    if (type === 'width') setSpreadWidth(e);
    else if (type === 'length') setSpreadLength(e);
    else setSpreadThick(e);

    // calculate
    if (calcType === 'Part') document.getElementById('calc-part-button').click();
    else document.getElementById('calc-vol-button').click();
  };
  const onRowClick = (id: string) => (e: any) => {
    setCurrentWeightChartRow(id);
  };
  const onChangeCalcType = (e: any) => {
    const typeValue = e.target?.value || e;
    setCalcType(typeValue);
  };

  useEffect(() => {
    setCurrentPart('');
    setCurrentVolume('');
    setCurrentPartData(null);
    setWeightDensities([]);
    setMinWeightPounds(poundWeight(MIN_WEIGHT));
    setMinWeightOunces(ounceWeight(MIN_WEIGHT));
    setMaxWeightPounds(poundWeight(MAX_WEIGHT));
    setMaxWeightOunces(ounceWeight(MAX_WEIGHT));
    setCurrentWeightChartRow('');

    // const preloadedBody = localStorage.getItem('densityCalcRef');
    if (partId) {
      setTimeout(() => {
        onPartCalculate(partId);
      }, 50);
    }
    if (calcType === 'Part') {
      inputRef.current.focus();
    } else {
      volInputRef.current.focus();
    }
  }, [calcType]);

  return (
    <PageWrapper>
      <CalculatorColumn>
        <ToggleColumn>
          <h5>Calculator type</h5>
          <CalcTypeRadioGroup value={calcType} buttonStyle="solid" onChange={onChangeCalcType}>
            <ViewTypeRadioButton
              value="Part"
            >
              Part
            </ViewTypeRadioButton>
            <ViewTypeRadioButton
              value="Volume"
            >
              Volume
            </ViewTypeRadioButton>
          </CalcTypeRadioGroup>
        </ToggleColumn>
        <VolumeColumnWrapper>
          <VolumeInputWrapper>
            <PartInputWrapper>
              <h4>Part Number</h4>
              { calcType === 'Part' ? (
                <PartInput ref={inputRef} value={currentPart} onPressEnter={onPartCalculateClick} onChange={onPartNumberChange} placeholder="e.g., MJTEL_00001" />
              ) : (
                <PartInput ref={volInputRef} value={currentVolume} onPressEnter={onVolumeCalculate} onChange={onVolumeChange} placeholder="e.g., 1.58" />
              )}
            </PartInputWrapper>
            { calcType === 'Part' ? (
              <VolumeCalculateButton id="calc-part-button" type="primary" onClick={onPartCalculateClick} icon={<CalculateIcon />} />
            ) : (
              <VolumeCalculateButton id="calc-vol-button" type="primary" onClick={onVolumeCalculate} icon={<CalculateIcon />} />
            )}
          </VolumeInputWrapper>
        </VolumeColumnWrapper>

        <CalculatorSectionWrapper>
          {/* <ToggleWeightButton type="primary" onClick={onToggleWeight} enable={showWeight}>{showWeight ? 'Hide weight settings' : 'Show weight settings'}</ToggleWeightButton> */}
          <h4>Chart Min/Max</h4>
          <CalculatorInputWrapper>
            <MinMaxWeightColumn>
              <h3>Min Weight</h3>
              <FlexRow>
                <MinMaxWeightInputWrapper>
                  <p>Lbs.</p>
                  <WeightInput value={minWeightPounds} onChange={onWeightChange('min', 'pounds')} type="tel" />
                </MinMaxWeightInputWrapper>
                <MinMaxWeightInputWrapper>
                  <p>Oz.</p>
                  <WeightInput max={15} min={0} value={minWeightOunces} onChange={onWeightChange('min', 'ounces')} type="tel" />
                </MinMaxWeightInputWrapper>
              </FlexRow>
            </MinMaxWeightColumn>
            <MinMaxWeightColumn>
              <h3>Max Weight</h3>
              <FlexRow>
                <MinMaxWeightInputWrapper>
                  <p>Lbs.</p>
                  <WeightInput
                    value={maxWeightPounds}
                    onChange={onWeightChange('max', 'pounds')}
                    type="tel"
                  />
                </MinMaxWeightInputWrapper>
                <MinMaxWeightInputWrapper>
                  <p>Oz.</p>
                  <WeightInput max={15} min={0} value={maxWeightOunces} onChange={onWeightChange('max', 'ounces')} type="tel" />
                </MinMaxWeightInputWrapper>
              </FlexRow>
            </MinMaxWeightColumn>
          </CalculatorInputWrapper>
        </CalculatorSectionWrapper>

        <CalculatorSectionWrapper>
          <h4>Spread Size</h4>
          <CalculatorInputWrapper>
            <MinMaxWeightColumn>
              <h3>Width</h3>
              <LumberInput disabled={false} value={spreadWidth} onChange={onSpreadChange('width')} type="number" />
            </MinMaxWeightColumn>
            <MinMaxWeightColumn>
              <h3>Length</h3>
              <LumberInput disabled={false} value={spreadLength} onChange={onSpreadChange('length')} type="number" />
            </MinMaxWeightColumn>
            <MinMaxWeightColumn>
              <h3>Thick</h3>
              <LumberInput disabled={false} value={spreadThick} onChange={onSpreadChange('thick')} type="number" />
            </MinMaxWeightColumn>
          </CalculatorInputWrapper>
        </CalculatorSectionWrapper>

      </CalculatorColumn>

      <ResultsColumnWrapper id="results-column">
        {currentPartData
        && (
        <PartHeaderRow>
          <PartDataColumn>
            <h3>{currentPartData.Sku}</h3>
            <PartDescription>{configToDescription(currentPartData.config)}</PartDescription>
            <PartBoardFootage>{`${currentPartData.volume} BF`}</PartBoardFootage>
          </PartDataColumn>
        </PartHeaderRow>
        )}
        <ResultsColumn>
          {!!weightDensities.length
         && (
         <>
           <DensityChartHeader showSpreadWeight />
           {weightDensities.map((wD, index) =>
             (
               <DensityChartRow
                 isTargetWeight={wD.isTargetWeight}
                 density={wD.density}
                 ounceWeight={wD.ounceWeight}
                 decimalWeight={wD.decimalWeight}
                 onClick={onRowClick}
               />
             ))}
         </>
         )}
        </ResultsColumn>
      </ResultsColumnWrapper>
    </PageWrapper>
  );
};
