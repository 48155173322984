/*
  Desktop site menu for Helm
 */

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider, Menu, Modal } from 'antd';
import { flatten, includes } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AccountCircle,
  Bolt,
  Calculate,
  CalendarMonth,
  DataObject,
  Description,
  EditNote,
  Factory,
  Handshake,
  Inbox,
  Inventory2,
  List,
  LocalShipping,
  Logout,
  NewReleases,
  Paid,
  QrCode,
  QrCodeScanner,
  Sell,
  Settings,
  Report,
  PrecisionManufacturing,
  VideogameAsset,
  Upload,
} from '@styled-icons/material-twotone';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import {
  adminUserEmailsAtom, customerUserEmailsAtom,
  shopUserEmailsAtom,
  superAdminUserEmailAtom,
  supervisorEmailsAtom,
} from 'shared/state/routingState';
import { currentPageTitleAtom } from 'shared/state/orderState';
import { FlexColumn } from 'shared/containers/FlexContainer/styledComponents';
import { clearRedirect } from 'shared/util';
import { showMessageCenterAtom, showSettingsCenterAtom } from 'shared/state/messageState';
import { IMessage } from '../types/dbRecords';
import useFirebase from '../../vendor/Firebase';
import theme from '../theme';
import { rgba } from '../palette/utils';
import ScopedComponent from '../components/Utility/ScopedComponent';

const ComponentWrapper = styled(FlexColumn)`
    width: ${(props: { collapsed: boolean }) => (props.collapsed ? '58px' : '280px')};
    position: fixed;
    top: 18px;
    left: 24px;
    z-index: 9999999;
    align-items: flex-start;
    gap: 0;
    box-shadow: ${(props: { collapsed: boolean }) => (!props.collapsed ? 'none' : 'rgba(0, 0, 0, 0.18) 0px 2px 4px')};
    transition:  ${(props: { collapsed: boolean }) => (!props.collapsed ? 'width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s' : 'width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s')};
    border-radius: 8px;
    //overflow: hidden;
    
    & .ant-menu.ant-menu-inline-collapsed {
        height: 0;
        overflow: hidden;
        // background-color:  ${(props: { collapsed: boolean }) => (props.collapsed ? theme.palette.primary.hue : '#001529')};
        display: ${(props: { collapsed: boolean }) => (props.collapsed ? 'none' : 'unset')};
        @media ${theme.device.laptopL} {
            //background-color: #001529;
            display: unset;
            height: unset;
            overflow: unset;
        }
    }
    
    @media ${theme.device.laptopM} {
        top: 16px;
        left: 16px;
    }
    
`;
// @ts-ignore
export const SiteMenuLogo = styled.button`
    cursor: pointer;
    height: 72px;
    width: 58px;
    border: none;
    background-color: ${theme.palette.primary.hue};
    background-image: ${(props: { siteImage: string }) => `url(${props.siteImage})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    //width: ${(props: { collapsed: boolean }) => (props.collapsed ? '58px' : '280px')};
    //border-top-right-radius: 8px;
    //border-top-left-radius: 8px;
    border-radius: ${(props: { collapsed: boolean }) => (props.collapsed ? '8px' : '8px 8px 0 0')};
    //transition: width 0.3s cubic-bezier(0.2, 0, 0, 1);
    
    @media ${theme.device.laptopL} {
        border-radius: 8px 8px 0 0;
        height: 48px;
    }
`;

const InboxIcon = styled(({ showMailUnreadPip, mailCount, ...props }) => (
  <div {...props}>
    <Inbox />
    {(mailCount > 0 && showMailUnreadPip) && <span />}
  </div>
))`
  display: inline-flex;
  position: relative;
  width: 28px;

  svg {
    width: 100%;
    color: ${({ mailCount }) => (mailCount > 0 ? theme.palette.primary.hue : rgba(theme.palette.neutral.white, 0.6))};
  }

  span {
    content: "";
    position: absolute;
    top: 7px;
    right: -1px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
  }
`;

const SiteMenuLinkWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border: none;
    &:hover {
      background-color: transparent;
      border: none;
        & a {
            color: rgba(255, 255, 255, 1);
        }
    }
    & a {
        color: rgba(255, 255, 255, 0.65);
    }
    
`;
const SiteMenuSubDivider = styled(Divider)`
    margin: 4px 0px;
`;
const SiteMenuLink = styled.a`
    color: rgba(255, 255, 255, 0.65);
    position: relative;
    //top: 2px;
    font-size: 14px;
`;

const MenuComponent = styled(Menu)`
    & [data-menu-id$="-1-tmp-6"]{
    & span {
      overflow: unset !important;
    }
  }
`;

const StagingCopy = styled.h1`
    display: none;
    font-weight: bolder;
    position: fixed;
    top: ${(props: { collapsed: boolean }) => (props.collapsed ? '442px' : '392px')};
    left: 69px;
    transform: rotate(90deg);
    transform-origin: left top 0;
    background-color: ${theme.palette.primary.hue};
    color: white;
    padding: 2px 16px 2px 32px;
    padding-left: ${(props: { collapsed: boolean }) => (props.collapsed ? '32px' : '120px')};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    
    @media ${theme.device.laptopL} {
        display: unset;
    }
`;

const TOP_LEVEL_ICON_STYLE = { width: 28, marginLeft: -8 };
const CHILD_ICON_STYLE = { width: 24, marginRight: 12 };
// styles for font-awesome icons, which are somewhat larger
const FA_TOP_LEVEL_ICON_STYLE = { width: 21, marginLeft: -5 };
const FA_CHILD_ICON_STYLE = {
  width: 19, marginRight: 15, position: 'relative', left: 3, top: 2,
};

const SiteMenu = () => {
  const { auth, database } = useFirebase();
  const { currentUser } = useContext(AuthContext);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom);
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom);
  const customerUserEmails = useRecoilValue(customerUserEmailsAtom);
  const [menuItems, setMenuItems] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const currentPageTitle = useRecoilValue(currentPageTitleAtom);
  const mailDbString = '/mail';
  const [mailCount, setMailCount] = useState<number>(0);
  const setShowMessageCenter = useSetRecoilState(showMessageCenterAtom);
  const setShowSettingsCenter = useSetRecoilState(showSettingsCenterAtom);

  const toggleMailDrawer = (e: any) => {
    setShowMessageCenter(true);
    setCollapsed(true);
  };

  const toggleSettingsCenter = (e: any) => {
    setShowSettingsCenter(true);
    setCollapsed(true);
  };
  const onLogout = (e: any) => {
    Modal.confirm({
      content: 'Log out?',
      okText: 'Log out',
      cancelText: 'Cancel',
      onOk: () => {
        auth().signOut().then(() => {
          window.location.href = '/login';
        });
      },
    });
  };

  const onWrapperClick = (url: string) => (e: any) => {
    e.preventDefault();
    clearRedirect();
    window.location.href = url;
  };

  const salesMenu = {
    label: 'Sales',
    key: 'sales',
    icon: <Paid style={TOP_LEVEL_ICON_STYLE} />,
    children: [
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/customers')}>
            <AccountCircle style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/customers" rel="noopener noreferrer">
              Customers
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'sales-customers',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/pricing')}>
            <Sell style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/pricing" rel="noopener noreferrer">
              Pricing
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'sales-pricing-main',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/pricing/edit')}>
            <EditNote style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/pricing/edit" rel="noopener noreferrer">
              Edit Pricing
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'sales-pricing-edit',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/orders')}>
            <Description style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/orders" rel="noopener noreferrer">
              Orders
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'sales-orders-main',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
    ],
  };
  const productDevelopmentMenu = {
    label: 'Product Development',
    key: 'product-development',
    icon: <PrecisionManufacturing style={TOP_LEVEL_ICON_STYLE} />,
    children: [
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/pd/queue')}>
            <PrecisionManufacturing style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/pd/queue" rel="noopener noreferrer">
              Development Queue
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'product-development-queue',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/pd/part-terms')}>
            <List style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/pd/part-terms" rel="noopener noreferrer">
              Part Terms
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'part-terms',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
    ],
  };
  const materialsMenu = {
    label: 'Materials',
    key: 'materials',
    icon: <Inventory2 style={TOP_LEVEL_ICON_STYLE} />,
    children: [
      {
        label: (
          <SiteMenuLink href="/inventory" rel="noopener noreferrer">
            <Inventory2 style={CHILD_ICON_STYLE} />
            Inventory
          </SiteMenuLink>
        ),
        key: 'materials-inventory',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/vendors')}>
            <Handshake style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/vendors" rel="noopener noreferrer">
              Vendors
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'materials-vendors',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/purchase-orders')}>
            <Description style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/purchase-orders" rel="noopener noreferrer">
              Purchase Orders
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'purchase-orders-main',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
    ],
  };
  const productionMenu = {
    label: 'Production',
    key: 'production',
    icon: <Factory style={{ ...TOP_LEVEL_ICON_STYLE, marginBottom: 2 }} />,
    children: [
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick(`/schedule/${localStorage.getItem('salesOrderViewType') || 'week'}`)}>
            <CalendarMonth style={CHILD_ICON_STYLE} />
            <SiteMenuLink href={`/schedule/${localStorage.getItem('salesOrderViewType') || 'week'}`} rel="noopener noreferrer">
              Production Plan
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'production-orders',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/router')}>
            <QrCode style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/router" rel="noopener noreferrer">
              Router
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'production-router',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/shipments')}>
            <LocalShipping style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/shipments" rel="noopener noreferrer">
              Shipments
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'production-shipments',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/scanner')}>
            <QrCodeScanner style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/scanner" rel="noopener noreferrer">
              Job Scanner
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'production-scanner',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
    ],
  };
  const ncMenu = {
    label: 'Quality Assurance',
    key: 'nonConformance',
    icon: <Report style={TOP_LEVEL_ICON_STYLE} />,
    // icon: <Award style={FA_TOP_LEVEL_ICON_STYLE} />,
    children: [
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/qa/tickets')}>
            <Report style={{
              ...CHILD_ICON_STYLE, width: 30, position: 'relative', left: -2, marginRight: 6,
            }}
            />
            {/* <Award style={FA_CHILD_ICON_STYLE} /> */}
            <SiteMenuLink href="/qa/tickets" rel="noopener noreferrer">
              Non-conformance Report
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'qa-ticket-browser',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
      {
        label: (
          <SiteMenuLinkWrapper onClick={onWrapperClick('/nc/scanner')}>
            <QrCodeScanner style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="/nc/scanner" rel="noopener noreferrer">
              Non-conformance Scanner
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'nonConformance-scanner',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
    ],
  };

  const dataParsersMenuItem = {
    label: 'Utilities',
    key: 'utilities',
    icon: <Bolt style={TOP_LEVEL_ICON_STYLE} />,
    children: [
      {
        label: (
          <SiteMenuLink href="/util/parsers" rel="noopener noreferrer">
            <DataObject style={CHILD_ICON_STYLE} />
            Utility
          </SiteMenuLink>
        ),
        key: 'utilities-parsers',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails]),
      },
      {
        label: (
          <SiteMenuLink href="/util/nc-file-analyzer" rel="noopener noreferrer">
            <Upload style={CHILD_ICON_STYLE} />
            NC File Analyzer
          </SiteMenuLink>
        ),
        key: 'utilities-nc-file-analyzer',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
      {
        label: (
          <SiteMenuLink href="/util/density-calculator" rel="noopener noreferrer">
            <Calculate style={CHILD_ICON_STYLE} />
            Density Calculator
          </SiteMenuLink>
        ),
        key: 'utilities-density-calculator',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
      {
        label: (
          <SiteMenuLink href="/util/name-game" rel="noopener noreferrer">
            <VideogameAsset style={CHILD_ICON_STYLE} />
            Name Game
          </SiteMenuLink>
        ),
        key: 'utilities-name-game',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails, shopUserEmails.emails]),
      },
    ],
  };
  const messageCenterItem = {
    label: 'Notifications',
    key: 'notification',
    icon: <InboxIcon style={TOP_LEVEL_ICON_STYLE} mailCount={mailCount} showMailUnreadPip />,
    children: [
      {
        label: (
          <SiteMenuLinkWrapper onClick={toggleMailDrawer}>
            <InboxIcon style={CHILD_ICON_STYLE} mailCount={mailCount} showMailUnreadPip={false} />
            <SiteMenuLink href="#" onClick={toggleMailDrawer}>
              Notifications
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'message-center',
        whitelist: flatten([superAdminEmails.emails, adminEmails.emails, supervisorEmails.emails]),
      },
    ],
  };

  const settingsCenterItem = {
    label: 'Settings',
    key: 'settings',
    icon: <Settings style={TOP_LEVEL_ICON_STYLE} />,
    children: [
      {
        label: (
          <SiteMenuLinkWrapper onClick={toggleSettingsCenter}>
            <Settings style={CHILD_ICON_STYLE} />
            <SiteMenuLink href="#" onClick={toggleSettingsCenter}>
              Settings
            </SiteMenuLink>
          </SiteMenuLinkWrapper>
        ),
        key: 'settings-center',
      },
    ],
  };

  const logoutMenuItem = {
    label: (
      <>
        <Logout style={{ ...TOP_LEVEL_ICON_STYLE, marginLeft: -6, marginRight: 8 }} />
        <SiteMenuLink href="#" onClick={onLogout}>
          Sign out
        </SiteMenuLink>
      </>
    ),
  };

  const toggleCollapsed = (e: any) => {
    setCollapsed(!collapsed);
  };
  const onMenuClick = (e: any) => {
    if (e.key !== 'message-center') {
      clearRedirect();
      const menuItemKey = e.key.split('-')[0];
      const childMenuKey = e.key;
      setSelectedKeys([menuItemKey, childMenuKey]);
      localStorage.setItem('currentMenuKeys', [menuItemKey, childMenuKey].join(','));
    }
  };

  const filterMenuOptions = (menuOptions: any, userEmail: string) => {
    const childItems = menuOptions.children.filter((c: any) => !c.whitelist || includes(c.whitelist, userEmail));
    if (childItems.length === 0) return null;
    return {
      ...menuOptions,
      children: childItems,
    };
  };

  useEffect(() => {
    if (currentUser) {
      setMenuItems(flatten([
        filterMenuOptions(salesMenu, currentUser.email),
        filterMenuOptions(productDevelopmentMenu, currentUser.email),
        // includes([...superAdminEmails.emails, ...adminEmails.emails], currentUser.email) ? salesMenu : [],
        filterMenuOptions(productionMenu, currentUser.email),
        // productionMenu,
        filterMenuOptions(materialsMenu, currentUser.email),
        // includes([...superAdminEmails.emails, ...adminEmails.emails], currentUser.email) ? materialsMenu : [],
        filterMenuOptions(ncMenu, currentUser.email),
        // ncMenu,
        filterMenuOptions(dataParsersMenuItem, currentUser.email),
        // includes([...superAdminEmails.emails], currentUser.email) ? dataParsersMenuItem : [],
        filterMenuOptions(messageCenterItem, currentUser.email),
        filterMenuOptions(settingsCenterItem, currentUser.email),
        collapsed ? null : logoutMenuItem,
      ]));
      const selected = localStorage.getItem('currentMenuKeys');
      if (selected?.length > 0) setSelectedKeys(selected.split(','));
      else setSelectedKeys(['production', 'production-orders']);
    }
  }, [currentPageTitle, collapsed, mailCount]);

  useEffect(() => {
    database.ref(mailDbString).on('value', (snap) => {
      const data = snap.val() || {};
      const unreadMessages = Object.values(data.unread) || [];
      const personalUnread = Object.values(data[currentUser.email.split('@')[0]]?.unread || {});
      const communalUnread = unreadMessages.filter((m: IMessage) => includes(m.users, currentUser.email.split('@')[0]));
      const combinedMail = [...personalUnread, ...communalUnread];
      if (!combinedMail.length) {
        setMailCount(0);
        return;
      }
      setMailCount(combinedMail.length || 0);
    });
  }, []);

  return (
    <>
      {(currentUser && !window.location.href.match(/login/)) && (
      <ScopedComponent blacklist={customerUserEmails.emails}>
        <ComponentWrapper collapsed={collapsed}>
          <SiteMenuLogo collapsed={collapsed} siteImage="/images/helm_logo_compact.png" onClick={toggleCollapsed} />
          <MenuComponent
            onClick={onMenuClick}
            selectedKeys={selectedKeys}
            defaultOpenKeys={[]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={menuItems}
          />
        </ComponentWrapper>
        {window.location.href.match(/staging/i) && (
          <StagingCopy collapsed={collapsed}>STAGING</StagingCopy>
        )}
        {window.location.href.match(/localhost/i) && (
          <StagingCopy collapsed={collapsed}>DEV</StagingCopy>
        )}
      </ScopedComponent>
      )}
    </>
  );
};

export default SiteMenu;
