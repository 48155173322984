import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Tag } from 'antd';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue } from 'recoil';
import { ORDERS_DB_COLLECTION, currentShopOrderAtom } from 'shared/state/orderState';
import { stageRedirect } from 'shared/util';

const LinkedOrdersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

const AddLinkButton = styled.div`
  background: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  padding: 0 7px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    border-color: #1890ff;
    color: #1890ff;
  }
`;

const StyledAutoComplete = styled.div`
  .ant-select {
    width: 200px;
  }
`;

const StyledTag = styled(Tag)`
  cursor: pointer;
  
  &:hover {
    background: #e6f7ff;
  }
`;

const OrderOptionLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const OrderNumber = styled.div`
  font-weight: 500;
`;

const OrderDescription = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`;

interface ILinkedSalesOrderProps {
  currentCustomerName: string;
  linkedOrders?: string[];
  callback: (linkedOrders: string[], removed?: string|null) => Promise<void>;
}

interface IOrderOption {
  value: string;
  label: React.ReactNode;
  id: string;
  description?: string;
}

interface ILinkedOrderDetails {
  id: string;
  salesOrder: string;
  customerName: string;
  type: string;
}

const LinkedSalesOrder: React.FC<ILinkedSalesOrderProps> = ({
  currentCustomerName,
  linkedOrders = [],
  callback,
}) => {
  const { firestore } = useFirebase();
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);

  const [isInputVisible, setIsInputVisible] = useState(false);
  const [availableOrders, setAvailableOrders] = useState<IOrderOption[]>([]);
  const [linkedOrderDetails, setLinkedOrderDetails] = useState<Map<string, ILinkedOrderDetails>>(new Map());

  // Memoize the orders query
  const fetchOrders = useCallback(async () => {
    if (!currentCustomerName) return;

    const ordersRef = firestore.collection(ordersDbCollectionString);
    const snapshot = await ordersRef
      .where('customer.DisplayName', '==', currentCustomerName)
      .where('completed', '!=', true)
      .get();

    const orders = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        value: data.salesOrder,
        label: (
          <OrderOptionLabel>
            <OrderNumber>{data.salesOrder}</OrderNumber>
            {data.description && (
              <OrderDescription>{data.description}</OrderDescription>
            )}
          </OrderOptionLabel>
        ),
        id: doc.id,
        description: data.description,
      };
    });

    setAvailableOrders(orders.filter((order) => order.id !== currentShopOrder.id));
  }, [currentCustomerName, currentShopOrder.id, firestore, ordersDbCollectionString]);

  // Memoize linked orders fetch
  const fetchLinkedOrderDetails = useCallback(async () => {
    if (!linkedOrders.length) return;

    const ordersRef = firestore.collection(ordersDbCollectionString);
    // Batch get all linked orders in one query
    const linkedDocs = await Promise.all(
      linkedOrders.map((orderId) => ordersRef.doc(orderId).get()),
    );

    const linkedDetailsMap = new Map();
    linkedDocs.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data();
        linkedDetailsMap.set(doc.id, {
          id: doc.id,
          salesOrder: data?.salesOrder,
          customerName: data?.customer?.DisplayName,
          type: data?.type,
        });
      }
    });
    setLinkedOrderDetails(linkedDetailsMap);
  }, [linkedOrders, firestore, ordersDbCollectionString]);

  // Split effects to avoid unnecessary re-renders
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    fetchLinkedOrderDetails();
  }, [fetchLinkedOrderDetails]);

  const handleSelect = useCallback((_value: string, option: IOrderOption) => {
    const updatedOrders = [...linkedOrders, option.id];
    callback(updatedOrders, null);
    setIsInputVisible(false);
  }, [linkedOrders, callback]);

  const handleRemoveLink = useCallback((orderId: string) => {
    const updatedOrders = linkedOrders.filter((id) => id !== orderId);
    callback(updatedOrders, orderId);
  }, [linkedOrders, callback]);

  const handleTagClick = useCallback((orderId: string, e: React.MouseEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).className.includes('close')) return;

    const orderDetails = linkedOrderDetails.get(orderId);
    if (!orderDetails) return;

    stageRedirect();
    window.location.href = `/orders/create?edit=true&orderId=${orderDetails.id}&customer=${encodeURIComponent(orderDetails.customerName)}&salesOrder=${encodeURIComponent(orderDetails.salesOrder)}`;
  }, [linkedOrderDetails]);

  // Memoize filtered options
  const filteredOptions = useMemo(() => availableOrders.filter(
    (order) => !linkedOrders.includes(order.id),
  ), [availableOrders, linkedOrders]);

  return (
    <LinkedOrdersContainer>
      {linkedOrders.map((orderId) => {
        const details = linkedOrderDetails.get(orderId);
        return (
          <StyledTag
            key={orderId}
            closable
            onClose={() => handleRemoveLink(orderId)}
            onClick={(e) => handleTagClick(orderId, e)}
          >
            {details
              ? `${details.salesOrder} ${details.type ? `(${details.type.charAt(0).toUpperCase()})` : ''}`
              : 'Loading...'}
          </StyledTag>
        );
      })}

      {isInputVisible ? (
        <StyledAutoComplete>
          <AutoComplete
            autoFocus
            options={filteredOptions}
            onSelect={handleSelect}
            onBlur={() => setIsInputVisible(false)}
            filterOption={(inputValue: string, option: IOrderOption) => (
              option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              || (option?.description?.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1) !== -1
            )}
          />
        </StyledAutoComplete>
      ) : (
        <AddLinkButton onClick={() => setIsInputVisible(true)}>
          <PlusOutlined />
          Link Order
        </AddLinkButton>
      )}
    </LinkedOrdersContainer>
  );
};

export default React.memo(LinkedSalesOrder);
