import React from 'react';
import {
  StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { sortBy } from 'lodash';
import { ICustomerPart } from 'shared/types/parts';
import { formatPrice } from 'shared/data';
import { breakPartDescription, configToDescription } from 'shared/partParser/util';
import { cleanMeta } from 'shared/text';

const HEADER_FONT_SIZE = 10;
const BODY_FONT_SIZE = 8;
const HORIZONTAL_PADDING = 24;

const style = StyleSheet.create({
  componentWrapper: {
    width: '95%',
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: '1px solid lightgrey',
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: HORIZONTAL_PADDING,
  },
  headerComponentWrapper: {
    marginTop: 24,
    paddingBottom: 0,
    borderBottom: '1px solid black',
  },
  shadedRow: {
    backgroundColor: '#f5f5f5',
  },
  partNumberWrapper: {
    width: 120,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 4,
  },
  descriptionCol: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 400,
  },
  priceWrapper: {
    width: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 12,
  },
  headerText: {
    fontFamily: 'Red Hat Display-700',
    fontSize: HEADER_FONT_SIZE,
  },
  bodyText: {
    fontFamily: 'Red Hat Display',
    fontSize: BODY_FONT_SIZE,
  },
  partNumberText: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
  },
  descriptionPart: {
    fontFamily: 'Red Hat Display',
    fontWeight: 500,
    fontSize: BODY_FONT_SIZE,
    margin: 0,
  },
  descriptionPartIndent: {
    marginLeft: 4,
  },
});

const TableHeader = () => (
  <View fixed style={[style.componentWrapper, style.headerComponentWrapper]}>
    <View style={style.partNumberWrapper}>
      <Text style={style.headerText}>Part Number</Text>
    </View>
    <View style={style.descriptionCol}>
      <Text style={style.headerText}>Description</Text>
    </View>
    <View style={style.priceWrapper}>
      <Text style={style.headerText}>Price</Text>
    </View>
  </View>
);

const TableRow = ({ part, index }: { part: ICustomerPart; index: number }) => {
  const wholeDescription = cleanMeta(configToDescription(part.config));
  const splitDescription = breakPartDescription(part.config);
  return (
    <>
      {part && (
      <View
        wrap={false}
        style={[
          style.componentWrapper,
          index % 2 === 1 ? style.shadedRow : {},
        ]}
      >
        <View style={style.partNumberWrapper}>
          <Text style={[style.bodyText, style.partNumberText]}>{part.Sku}</Text>
        </View>
        <View style={style.descriptionCol}>
          {wholeDescription.length <= 75 ? (
            <Text style={style.descriptionPart}>{wholeDescription}</Text>
          ) : (
            <>
              <Text style={style.descriptionPart}>{cleanMeta(splitDescription[0])}</Text>
              <Text style={[style.descriptionPart, style.descriptionPartIndent]}>{cleanMeta(splitDescription[1])}</Text>
            </>
          )}
        </View>
        <View style={style.priceWrapper}>
          <Text style={style.bodyText}>{formatPrice(part.price)}</Text>
        </View>
      </View>
      )}
    </>
  );
};

interface IComponent {
  customerParts: ICustomerPart[];
}

const PricingTable = ({ customerParts }: IComponent) => (
  <>
    <TableHeader />
    {sortBy(customerParts, (c) => {
      const parts = c.Sku.split('_');
      return parts.length > 1 ? parseInt(parts[1], 10) || Infinity : Infinity;
    }).map((part, index) => (
      <TableRow key={part.Sku} part={part} index={index} />
    ))}
  </>
);

export default PricingTable;
