import React from 'react';
import styled from 'styled-components';

const WatermarkContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
  user-select: none;
`;

const WatermarkText = styled.div`
  font-size: 256px;
  font-weight: bold;
  color: rgba(255, 0, 0, 0.15);
  transform: rotate(22.5deg);
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
`;

const DraftWatermark = () => (
  <WatermarkContainer>
    <WatermarkText>DRAFT</WatermarkText>
  </WatermarkContainer>
);

export default DraftWatermark;
