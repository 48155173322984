import React, { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { Button, Modal } from 'antd';
import { every, findIndex, omit } from 'lodash';
import { ArrowDropDown } from '@styled-icons/material';
import theme from 'shared/theme';
import {
  INVENTORY_ITEMS_COLLECTION,
  currentInventoryPartAtom,
  inventoryItemsAtom,
  showQuickEditDrawerAtom, inventoryPartEditedAtom,
} from 'shared/state/inventoryState';
import {
  clearRedirect, isDevSite, popRedirect, redirect, useTestData,
} from 'shared/util';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import QBOItem from 'shared/data/QBO/item';
import { IItemAdjustment, IQBOItem, IQuantityAdjustmentItem } from 'shared/types/qbo';
import { IInventoryChild, IInventoryPart } from 'shared/types/dbRecords';
import { qboDateString } from 'shared/data/calendar';
import { SaveText, SpinnerWrapper, SaveSpinner } from 'shared/styledComponents/utility';
import useFirebase from 'vendor/Firebase';
import { reorderPointNotification } from 'shared/messaging';
import { QBOError, handleQBOError } from 'shared/data/QBO/error';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../../../shared/state/messageState';
import { AuthContext } from '../../../../vendor/Firebase/AuthProvider';

/**
 * Button component that is responsible for saving inventory records
 */

const DropdownIcon = styled(ArrowDropDown)`
  width: 24px;
  margin-bottom: 2px;
`;

const SaveButton = styled(Button)`
  margin-left: ${theme.spacing(3)};
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;
export default () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const partState = useRecoilValue(currentInventoryPartAtom);
  const [quickEditMode, setQuickEditMode] = useRecoilState(showQuickEditDrawerAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const setInventoryPartEdited = useSetRecoilState(inventoryPartEditedAtom);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const [inventoryItems, setInventoryItems] = useRecoilState(inventoryItemsAtom);
  // @ts-ignore
  const [isSaving, setIsSaving] = useState(false);
  const [saveText, setSaveText] = useState('Saving to QBO...');

  const finishSave = () => {
    setIsSaving(false);
    setInventoryPartEdited(false);
    if (quickEditMode) {
      setQuickEditMode(false);
    } else if (window.location.href.match(/copy/)) {
      localStorage.removeItem('inventory.temp.part');
      const redirectUrl = popRedirect();
      if (redirectUrl && !redirectUrl.match(new RegExp(partState.Sku))) {
        clearRedirect();
        window.location.href = `/inventory?partId=${partState.Sku}`;
      }
    } else {
      redirect();
    }
  };

  const updateParent = async () => {
    const parentRecord = await firestore.collection(inventoryDbString).doc(partState.Parent?.Sku).get();
    const parentData = parentRecord.data() as IInventoryPart;
    const parentChildren = parentData.Children;
    const childSkus = parentChildren.filter((c: IInventoryChild) => c.sku !== partState.Sku).map((c) => c.sku);
    const childDocs = childSkus.length ? await Promise.all(childSkus.map((sku: string) => firestore.collection(inventoryDbString).doc(sku).get())) : { docs: [] };
    // @ts-ignore
    const childData = childSkus.length ? [partState, ...childDocs.map((d) => d.data())] : [partState];

    const quantityAvailable = childData.map((c: IInventoryPart) => {
      const factor = (c.Parent?.ParentPerChild || 1) >= 1 ? (c.Parent?.ParentPerChild || 1) : (1 / (c.Parent?.ParentPerChild || 1));
      return Math.round(c.QtyOnHand * factor);
    }).reduce((a, b) => a + b, 0);
    // average cost is determined solely by the average of the unit cost of each child, without respect to available quantity
    const averageCost = childData
      .map((c: IInventoryPart) => {
        const factor = (c.Parent?.ParentPerChild || 1) >= 1 ? (c.Parent?.ParentPerChild || 1) : (1 / (c.Parent?.ParentPerChild || 1));
        // @ts-ignore
        return c.PurchaseCost / factor;
      })
      .reduce((a, b) => a + b, 0) / childData.length;

    /*
      Enable this once we are saving/editing quantities in QBO
     */
    const qboParentPart = QBOItem.fromPart(parentData, partState.InvStartDate ? new Date(`${partState.InvStartDate}T07:00:00`) : new Date());
    await QBOItem.update({ ...qboParentPart, QtyOnHand: 0, PurchaseCost: parseFloat(averageCost.toFixed(2)) }) as IQBOItem;
    await firestore.collection(inventoryDbString).doc(parentData.Sku).update({ PrefVendorRef: null, QtyOnHand: quantityAvailable, PurchaseCost: parseFloat(averageCost.toFixed(2)) });
    // ensure the children have the parent's description
    await Promise.all(childData.map((c: IInventoryPart) => firestore.collection(inventoryDbString).doc(c.Sku).update({ Description: parentData.Description })));
  };
  const saveChild = async (inventoryState: IInventoryPart) => {
    const parentRecord = await firestore.collection(inventoryDbString).doc(inventoryState.Parent?.Sku).get();
    const parentData = parentRecord.data() || { Children: [] };
    const children = parentData.Children || [];
    const childIndex = findIndex(children, (c: { sku: string, id: string }) => c.sku === inventoryState.Sku);
    if (childIndex >= 0) return;
    const child = { sku: inventoryState.Sku, id: inventoryState.Id };
    await firestore.collection(inventoryDbString).doc(inventoryState.Parent?.Sku).update({ Children: [...children, child] });
  };

  const onSave = async (e: any) => {
    // Validation: part cannot be saved unless passing these checks
    if (partState.Description === '') {
      Modal.error({
        title: 'No Description!',
        content: 'All parts must have a description to be saved',
      });
      return;
    }
    if (partState.Sku === '') {
      Modal.error({
        title: 'No Part Number!',
        content: 'All parts must have a part number to be saved',
      });
      return;
    }
    if (partState.ProductCode.productCode === '') {
      Modal.error({
        title: 'No Product Code!',
        content: 'All parts must have a product code to be saved',
      });
      return;
    }
    // END VALIDATION

    setIsSaving(true);
    let updated;
    let qboRecord;

    // only save to QBO if we are not in test mode
    if (!useTestData && !isDevSite()) {
      try {
        setSaveText('Saving to QBO...');
        const quickbooksItem = await QBOItem.fromPart({
          ...partState,
          QtyOnHand: 0,
        }, partState.InvStartDate ? new Date(`${partState.InvStartDate}T07:00:00`) : new Date());

        qboRecord = await QBOItem.fetchByName(partState.Sku) as IQBOItem;

        if (!qboRecord) {
          updated = await QBOItem.createStubItem({ ...omit(quickbooksItem, ['Id']), QtyOnHand: 0 }) as IQBOItem;
        } else {
          updated = await QBOItem.update({
            ...omit(quickbooksItem, ['QtyOnHand']),
            QtyOnHand: qboRecord.QtyOnHand || 0,
          }) as IQBOItem;

          /*
          * DEPRECATED: WE ARE NO LONGER UPDATING QBO INVENTORY DATA FROM HELM -- KH 1/9/2025

            const inventoryAdjustment = await QBOInventoryAdjustment.itemAdjustmentFromParts(
              [{ ...quickbooksItem, adjustmentQuantity: quickbooksItem.QtyOnHand - (qboRecord.QtyOnHand || 0) } as IQuantityAdjustmentItem],
              null,
              partState.InvStartDate ? new Date(partState.InvStartDate) : new Date(),
              `${quickbooksItem.Sku} - quantity updated manually by ${currentUser.email.split('@')[0]}`,
            ) as IItemAdjustment;

            try {
              await QBOInventoryAdjustment.create(inventoryAdjustment);
            } catch (adjustmentError) {
              if (adjustmentError instanceof QBOError) {
                const shouldRetry = await handleQBOError(adjustmentError);
                if (shouldRetry) {
                  return onSave(e);
                }
                // User chose to skip QBO adjustment - continue with save
              } else {
                throw adjustmentError;
            }
          */
        }
      } catch (error) {
        if (error instanceof QBOError) {
          const shouldRetry = await handleQBOError(error);
          if (shouldRetry) {
            return onSave(e);
          }
          // User chose to skip QBO - continue with Firestore save
        } else {
          throw error;
        }
      }
    }

    setSaveText('Saving to HELM...');
    await firestore.collection(inventoryDbString).doc(partState.Sku).set({
      ...partState,
      SyncToken: updated?.SyncToken || partState.SyncToken || '0',
      Id: updated?.Id || partState.Id || '',
      MetaData: { ...partState.MetaData, LastUpdatedTime: qboDateString(new Date(), true) },
    });

    if (partState.QtyOnHand <= partState.reorderQty && partState.reorderQty > 0) {
      // send message to Purchasing group
      reorderPointNotification(database, messageDbString, reorderItemNotificationUsers, partState);
    }

    if (partState.Parent) {
      await saveChild({ ...partState, Id: qboRecord?.Id || '' });
      await updateParent();
      finishSave();
    } else if (partState.Children && partState.Children.length > 0) {
      // if the part has children, update the description of each child to match the parent
      await Promise.all(partState.Children.map((c) => firestore.collection(inventoryDbString).doc(c.sku).update({ Description: partState.Description })));
      finishSave();
    } else {
      finishSave();
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerWrapper>
          <SaveSpinner />
          <SaveText>{saveText}</SaveText>
        </SpinnerWrapper>
      ) : (
        <SaveButton id="inventory-record-save-button" type="primary" onClick={onSave} test={useTestData}>Save</SaveButton>
      )}
    </>
  );
};
