import { useRecoilState, useRecoilValue } from 'recoil';
import React, { useEffect, useState } from 'react';
import { Popconfirm } from 'antd';
import {
  LeftOutlined, QuestionCircleFilled,
} from '@ant-design/icons';

import { ListPriceLabel } from 'shared/pageElements/styledComponents';
import {
  bodyTotal, formatPrice, neckTotal,
} from 'shared/data';
import {
  documentSavingProgressAtom, partEditModeAtom, useTestDataAtom, customerPartAtom,
} from 'shared/state/pricingState';
import theme from 'shared/theme';
import { ItemDetailBackButton } from 'shared/styledComponents/inputs';
import { PriceDiscount, TotalPrice } from 'shared/styledComponents/typographicElements';
import { currentCustomerAtom } from 'shared/state/customerState';
import { PART_VIEWER_COLLECTION, partBomItemsAtom } from 'shared/state/partViewState';
import { redirect } from 'shared/util';
import { ICustomerPart } from 'shared/types/parts';
import {
  NewItemHeader, NewItemHeaderLabel, EditRecordButton,
} from './styledComponents';
import SaveRecordButton from './Buttons/SaveRecordButton';
import ScopedComponent from '../../../shared/components/Utility/ScopedComponent';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from '../../../shared/state/routingState';
import CopyPartDataButton from './Buttons/CopyPartDataButton';

interface ComponentInterface {
  partType: 'body'|'neck';
  partId: any;
  copy: any;
  partState: any;
  customerParts: ICustomerPart[];
}

export default ({
  partType, partId, partState, copy, customerParts,
}: ComponentInterface) => {
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  // @ts-ignore
  // const partState = useRecoilValue(PART_STATE_ATOM[partType]);
  const _partState = useRecoilValue<ICustomerPart>(customerPartAtom);
  const bomItems = useRecoilValue(partBomItemsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const useTestData = useRecoilValue(useTestDataAtom);
  const [partEditMode, setPartEditMode] = useRecoilState(partEditModeAtom);
  const partCollectionDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const isSaving = useRecoilValue(documentSavingProgressAtom);
  const isDev = !!partCollectionDbString.match(/test/i);
  const totalFunction = partType.match(/body/i) ? bodyTotal : neckTotal;
  const discount = partType.match(/body/i) ? currentCustomer.bodyDiscount : currentCustomer.neckDiscount;
  const [total, setTotal] = useState<number>(0);
  const [_customerParts, setCustomerParts] = useState<ICustomerPart[]>(customerParts);
  const [discountText, setDiscountText] = useState<string>('');
  const [newPrice, setNewPrice] = useState<number>(0);
  const [customerDiscount, setCustomerDiscount] = useState<string>('');

  const onBack = (e: any) => {
    e.preventDefault();
    if (currentCustomer.id) {
      localStorage.setItem('currentCustomerId', currentCustomer.id);
    }
    if (sessionStorage.getItem('redirectUrl')) {
      redirect();
    } else {
      window.location.href = `/pricing?partId=${_partState.Sku}`;
    }
  };

  const togglePartEditMode = (e: any) => {
    e.preventDefault();
    setPartEditMode(true);
  };

  useEffect(() => {
    if (_partState.Sku.length > 0) {
      const _total = totalFunction(_partState) as any;
      const basePrice = _total.price + _total.discount;
      setTotal(basePrice);
      const _customerDiscount = ['GB', 'BB'].includes(_partState.type) ? currentCustomer.bodyDiscount : currentCustomer.neckDiscount;
      const _discountText = _customerDiscount > 0 ? 'increased' : 'discounted';
      setDiscountText(_discountText);
      setNewPrice(Math.round(_total.price * (1 + (_customerDiscount / 100)) + _total.discount));
      setCustomerDiscount(`${_discountText} ${Math.abs(_customerDiscount).toFixed(2)}% from 
        ${formatPrice(Math.floor(_total.price + _total.discount))}`);
    }
  }, [_partState]);

  useEffect(() => {
    setCustomerParts(customerParts);
  }, [customerParts]);

  return (
    <NewItemHeader testMode={useTestData}>
      { (!partEditMode && !isSaving) ? (
        <>
          <ItemDetailBackButton
            shape="circle"
            type="primary"
              // @ts-ignore
            icon={<LeftOutlined />}
            onClick={onBack}
            testMode={useTestData}
          />
          <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
            <EditRecordButton type="primary" onClick={togglePartEditMode} testMode={useTestData}>Edit</EditRecordButton>
          </ScopedComponent>
        </>
      ) : (
        <>
          <Popconfirm
            title="Discard unsaved changes?"
            onConfirm={onBack}
            okText="Yes"
            cancelText="No"
            placement="bottomLeft"
            icon={(
              // @ts-ignore
              <QuestionCircleFilled style={{ color: theme.palette.warning.hue }} />
            )}
          >
            <ItemDetailBackButton
              shape="circle"
              type="primary"
              // @ts-ignore
              icon={<LeftOutlined />}
              disabled={isSaving}
            />
          </Popconfirm>
          <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
            <SaveRecordButton partType={partType} price={newPrice} />
          </ScopedComponent>
        </>
      )}
      <ScopedComponent whitelist={[...superAdminEmails.emails]}>
        <CopyPartDataButton customerParts={_customerParts} editMode={partEditMode} isSaving={isSaving} />
      </ScopedComponent>
      {!partId
        && <NewItemHeaderLabel title={`New ${partType === 'body' ? 'Body' : 'Neck'}`} />}
      <ListPriceLabel>List price</ListPriceLabel>
      <TotalPrice>{formatPrice(newPrice)}</TotalPrice>
      {discount !== 0 && <PriceDiscount>{customerDiscount}</PriceDiscount>}
    </NewItemHeader>
  );
};
