import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { findIndex, sortBy } from 'lodash';
import { formatPercent, formatPrice } from 'shared/data';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { bomEditedAtom, PART_BOM_COLLECTION } from 'shared/state/partViewState';
import { partEditModeAtom } from 'shared/state/pricingState';
import type { IBomItem, IInventoryPart } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import BomItem from './BomItem';
import AddBomItemButton from '../Buttons/AddBomItemButton';
import CopyBomButton from '../Buttons/CopyBomButton';
import RefreshBomDetailsButton from '../Buttons/RefreshBomDetailsButton';

interface IComponent {
  open: boolean
  partType: string
  partId: string
  partDescription: string
  partPrice: number
  bom?: IBomItem[] | null
  onBomChange: (bomItems: IBomItem[]) => void
}

const ComponentWrapper = styled.div`
  width: 100%;
`;

const BomHeader = styled.div`
  margin-bottom: 24px;
  background: #f8fafc;
  padding-top: 24px;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const BomTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BomTitleText = styled.h4`
  font-size: 14px;
  font-weight: 600;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0;
`;

const BomCost = styled.div`
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  color: #374151;
  
  span {
    color: #6b7280;
    margin-left: 4px;
  }
`;

const BomActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;

const BomListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const BomItemWrapper = styled.div`
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;

  &:hover {
    border-color: #d1d5db;
  }
`;

const BomItemHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const BomItemTitle = styled.div`
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #111827;
    margin: 0 0 4px 0;
  }
  
  p {
    font-size: 13px;
    color: #6b7280;
    margin: 0;
  }
`;

const BomItemActions = styled.div`
  display: flex;
  gap: 8px;
`;

const BomPlaceholder = styled.div`
  background: #fff;
  border: 1px dashed #e5e7eb;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  color: #6b7280;
`;

const ActionButtonRow = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
  border-top: 1px solid #e5e7eb;
`;

const ItemContainer = styled.div`
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const ItemId = styled.div`
  font-weight: 500;
  color: #374151;
`;

const ItemDescription = styled.div`
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 16px;
  line-height: 1.4;
  word-break: break-word;
`;

const ItemFooter = styled.div`
  display: flex;
  gap: 8px;
`;

const PartBomList: React.FC<IComponent> = ({
  open, partType, partId, partDescription, partPrice, bom = null, onBomChange,
}) => {
  const { firestore } = useFirebase();
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalSell, setTotalSell] = useState<number>(0);
  const [description, setDescription] = useState<string>(partDescription);
  const [bomItems, setBomItems] = useState<IBomItem[]>(bom || []);
  const partBomDbCollection = useRecoilValue(PART_BOM_COLLECTION);
  const partEditMode = useRecoilValue(partEditModeAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);

  const onRefreshParts = (parts: IBomItem[]) => {
    setBomItems(parts);
  };

  const onChangeBomItem = (bomItem: IBomItem, oldSku: string) => {
    setBomEdited(true);
    const updatedItems = [...bomItems];
    const index = findIndex(bomItems, (i: IBomItem) => i.Sku === bomItem.Sku || i.Sku === oldSku);

    if (index > -1) {
      updatedItems[index] = bomItem;
      setBomItems(updatedItems);
    } else {
      updatedItems[updatedItems.length - 1] = bomItem;
    }
    onBomChange(updatedItems);
  };

  const onAddBomItem = (bomItem: IBomItem) => {
    setBomEdited(true);
    setBomItems([...bomItems, bomItem]);
    onBomChange([...bomItems, bomItem]);
  };

  const onDeleteBomItem = (sku: string) => {
    setBomEdited(true);
    const updatedItems = bomItems.filter((item) => item.Sku !== sku);
    setBomItems(updatedItems);
    onBomChange(updatedItems);
  };

  useEffect(() => {
    if (!partId || partId.length === 0) return;
    const cost = bomItems.map((item) => item.unitCost).reduce((a, b) => a + b, 0);
    setTotalCost(cost);
    const sell = bomItems.map((item) => item.unitPrice).reduce((a, b) => a + b, 0);
    setTotalSell(sell);
  }, [bomItems]);

  useEffect(() => {
    if (!partId || partId.length === 0 || bomItems.length > 0) return;
    firestore.collection(partBomDbCollection).doc(partId).get().then((doc) => {
      if (doc.exists) {
        setBomItems(doc.data()?.bom || []);
      }
    });
  }, [partId]);

  return (
    <ComponentWrapper>
      <BomHeader>
        <BomTitle>
          <BomTitleText>Bill of Materials</BomTitleText>
          <ActionButtonRow>
            {partEditMode && open && (
              <>
                <AddBomItemButton onAddBomItem={onAddBomItem} />
                <CopyBomButton partDescription={description} />
              </>
            )}
            <RefreshBomDetailsButton partType={partType} partId={partId} callback={onRefreshParts} />
          </ActionButtonRow>
        </BomTitle>

        <BomCost>
          Cost:
          {' '}
          {formatPrice(totalCost)}
          <span>
            (
            {formatPercent(totalCost / partPrice, 2)}
            )
          </span>
        </BomCost>
      </BomHeader>

      <BomListWrapper>
        {bomItems.length > 0 ? (
          sortBy(bomItems, (item) => item.Sku).map((item) => (
            <BomItemWrapper key={`bom-item-${item.Sku}`}>
              <BomItem
                id={item.Sku}
                description={item.Description}
                quantity={item.quantity.toString()}
                onDelete={() => onDeleteBomItem(item.Sku)}
                editCallback={(quantity: number) => { onChangeBomItem({ ...item, quantity }, item.Sku); }}
                partChangeCallback={(part: IInventoryPart) => {
                  onChangeBomItem({
                    ...item,
                    Description: part.Description,
                    description: part.Description,
                    unitCost: part.PurchaseCost,
                    unitPrice: part.UnitPrice,
                    totalCost: part.PurchaseCost * item.quantity,
                    totalPrice: part.UnitPrice * item.quantity,
                    Sku: part.Sku,
                    quantity: item.quantity,
                    unit: part.PurchasingUnit,
                    stepId: part.routerStep,
                  }, item.Sku);
                }}
              />
            </BomItemWrapper>
          ))
        ) : (
          <BomPlaceholder>No BOM items to display</BomPlaceholder>
        )}
      </BomListWrapper>

    </ComponentWrapper>
  );
};

export default PartBomList;
