import React from 'react';
import {
  StyleSheet, View, Text, Image,
} from '@react-pdf/renderer';
import { ICustomerRecord } from 'shared/types/dbRecords';

const style = StyleSheet.create({
  headerWrapper: {
    width: '98%',
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginTop: -4,
  },
  contactColumn: {
    width: 200,
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginLeft: 10,
  },
  contactBlock: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    top: -20,
    left: -24,
  },
  contactInfo: {
    fontFamily: 'Red Hat Display',
    fontSize: 8,
    margin: 0,
  },
  confirmationDetailColumn: {
    flexDirection: 'column',
    width: 'auto',
    height: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    textAlign: 'right',
    paddingTop: 12,
  },
  pageTitle: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 20,
  },
  orderDescription: {
    fontFamily: 'Red Hat Display',
    fontSize: 9,
    marginTop: -2,
  },
  orderDetailRow: {
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'right',
    marginTop: 6,
  },
  orderDetailTypeColumn: {
    width: '60%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 6,
  },
  orderDetailValueColumn: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  orderDetailType: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 10,
    margin: 0,
  },
  orderDetailValue: {
    fontFamily: 'Red Hat Display',
    fontSize: 10,
    margin: 0,
  },
});

interface IComponent {
  customer: ICustomerRecord;
}

const PriceListHeader = ({ customer }: IComponent) => (
  <View fixed style={style.headerWrapper}>
    <View style={style.contactColumn}>
      <Image src="/images/horizontal_lockup.jpg" width={188} height={72} />
      <View style={style.contactBlock}>
        <Text style={style.contactInfo}>1055 Samoa Blvd.</Text>
        <Text style={style.contactInfo}>Arcata, CA 95521</Text>
        <Text style={style.contactInfo}>Phone: +1 (707) 822-9541</Text>
      </View>
    </View>
    <View style={style.confirmationDetailColumn}>
      <Text style={style.pageTitle}>Price List</Text>
      <Text style={style.orderDescription}>{customer.CompanyName}</Text>
      <View style={style.orderDetailRow}>
        <View style={style.orderDetailTypeColumn}>
          <Text style={style.orderDetailType}>Printed:</Text>
        </View>
        <View style={style.orderDetailValueColumn}>
          <Text style={style.orderDetailValue}>{new Date().toLocaleDateString()}</Text>
        </View>
      </View>
    </View>
  </View>
);

export default PriceListHeader;
