import React, { useState } from 'react';
import { Upload, Card, Typography } from 'antd';
import type { UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

const { Title } = Typography;

interface ToolInfo {
  name: string
  isEmpty: boolean
  position: number
  offset: number
  order: number
}

type ToolMap = {
  [key: string]: ToolInfo
};

// Styled Components
const Container = styled(FlexColumn)`
  width: 100%;
  margin: 5rem auto;
  padding: 0 1.5rem;
  @media ${theme.device.laptopM} {
    max-width: 50%;
    margin: 2rem auto;
  };
`;

const StyledCardWrapper = styled.div`
  width: 100%;
  .ant-card {
    background: #ffffff;
    border: none;
    border-radius: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 
                0 2px 4px rgba(0, 0, 0, 0.04), 
                0 4px 8px rgba(0, 0, 0, 0.04),
                0 8px 16px rgba(0, 0, 0, 0.04);
    height: 100%;
    
    .ant-card-head {
      border-bottom: none;
      padding: 24px 32px 0;
      
      .ant-card-head-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #111827;
        padding: 0;
      }
    }
    
    .ant-card-body {
      padding: 24px 32px;
    }
  }
  margin-bottom: 24px;
`;

const CardsContainer = styled(FlexColumn)`
  width: 100%;
  align-items: stretch;
  gap: 16px;
  
  @media ${theme.device.laptopM} {
    flex-direction: row;
    & > * {
      flex: 1;
    }
  };
`;

const ToolList = styled.div`
  display: grid;
  gap: 12px;
`;

const ToolItem = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: ${(props) => (props.isEmpty ? '#f9fafb' : '#f0f9ff')};
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

const ToolNumber = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: #6b7280;
  width: 48px;
`;

const ToolName = styled.div<{ isEmpty?: boolean }>`
  flex: 1;
  font-size: 1rem;
  font-weight: ${(props) => (props.isEmpty ? '400' : '500')};
  color: ${(props) => (props.isEmpty ? '#9ca3af' : theme.palette.primary.hue)};
`;

const ToolOffset = styled.div`
  padding: 4px 8px;
  background: #fff;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #f59e0b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const UploadCard = styled(Card)`
  height: ${(props) => (props.fullHeight ? '200px' : '100px')};
  .ant-upload-drag {
    border: 2px dashed #e5e7eb;
    border-radius: 12px;
    background: #f9fafb;
    padding: 32px;
    transition: all 0.2s ease;

    &:hover {
      border-color: #60a5fa;
      background: #f0f9ff;
    }
  }

  .ant-upload-text {
    font-size: 1.125rem;
    font-weight: 500;
    color: #111827;
    margin: 16px 0 8px;
  }

  .ant-upload-hint {
    font-size: 0.875rem;
    color: #6b7280;
  }
`;

const StyledUploadIcon = styled(UploadOutlined)`
  font-size: 2rem;
  color: #60a5fa;
`;

export default function NCFileAnalyzer() {
  const [toolLoad, setToolLoad] = useState<ToolMap>({});
  const [runOrder, setRunOrder] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>('');

  const extractFileName = (content: string): string => {
    const firstLines = content.split('\n').slice(0, 2);
    const fileNameMatch = firstLines[1]?.match(/O\d+\s*\([^)]+\)/);
    return fileNameMatch ? fileNameMatch[0] : '';
  };

  const processFileContent = (content: string) => {
    // Extract and set filename first
    setFileName(extractFileName(content));

    const lines = content.split('\n');
    const toolMap: ToolMap = {};
    const toolOrder: string[] = [];

    // Initialize all possible tool positions with default values
    for (let i = 1; i <= 12; i += 1) {
      const toolNum = `T${i}`;
      toolMap[toolNum] = {
        name: 'Empty',
        isEmpty: true,
        position: -1,
        offset: -1,
        order: -1,
      };
    }

    let order = 0;
    for (let i = 0; i < lines.length; i += 1) {
      const line = lines[i].trim();

      // Look for tool number in non-parenthetical line
      const toolMatch = line.match(/^N\d+\s+T(1[01][0-9])/);
      if (toolMatch) {
        const fullNumber = parseInt(toolMatch[1], 10);
        const position = fullNumber % 100;
        const toolNumber = `T${position}`;

        // Get description from first parenthetical line after a non-parenthetical line
        let description = '';
        for (let j = i - 1; j >= 0; j -= 1) {
          const prevLine = lines[j].trim();
          // Found a non-parenthetical line
          if (!prevLine.startsWith('(') || !prevLine.endsWith(')')) {
            // Take the next line (j + 1) as our description if it exists and is a parenthetical
            const descLine = lines[j + 1]?.trim();
            if (descLine && descLine.startsWith('(') && descLine.endsWith(')')) {
              description = descLine.slice(1, -1).trim();
            }
            break;
          }
        }

        // Look ahead for height offset
        let offset = position; // Default to position if not found
        for (let j = i + 1; j < lines.length && j <= i + 5; j += 1) {
          const nextLine = lines[j].trim();
          const offsetMatch = nextLine.match(/H(\d+)\s+Z/);
          if (offsetMatch) {
            offset = parseInt(offsetMatch[1], 10);
            break;
          }
        }

        // Check if this is a valid tool number (T1-T12)
        if (/^T(1[0-2]|[1-9])$/.test(toolNumber)) {
          toolMap[toolNumber] = {
            name: description,
            isEmpty: false,
            position,
            offset,
            order,
          };
          toolOrder.push(toolNumber);
          order += 1;
        }
      }
    }

    setToolLoad(toolMap);
    setRunOrder(toolOrder);
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        processFileContent(e.target.result as string);
      }
    };
    reader.readAsText(file);
    return false; // Prevent default upload behavior
  };

  const uploadProps: UploadProps = {
    accept: '*',
    beforeUpload: handleFileUpload,
    showUploadList: false,
  };

  const renderToolValue = (info: ToolInfo) => (
    <>
      <ToolName isEmpty={info.isEmpty}>{info.name}</ToolName>
      {!info.isEmpty && (
      <ToolOffset>
        H
        {info.offset}
      </ToolOffset>
      )}
    </>
  );

  return (
    <Container>
      <StyledCardWrapper>
        <UploadCard title="NC File Analyzer" fullHeight={!toolLoad}>
          <Upload.Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <StyledUploadIcon />
            </p>
            <p className="ant-upload-text">Select an .NC file to analyze its tool load</p>
            <p className="ant-upload-hint">Click or drag an NC file to this area to upload</p>
          </Upload.Dragger>
        </UploadCard>
      </StyledCardWrapper>

      {fileName && (
        <Title level={4} style={{ margin: '0 0 16px 0', color: '#111827' }}>
          {fileName}
        </Title>
      )}

      <CardsContainer>
        <StyledCardWrapper>
          <Card title="Tool Positions">
            <ToolList>
              {Object.entries(toolLoad)
                .sort(([a], [b]) => {
                  const numA = Number.parseInt(a.slice(1), 10);
                  const numB = Number.parseInt(b.slice(1), 10);
                  return numA - numB;
                })
                .map(([tool, info]) => (
                  <ToolItem key={tool} isEmpty={info.isEmpty}>
                    <ToolNumber>{tool}</ToolNumber>
                    {renderToolValue(info)}
                  </ToolItem>
                ))}
            </ToolList>
          </Card>
        </StyledCardWrapper>

        <StyledCardWrapper>
          <Card title="Run Order">
            <ToolList>
              {runOrder.map((tool) => (
                <ToolItem key={`run-${tool}`} isEmpty={toolLoad[tool].isEmpty}>
                  <ToolNumber>{tool}</ToolNumber>
                  {renderToolValue(toolLoad[tool])}
                </ToolItem>
              ))}
            </ToolList>
          </Card>
        </StyledCardWrapper>
      </CardsContainer>
    </Container>
  );
}
