import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { ICustomerPart } from 'shared/types/parts';
import PriceListPDF from '../PriceListPDF';

interface IComponent {
  customer: ICustomerRecord;
  customerParts: ICustomerPart[];
  showDrawer: boolean;
  onClose?: () => void;
}

const PriceListPDFDrawer = ({
  customer, customerParts, showDrawer, onClose,
}: IComponent) => {
  const [open, setOpen] = useState(showDrawer);

  const closeDrawer = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(showDrawer);
  }, [showDrawer]);

  return (
    <Drawer
      placement="right"
      width="78vw"
      open={open}
      onClose={closeDrawer}
      destroyOnClose
    >
      <PriceListPDF customer={customer} customerParts={customerParts} />
    </Drawer>
  );
};

export default PriceListPDFDrawer;
