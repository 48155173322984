import { ICustomerPart } from 'shared/types';
import {
  resolveWeight,
  resolveArchetype,
  resolveModel,
  resolveWood,
  resolveWeightReductionType,
  resolveSpreadDimensions,
} from 'shared/partParser/partResolver';

const AVG_MAPLE_DENSITY = 3.5;
const TOP_SOLID_PCT = 0.11;
const WR_VOLUME = {
  SH: 0.596,
  SLOT: 0.313,
  HC: 0.35,
  TL: 0.596,
};
interface DensityResult {
  density: number;
  volume: number;
  weight: number;
  isMapleTopAdjusted: boolean;
  spreadWeight: number;
}

const calculatePartDensity = (part: ICustomerPart): DensityResult | null => {
  if (!part) return null;
  const { spreadLength, spreadWidth, spreadThick } = resolveSpreadDimensions(part.config);
  const volume = part.volume;
  // @ts-ignore
  const weight = resolveWeight(part.config);

  if (!volume || !weight?.value) {
    return null;
  }

  const calculateTopPercentage = (coreVolume: number, wrVolume: number) => {
    const topSolidVolume = (coreVolume + wrVolume) * TOP_SOLID_PCT;
    const wRCoreVolume = coreVolume - topSolidVolume;
    return topSolidVolume / wRCoreVolume;
  };

  const weightReduction = resolveWeightReductionType(part.config);
  let weightReductionVolume = 0;

  if (weightReduction.text.match(/sh/i)) {
    weightReductionVolume = WR_VOLUME.SH;
  } else if (weightReduction.text.match(/hc/i)) {
    weightReductionVolume = WR_VOLUME.HC;
  } else if (weightReduction.text.match(/tl/i)) {
    weightReductionVolume = WR_VOLUME.TL;
  } else if (!weightReduction.text.match(/tl|hc|sh/i)) {
    weightReductionVolume = WR_VOLUME.SLOT;
  }

  const totalVolume = parseFloat(volume);
  const spreadVolume = (spreadLength * spreadThick * spreadWidth) / 144;
  let density = weight.value / totalVolume;
  const boardYield = parseFloat(volume) / spreadVolume;
  let isMapleTopAdjusted = false;

  const bodyTopped = resolveWood(part.config).top !== 'None';
  if (bodyTopped
    && resolveArchetype(part.config).text === 'GB'
    && resolveModel(part.config).text.match(/tl|tx|tele|t69|t72|ce|70s/i)
    && resolveWood(part.config).top?.match(/map|flame|flm|quilt|qlt/i)) {
    const topPercentage = calculateTopPercentage(totalVolume, weightReductionVolume);
    const topDensity = topPercentage * AVG_MAPLE_DENSITY;
    const corePercentage = 1 - topPercentage;
    density = (density - topDensity) / corePercentage;
    isMapleTopAdjusted = true;
  }

  return {
    density: Math.round(density * 100) / 100,
    volume: totalVolume,
    weight: weight.value,
    isMapleTopAdjusted,
    spreadWeight: weight.value / boardYield,
  };
};

export default calculatePartDensity;
