import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RAW_MATERIALS_COLLECTION, ROUTER_DATA_PATH, userSettingsAtom } from 'shared/state/siteState';
import {
  BODY_DATA_PATH,
  NECK_DATA_PATH,
  PRICING_DB_COLLECTION,
  useTestDataAtom,
} from 'shared/state/pricingState';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { PART_VIEWER_COLLECTION, pricedStatusDisplayAtom } from 'shared/state/partViewState';
import { CUSTOMERS_DB_COLLECTION } from 'shared/state/customerState';
import { INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import { resolveFirestorePath, resolveRealtimePath } from 'shared/util';
import BooleanSetting from './BooleanSetting';

interface IComponent {
  callback: (key: string, value: boolean, reloadRequired: boolean) => void;
}
const TestModeSwitch = ({ callback }: IComponent) => {
  const userSettings = useRecoilValue(userSettingsAtom);
  const [useTestData, setUseTestData] = useRecoilState(useTestDataAtom);
  const [skipTestModal, setSkipTestModal] = useState(!!localStorage.getItem('bypassTestModePrompt')?.match(/true/i));
  const [modalVisible, setModalVisible] = useState(false);
  const setOrdersDBString = useSetRecoilState(ORDERS_DB_COLLECTION);
  const setInventoryDBString = useSetRecoilState(INVENTORY_ITEMS_COLLECTION);
  const setPricingDBString = useSetRecoilState(PRICING_DB_COLLECTION);
  const setPartCollectionDBString = useSetRecoilState(PART_VIEWER_COLLECTION);
  const setCustomersDBString = useSetRecoilState(CUSTOMERS_DB_COLLECTION);
  const setRawMaterialsDBString = useSetRecoilState(RAW_MATERIALS_COLLECTION);
  const setBodyDataString = useSetRecoilState(BODY_DATA_PATH);
  const setNeckDataString = useSetRecoilState(NECK_DATA_PATH);
  const setRouterDataString = useSetRecoilState(ROUTER_DATA_PATH);
  const setPricedStatus = useSetRecoilState(pricedStatusDisplayAtom);
  const toggleTestData = (key: string, value: boolean, reloadRequired: boolean) => {
    const newState = value;
    // if we are going into test mode, restrict pricing tool to priced items
    if (newState) setPricedStatus('priced');

    setUseTestData(newState);
    localStorage.setItem('testData', newState.toString());

    const env = newState ? 'dev' : null;
    const realtimeEnv = newState ? 'Test' : null;

    setOrdersDBString(resolveFirestorePath('orders-dev', env));
    setInventoryDBString(resolveFirestorePath('inventory-items-dev', env));
    setPricingDBString(resolveFirestorePath('pricing-dev', env));
    setPartCollectionDBString(resolveFirestorePath('part-viewer-data-test', env));
    setCustomersDBString(resolveFirestorePath('customers-dev', env));
    setRawMaterialsDBString(resolveFirestorePath('rawMaterials-dev', env));
    setBodyDataString(resolveRealtimePath('body', realtimeEnv));
    setNeckDataString(resolveRealtimePath('neck', realtimeEnv));
    setRouterDataString(resolveRealtimePath('router', realtimeEnv));

    if (!skipTestModal) setModalVisible(true);
    callback(key, value, reloadRequired);
  };

  return (
    <BooleanSetting
      label="Use Test Data"
      helpMessage="Use test databases to simulate large changes, e.g., pricing updates"
      storageKey="boolean/useTestData"
      value={userSettings?.boolean?.useTestData || false}
      callback={toggleTestData}
      reloadRequired
    />
  );
};

export default TestModeSwitch;
