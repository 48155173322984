import React from 'react';
import { Popover, Tooltip } from 'antd';
import shortid from 'shortid';
import styled from 'styled-components';
import { includes, sortBy } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { FlexRow } from 'shared/containers/FlexContainer';
import { formatPercent, formatPrice, HOLD_STEPS } from 'shared/data';
import { numberWithCommas } from 'shared/text';
import theme from 'shared/theme';
import {
  dateBefore, dateString, workingDaysBefore,
} from 'shared/data/calendar';
import { stageRedirect } from 'shared/util';
import { IBomItem, INCRecord, IOrderItem } from 'shared/types/dbRecords';
import { orderItemsWithoutDevelopment, toppedOrBound } from 'shared/data/order';
import { devLog } from 'shared/util/logging';
import RunnerHistory from './RunnerHistory';
import {
  BackorderTag,
  CNCTag,
  CompoundRadiusTag,
  HoldTag,
  HouseSampleTag,
  OrderNumberTag,
  PrototypeTag,
  TiltbackTag,
  ToppedTag,
  WeightReducedTag,
  BlockAndBoundTag, WRSeverityBar, WRSeverityWrapper,
} from './ScheduleTags';
import {
  OrderShippedIndicator,
  OrderDescriptionWrapper,
  RouterHistoryIcon,
  RouterInfoIcon,
  RouterNcIcon,
  RouterNote,
  RouterTableHistoryButton,
  RouterTableInfoButton,
  RouterTDCopy,
  TagWrapper,
  WorkOrderReleasedCheck,
} from '../styledComponents';

import OnTimeDot from './ScheduledOrderRecord/OnTimeDot';
import SalesOrderActions from './ScheduledOrderRecord/SalesOrderActionRow';
import ViewJobWeekButton from './Buttons/ViewJobWeekButton';
import CustomerFilterButton from './Buttons/CustomerFilterButton';
import { IClientRunner, IRunner, IShopOrder } from '../../Orders/types';
import JumpToOrderButton from './Buttons/JumpToOrderButton';

const onRunnerClick = (runner: IClientRunner) => (e: any) => {
  e.preventDefault();
  stageRedirect(`/router#${runner.id}`);
  window.location.href = `/router/edit?shopOrderId=${runner.orderId}&runnerId=${runner.id}`;
};

const onNcClick = (record: any) => (e: any) => {
  const ncHistory = sortBy(record.nonConformanceHistory, (nc: INCRecord) => nc.dateRecorded);
  window.location.href = `/nc/report#${record.salesOrder}@${ncHistory[0].id}`;
};

const PartCustomerCellWrapper = styled(FlexRow)`
    align-items: center;
    justify-content: flex-start;
    scroll-margin-top: 240px;
`;

const OrderValueContainer = styled(FlexRow)`
    //position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 0px;
        top: -6px;
        width: 12px;
        height: 12px;
        background-color: ${(props: { materialPercent: number }) => (props.materialPercent > 0.4 ? 'green' : 'transparent')};
        clip-path: polygon(0 0,100% 100%, 100% 0);
    }
`;

export const CUSTOMER_COLUMN = {
  title: 'Customer',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.customer > b.customer) return 1;
      if (b.customer > a.customer) return -1;
      return 0;
    },
    multiple: 3,
  },
  render: (text: string, record: any) => (
    <FlexRow id={record.id} key={shortid.generate()} align="center" justify="space-between">
      <RouterTDCopy key={shortid.generate()}>{record.customer}</RouterTDCopy>
      {!!record.notes
            && (
            <Tooltip key={shortid.generate()} placement="topLeft" title={record.notes} overlayClassName="tooltip-blur" overlayStyle={{ maxWidth: 800 }}>
              <RouterNote key={shortid.generate()} />
            </Tooltip>
            )}
    </FlexRow>
  ),
  width: 150,
};

export const SALES_CUSTOMER_COLUMN = {
  ...CUSTOMER_COLUMN,
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.customer > b.customer) return 1;
      if (b.customer > a.customer) return -1;
      return 0;
    },
    multiple: 1,
  },
  render: (text: string, record: any) => {
    let borderBottom = '4px solid transparent';
    if (record.customer.id.match(/nashg/i)) borderBottom = '4px solid #FDD017';
    if (record.customer.id.match(/mjtel/i)) borderBottom = '4px solid deepskyblue';
    if (record.customer.id.match(/xotic/i)) borderBottom = '4px solid #8D38C9';
    if (record.customer.id.match(/fried/i)) borderBottom = '4px solid mediumspringgreen';
    if (record.customer.id.match(/proso/i)) borderBottom = '4px solid aquamarine';
    return ({
      props: {
        style: {
          // borderBottom: `2px solid ${backgroundColor}`,
        },
      },
      children:
  <PartCustomerCellWrapper id={record.recordId} key={shortid.generate()}>
    <FlexRow style={{ width: '100%', borderBottom, gap: 8 }} justify="center">
      {!window.location.pathname.match(/customer/) ? (
      // {(localStorage.getItem('salesOrderViewType') || 'customer')).match(/week/i) ? (
        <CustomerFilterButton record={record} />
      ) : (
        <RouterTDCopy className={record.customer.id} key={shortid.generate()}>{record.customer.id}</RouterTDCopy>
      )}
      {/* {record.nonConformanceHistory?.length && ( */}
      {/* <Tooltip title={`${record.nonConformanceHistory.length} non-conformance${record.nonConformanceHistory.length > 1 ? 's' : ''} on this order. Click to view.`} maxWidth={500}> */}
      {/*  <RouterNcIcon onClick={onNcClick(record)} /> */}
      {/* </Tooltip> */}
      {/* )} */}
    </FlexRow>
    {/* {!!record.notes */}
    {/* && ( */}
    {/* <Tooltip */}
    {/*  key={shortid.generate()} */}
    {/*  placement="topLeft" */}
    {/*  title={record.notes} */}
    {/*  overlayClassName="tooltip-blur" */}
    {/*  overlayStyle={{ maxWidth: 800 }} */}
    {/* > */}
    {/*  <RouterNote key={shortid.generate()} /> */}
    {/* </Tooltip> */}
    {/* )} */}
  </PartCustomerCellWrapper>,
    });
  },
  width: 100,
  // width: '8%',
  defaultSortOrder: 'ascend',
};

export const SALES_ORDER_COLUMN = {
  title: 'SO#',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.salesOrder > b.salesOrder) return 1;
      if (b.salesOrder > a.salesOrder) return -1;
      return 0;
    },
    multiple: 2,
  },
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children: <JumpToOrderButton key={shortid.generate()} record={record} />,
  }),
  width: '5%',
};

export const WORK_ORDER_COLUMN = {
  title: 'Work Order',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.runnerNumber > b.runnerNumber) return 1;
      if (b.runnerNumber > a.runnerNumber) return -1;
      return 0;
    },
    multiple: 1,
  },
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.runnerNumber}</RouterTDCopy>
  ),
  width: 120,
};

export const NC_COLUMN = {
  title: 'NC',
  dataIndex: 'non-conformance',
  render: (text: string, record: IShopOrder) => (
    <FlexRow style={{ width: '100%' }}>
      {record.nonConformanceHistory?.length ? (
        <Tooltip title={`${record.nonConformanceHistory.length} non-conformance${record.nonConformanceHistory.length > 1 ? 's' : ''} on this order. Click to view.`} maxWidth={500}>
          <RouterNcIcon style={{ right: 0 }} onClick={onNcClick(record)} />
        </Tooltip>
      ) : (
        <RouterTDCopy>--</RouterTDCopy>
      )}
    </FlexRow>
  ),
  responsive: ['md', 'lg'],
  width: 28,
};

export const UNITS_COLUMN = {
  title: 'Units',
  dataIndex: shortid.generate(),
  render: (text: string, record: IRunner) => {
    const units = record.parts.filter((p) => p.open && p.Sku.match(/^[A-Z]{5}/))
      .map((p) => p.quantityAssigned).reduce((a, b) => a + b, 0);
    return {
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children: <RouterTDCopy key={shortid.generate()}>{units}</RouterTDCopy>,
    };
  },
  responsive: ['md', 'lg'],
  width: 100,
};

const tagRegExp = (pattern: string, simple: boolean = false, flags: string = 'i'): RegExp => {
  if (simple) return new RegExp(pattern, flags);
  return new RegExp(`(all|[0-9]{1,2})\\s?x?\\s?${pattern}`, flags);
};

const tagMatchesAll = (tag: string, record: IShopOrder): string => tag.replace(/all/i, `${record.partCount} `);

const removeXAndWhitespace = (s: string): string => s.replace(/(\s?x\s?)/i, ' ');

const TAG_LABELS = {
  bind: {
    pattern: '(Binding|Bind|Bound|B&B)',
    label: 'BIND',
  },
  thinline: {
    pattern: '(TL|Thinline)',
    label: 'TL',
  },
  tilt: {
    pattern: '(TB|Tilt|Tiltback)',
    label: 'TILT',
  },
  wr: {
    pattern: '(W\\/?R)',
    label: 'W/R',
  },
  cr: {
    pattern: '(C\\/?R)',
    label: 'C/R',
  },
};

const generateTagFor = (tag: string, description: string, record: IShopOrder): { tag: string, match: string} | null => {
  if (!TAG_LABELS[tag]) return null;

  const { pattern, label } = TAG_LABELS[tag];
  let match = description.match(tagRegExp(pattern));

  if (!match) {
    match = description.match(tagRegExp(pattern, true));
    if (!match) return null;
    return { tag: label, match: match[0] };
  }

  let tagString = match[0].replace(tagRegExp(pattern, true, 'ig'), label);
  // let tagString = match[0].replace(tagRegExp(pattern, true, 'ig'), label);
  tagString = removeXAndWhitespace(tagString);
  tagString = tagMatchesAll(tagString, record);
  return { tag: tagString, match: match[0] };
};

export const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: shortid.generate(),
  render: (text: string, record: IShopOrder) => {
    const holdSteps = ['_ic83Hl5F', 'JRgTgTROh'];
    const tags = [];
    const heldRunners = record.runners?.map((r: IRunner) => r.step).map((step: string) => includes(holdSteps, step));
    let description = record.description;
    // const orderItems = useRecoilValue(salesOrderItemsAtom);
    // const recordOrderItems = find(orderItems, (i: { id: string, orderItems: IOrderItem[] }) => i.id === record.id);

    /*
      ANY ORDER TYPE TAGS
     */
    // MACHINE
    // if (record.machine === 'andi') {
    //   tags.push(<AndiTag>ANDI</AndiTag>);
    // } else if (record.machine === 'anni') {
    //   tags.push(<AnniTag>ANNI</AnniTag>);
    // } else {
    //   tags.push(<EmptyTag />);
    // }
    // tags.push(<MachineSelector key={`machine-selector-tag-${record.id}`} record={record} />);
    // ORDER NUMBER
    const orderNumberMatch = record.description.match(/^(#\s?[0-9-.]{1,4}\s?)+/);
    if (orderNumberMatch) {
      tags.push(<OrderNumberTag key={`order-number-tag-${record.id}`}>{orderNumberMatch[0].trim()}</OrderNumberTag>);
      // description = description.replace(orderNumberMatch[0], '');
    }
    // ON HOLD
    if (record.hold) {
      tags.push(
        <Tooltip
          title={record.holdNotes || ''}
          placement="left"
          trigger={['hover', 'click']}
          mouseEnterDelay={0.1}
          mouseLeaveDelay={0.1}
        >
          <HoldTag key={`hold-tag-${record.id}`}>HOLD</HoldTag>
        </Tooltip>,
      );
    }

    // BACKORDER
    try {
      if (record.salesOrder.match(/-BO/i) || record.description.match(/-BO/i) || record.description.match(/backorder/i)) {
        tags.push(<BackorderTag key={`backorder-tag-${record.id}`} className="backorder-text">B.O.</BackorderTag>);
        // description = description.replace(/backorder/i, '').replace(/B\.?O\.?/i, '');
      }
    } catch (e) {
      devLog('ProductionSchedule/DataColumns', 328, e);
    }
    // PROTOTYPE
    if (record.description.match(/prototype/i)) {
      tags.push(<PrototypeTag key={`prototype-tag-${record.id}`}>PROTO</PrototypeTag>);
      // description = description.replace(/prototype/i, '');
    }

    // HOUSE SAMPLE
    if (record.ihs) {
      tags.push(<HouseSampleTag key={`house-sample-tag-${record.id}`}>H.S.</HouseSampleTag>);
      // description = description.replace(/(make)?\s?h\.?s\.?/i, '');
    }

    // CNC ONLY
    if ((!record.finishing && record.orderItems?.length > 0) || description.match(/CNC\s?(only?)/i)) {
      tags.push(<CNCTag key={`cnc-tag-${record.id}`}>CNC</CNCTag>);
      // description = description.replace(/CNC\s?(only)?/i, '');
    }

    /*
      BODY ORDER TAGS
     */
    // TOPPED
    if (record.orderItems && toppedOrBound(record.orderItems) && record.type === 'body' && !record.description.match(/(TL|Thinline|W\/?R)/)) {
      const tagData = generateTagFor('top', description, record);
      if (tagData) {
        tags.push(<ToppedTag key={`topped-tag-${record.id}`}>TOP</ToppedTag>);
      }
    }

    // TOPPED BODIES
    if (record.orderItems && toppedOrBound(record.orderItems) && record.type === 'body') {
      const solidTopped = Math.min(record.weightReduction?.none || 0, record.partCount);
      const weightRelieved = Math.min((record.weightReduction?.carveTop || 0) + (record.weightReduction?.cavity || 0) + (record.weightReduction?.slot || 0) + (record.weightReduction?.honeycomb || 0), record.partCount);

      if (solidTopped > 0) {
        tags.push(<ToppedTag key={`topped-body-tag-${record.id}`}>{`${solidTopped} TOP`}</ToppedTag>);
        const tagData = generateTagFor('top', description, record);
        if (tagData && tagData.match) description = description.replace(tagData.match, '');
      }
      if (weightRelieved > 0) {
        const severityTag = (
          <WeightReducedTag key={`weight-reduced-tag-${record.id}`}>
            <span>
              {weightRelieved}
              {' '}
              W/R
            </span>
            <WRSeverityWrapper>
              <WRSeverityBar key={`weight-reduction-severity-4-${record.id}`} live={!!record.weightReduction.carveTop} />
              <WRSeverityBar key={`weight-reduction-severity-3-${record.id}`} live={!!record.weightReduction.honeycomb || !!record.weightReduction.carveTop} />
              <WRSeverityBar key={`weight-reduction-severity-2-${record.id}`} live={!!record.weightReduction.slot || !!record.weightReduction.honeycomb || !!record.weightReduction.carveTop} />
              <WRSeverityBar key={`weight-reduction-severity-1-${record.id}`} live={!!record.weightReduction.cavity || !!record.weightReduction.slot || !!record.weightReduction.honeycomb || !!record.weightReduction.carveTop} />
            </WRSeverityWrapper>
          </WeightReducedTag>
        );
        tags.push(severityTag);
        const tagData = generateTagFor('wr', description, record);
        if (tagData && tagData.match) description = description.replace(tagData.match, '');
      }
    }
    /*
      NECK ORDER TAGS
     */
    // BOUND
    if (record.toppedOrBound && record.type === 'neck') {
      const tagData = generateTagFor('bind', description, record);
      if (tagData) {
        tags.push(<BlockAndBoundTag key={`block-and-bound-tag-${record.id}`}>{tagData.tag}</BlockAndBoundTag>);
        description = description.replace(tagData.match, '');
      }
    }

    // TILT BACK
    if (description.match(/(TB|Tilt|Tiltback)\b/)) {
      const tagData = generateTagFor('tilt', description, record);
      if (tagData) {
        tags.push(<TiltbackTag key={`tiltback-tag-${record.id}`}>{tagData.tag}</TiltbackTag>);
        description = description.replace(tagData.match, '');
      }
    }

    // COMPOUND RADIUS
    if (record.description.match(/\bC\/?R\b/i)) {
      const tagData = generateTagFor('cr', description, record);
      if (tagData) {
        tags.push(<CompoundRadiusTag key={`compound-radius-tag-${record.id}`}>{tagData.tag}</CompoundRadiusTag>);
        description = description.replace(tagData.match, '');
      }
    }

    const orderDateMatcher = description.match(/([0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4})/);
    if (orderDateMatcher) {
      description = description.replace(/[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4}/, '');
    }

    description = description.replace(/[,-]/g, '');
    description = description.replace(/\s$/, '');

    return {
      props: {
        style: {
          textAlign: 'left',
        },
      },
      children:
  <OrderDescriptionWrapper>
    <TagWrapper>
      {tags.map((t) => t)}
    </TagWrapper>
    <RouterTDCopy>{description}</RouterTDCopy>
  </OrderDescriptionWrapper>,
    };
  },
  // responsive: ['md', 'lg'],
  // width: 456,
  // width: IS_MOBILE ? 600 : 800,
  width: '50%',
};

export const VALUE_COLUMN = {
  title: 'Value',
  dataIndex: shortid.generate(),
  render: (text: string, record: IRunner) => {
    const runnerValue = record.parts.map((p) => p.quantityAssigned * p.unitPrice).reduce((a, b) => a + b, 0);
    return (<RouterTDCopy key={shortid.generate()}>{`$${Math.round(runnerValue)}`}</RouterTDCopy>);
  },
  responsive: ['lg'],
  // width: 100,
  width: '5%',
};
export const ORDER_VALUE_COLUMN = {
  title: 'Value',
  dataIndex: 'orderValue',
  render: (text: string, record: any) => {
    const orderPartValue = (record.orderItems || [{ unitPrice: 0 }])
      .map((i: IOrderItem) => i.unitPrice * i.quantityOrdered)
      .reduce((a, b) => a + b, 0);
    const orderItemsBomCost = (record.orderItems || [{ bom: null }])
      .filter((i: IOrderItem) => (record.completed ? i.quantityShipped : i.quantityOpen) > 0)
      .map((i: IOrderItem) => {
        if (!i.bom || !i.bom.length) return 0;
        return i.bom.map((b: IBomItem) => {
          const bomItemCost = (b.quantity * b.unitCost) * (record.completed ? i.quantityShipped : i.quantityOrdered);
          return bomItemCost;
        }).reduce((a, b) => a + b, 0);
      }).reduce((a, b) => a + b, 0);
    const materialPercent = orderItemsBomCost / orderPartValue;
    return (
      <OrderValueContainer materialPercent={materialPercent}>
        <Tooltip placement="right" title={`Material cost: ${formatPrice(orderItemsBomCost)} (${formatPercent(orderItemsBomCost / (orderPartValue || orderItemsBomCost), 2)})`}>
          <RouterTDCopy completed={record.completed} key={shortid.generate()}>{`$${Math.round(record.orderValue, 0)}`}</RouterTDCopy>
        </Tooltip>
      </OrderValueContainer>
    );
  },
  responsive: ['md', 'lg'],
  // width: 100,
  width: '5%',
};

export const SHIP_VALUE_COLUMN = {
  title: '',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'left',
        borderTop: '1px solid black',
        backgroundColor: record.actual ? 'lightyellow' : 'transparent',
        padding: '8px 8px',
      },
    },
    children:
    // <FlexRow justify="center">
    //   <FlexRow justify="flex-end" style={{ width: 280, marginRight: 12 }}>
    //     <RouterTDCopy completed={record.actual} key={shortid.generate()}>{record.label}</RouterTDCopy>
    //   </FlexRow>
  <FlexRow justify="space-between">
    <RouterTDCopy completed key={shortid.generate()}>{ReactHtmlParser(`<code>$${numberWithCommas(record.value)}</code>`)}</RouterTDCopy>
    <RouterTDCopy>{ReactHtmlParser(`<code>($${numberWithCommas(record.averageValue)}/${record.averageUnit})</code>`)}</RouterTDCopy>
  </FlexRow>,
  // </FlexRow>,
  }),
  // width: 250,
};
export const SHIPPED_DAILY_VALUE_COLUMN = {
  ...ORDER_VALUE_COLUMN,
  title: '',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'left',
        padding: '8px 8px',
      },
    },
    children: <RouterTDCopy completed key={shortid.generate()}>{`$${numberWithCommas(record.dailyValue)} / day`}</RouterTDCopy>,
  }),
};

export const MONTH_VALUE_LABEL_COLUMN = {
  title: '',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children: <RouterTDCopy completed key={shortid.generate()}>{`${record.totalType} ${record.partType} ${record.label}`}</RouterTDCopy>,
  }),
  width: 600,
};

export const SHIP_DATE_COLUMN = {
  title: 'Ship',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.shipDate > b.shipDate) return 1;
      if (b.shipDate > a.shipDate) return -1;
      return 0;
    },
    multiple: window.location.href.match(/schedule/) ? 3 : 1,
  },
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.shipDate}</RouterTDCopy>
  ),
  // width: 100,
  width: '5%',
};

export const ROUTER_SHIP_DATE_COLUMN = {
  ...SHIP_DATE_COLUMN,
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.shipDate > b.shipDate) return 1;
      if (b.shipDate > a.shipDate) return -1;
      if (a.shipDate === b.shipDate) {
        if (a.originalShipDate > b.originalShipDate) return 1;
        if (a.originalShipDate < b.originalShipDate) return -1;
      }
      return 0;
    },
    multiple: 2,
  },
  defaultSortOrder: 'ascend',
};

export const SALES_SHIP_DATE_COLUMN = {
  ...SHIP_DATE_COLUMN,
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => {
    const tooltipCopy = record.shipDateHistory?.length > 1
      ? `Original ship date: ${record.shipDateHistory[0].toDate().toLocaleDateString()}`
      : 'Order originally scheduled for date shown';
    return (
      <Tooltip placement="right" title={tooltipCopy}>
        <RouterTDCopy completed={record.completed} key={shortid.generate()}>{record.dates.shipDate}</RouterTDCopy>
      </Tooltip>
    );
  },
  defaultSortOrder: 'ascend',
};

export const SALES_ORDER_DATE_COLUMN = {
  ...SHIP_DATE_COLUMN,
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IShopOrder, b: IShopOrder) => {
      if (a.orderDate > b.orderDate) return 1;
      if (b.orderDate > a.orderDate) return -1;
      return 0;
    },
    multiple: window.location.href.match(/schedule/) ? 1 : 3,
  },
  title: 'Ordered',
  render: (text: string, record: any) => {
    const orderDate = record.dates.orderDate === '12/21/20' ? '--/--/--' : record.dates.orderDate;
    if (record.completed) {
      const timeToShip = Math.floor((record.shipDate.toDate() - record.orderDate) / 86400000);

      return (
        <Tooltip placement="top" title={`Shipped ${timeToShip} days after order`}>
          <RouterTDCopy key={shortid.generate()}>{orderDate}</RouterTDCopy>
        </Tooltip>
      );
    }
    return <RouterTDCopy key={shortid.generate()}>{orderDate}</RouterTDCopy>;
  },
  defaultSortOrder: 'ascend',
  width: '5%',
};

export const SALES_ORDER_PO_COLUMN = {
  title: 'PO',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.purchaseOrder}</RouterTDCopy>
  ),
  width: 150,
  responsive: ['l', 'xl'],
};

export const COMPLETION_DATE_COLUMN = {
  title: 'Est. Complete',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.shipDate > b.shipDate) return 1;
      if (b.shipDate > a.shipDate) return -1;
      return 0;
    },
    multiple: 1,
  },
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{includes(HOLD_STEPS, record.step) ? '--' : record.actualShip}</RouterTDCopy>
  ),
  width: 100,
  responsive: ['xl'],
};

export const SHIPPED_DATE_COLUMN = {
  title: 'Shipped',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: any, b: any) => {
      if (a.completedDate > b.completedDate) return 1;
      if (a.completedDate < b.completedDate) return -1;
      return 0;
    },
    multiple: 1,
  },
  render: (text: string, record: any) => (<RouterTDCopy key={shortid.generate()}>{record.shipDate}</RouterTDCopy>),
  width: 100,
};

export const ACTIONS_COLUMN = {
  title: '',
  key: 'actions',
  render: (text: string, record: any) => (
    <FlexRow justify="flex-end" style={{ paddingRight: 24 }} key={shortid.generate()}>
      <Popover content={<RunnerHistory runner={record} />}>
        <RouterTableHistoryButton key={shortid.generate()} onClick={() => {}}><RouterHistoryIcon /></RouterTableHistoryButton>
      </Popover>
      <Tooltip placement="top" title="View/Edit">
        <RouterTableInfoButton key={shortid.generate()} onClick={onRunnerClick(record)}><RouterInfoIcon /></RouterTableInfoButton>
      </Tooltip>
    </FlexRow>
  ),
  width: 'auto',
};

export const SALES_ORDER_ACTIONS_COLUMN = {
  title: '',
  key: 'actions',
  render: (text: string, record: any) => ({
    props: {
      // style: {
      //   paddingTop: 4,
      //   paddingBottom: 4,
      // },
    },
    children: <SalesOrderActions shopOrder={record} />,
  }),
  // width: 124,
  width: '5%',
};

export const BODY_UNITS_COLUMN = {
  title: 'Units',
  dataIndex: 'partCount',
  render: (text: string, record: IRunner) => {
    const partCount = record.parts.filter((p) => p.Sku.match(/[A-Z]{5}/))
      .map((p) => p.quantityAssigned)
      .reduce((a, b) => a + b, 0);
    return (
      <>
        <FlexRow key={shortid.generate()} justify="center">
          {partCount > 0
            && <RouterTDCopy key={shortid.generate()}>{partCount}</RouterTDCopy>}
        </FlexRow>

      </>
    );
  },
  sorter: {
    multiple: 1,
  },
  responsive: ['sm', 'md', 'lg'],
  width: '5%',
  // width: 100,
};

export const HOLD_COLUMN = {
  ...COMPLETION_DATE_COLUMN,
  render: () => (
    <RouterTDCopy key={shortid.generate()}>--</RouterTDCopy>
  ),
};
export const RELEASE_DATE_COLUMN = {
  title: 'Release',
  key: 'releaseDate',
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{`${record.releaseDate.getMonth() + 1}/${record.releaseDate.getDate()}`}</RouterTDCopy>
  ),
  sorter: {
    compare: (a: any, b: any) => {
      if (a.releaseDate > b.releaseDate) return 1;
      if (b.releaseDate > a.releaseDate) return -1;
      return 0;
    },
    multiple: 1,
  },
  defaultSortOrder: 'ascend',
  width: '5%',
  // width: 100,
};
export const SALES_RELEASE_DATE_COLUMN = {
  title: 'Release',
  key: 'releaseDate',
  render: (text: string, record: any) => {
    let backgroundColor = 'transparent';
    let color = theme.palette.neutral.black;
    let fontWeight = 400;
    let tooltipText = '';

    const releaseDate = record.releaseDate || new Date();
    releaseDate.setHours(0, 0, 0);
    const releaseDateString = releaseDate.toLocaleDateString();
    const today = new Date();
    today.setHours(0, 0, 0);

    // @ts-ignore
    const releaseDelta = Math.round((releaseDate - today) / 86400000);
    if (!record.completed) {
      if (record.releaseConfirmed || record.materialsConfirmed) {
        tooltipText = `Order confirmed: ${record.releaseConfirmed ? 'YES' : 'NO'}  --  Materials confirmed: ${record.materialsConfirmed ? 'YES' : 'NO'}`;
        backgroundColor = theme.palette.warning.hue;
        color = theme.palette.neutral.white;
      }
      // if the order has been confirmed, mark the release date blue
      if (record.releaseConfirmed && record.materialsConfirmed && !record?.runners?.length) {
        // if (dateString(new Date()) === dateString(record.releaseDate) && record.releaseConfirmed && !record.runners) {
        backgroundColor = theme.palette.success.hue;
        color = theme.palette.neutral.white;
        // if it's within five days of release and we haven't confirmed release, flag it.
      } else if (releaseDelta <= 5
        && (!record.releaseConfirmed || !record.materialsConfirmed)
        && !record.runners) {
        // backgroundColor = theme.palette.warning[900];
        color = theme.palette.warning.D100;
        fontWeight = 'bolder';
        // if it's past the due date, and confirmed, flag it red.
      } else if (dateBefore(record.releaseDate, new Date()) && record.releaseConfirmed && !record.runners) {
        backgroundColor = theme.palette.error.hue;
        color = theme.palette.neutral.white;
      }
    }
    return ({
      props: {
        style: {
          color,
          textAlign: 'center',
          fontWeight,
        },
      },
      children:
  <>
    { (record.runners?.length || record.completed)
      ? (
        <Tooltip key={shortid.generate()} placement="top" title={releaseDateString}>
          <WorkOrderReleasedCheck />
        </Tooltip>
      )
      : (
        <>
          {tooltipText.length > 0 ? (
            <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" key={shortid.generate()} title={tooltipText}>
              <RouterTDCopy style={{ fontWeight, backgroundColor, color }} key={shortid.generate()}>{record.dates.releaseDate}</RouterTDCopy>
            </Tooltip>
          ) : (
            <RouterTDCopy style={{ fontWeight, backgroundColor, color }} key={shortid.generate()}>{record.dates.releaseDate}</RouterTDCopy>
          )}
        </>
      )}
  </>,
    });
  },
  sorter: {
    compare: (a: any, b: any) => {
      if (a.releaseDate > b.releaseDate) return 1;
      if (b.releaseDate > a.releaseDate) return -1;
      return 0;
    },
    multiple: localStorage.getItem('salesOrderViewType') === 'soon' ? 5 : 1,
  },
  defaultSortOrder: 'ascend',
  // width: 100,
  width: '5%',
};

export const DRY_ROOM_DAYS_COLUMN = {
  title: 'Days left',
  key: 'dryRoomDay',
  sorter: {
    compare: (a: any, b: any) => {
      if (a.dryRoomDay > b.dryRoomDay) return 1;
      if (a.dryRoomDay < b.dryRoomDay) return -1;
      return 0;
    },
  },
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.dryRoomDay}</RouterTDCopy>
  ),
  responsive: ['md', 'lg'],
};

export const JOB_TYPE_COLUMN = {
  title: 'Type',
  dataIndex: 'type',
  sorter: {
    compare: (a: any, b: any) => {
      if (a.type > b.type) return 1;
      if (a.type < b.type) return -1;
      return 0;
    },
    multiple: 2,
  },
  render: (text: string, record: any) => {
    const jobType = record.type.match(/body/i) ? 'B' : 'N';
    return (
      <RouterTDCopy key={shortid.generate()}>{jobType}</RouterTDCopy>
    );
  },
  width: '5%',
};

export const PART_COUNT_COLUMN = {
  title: 'Units',
  dataIndex: 'partCount',
  sorter: {
    compare: (a: any, b: any) => {
      if (a.partCount > b.partCount) return 1;
      if (a.partCount < b.partCount) return -1;
      return 0;
    },
    multiple: 2,
  },
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.partCount}</RouterTDCopy>
  ),
  width: '5%',
};

export const LOCATION_COLUMN = {
  title: 'Location',
  dataIndex: 'location',
  sorter: {
    compare: (a: IClientRunner, b: IClientRunner) => {
      if (a.step > b.step) return 1;
      if (a.step < b.step) return -1;
      return 0;
    },
    multiple: 4,
  },
  render: (text: string, record: any) => (
    <RouterTDCopy>{record.step}</RouterTDCopy>
  ),
};

export const SALES_ON_TIME_COLUMN = {
  title: 'Status',
  dataIndex: 'onTime',
  render: (text: string, record: any) => (
    <>
      {(!record.runners && !record.completed) && (
      <RouterTDCopy>--</RouterTDCopy>
      )}
      {
            record.runners && !record.completed
            && (
            <OnTimeDot record={record} />
            )
          }
      {record.completed
          && (
          <Tooltip placement="top" title={record.shipDelta} overlayClassName="tooltip-blur" overlayStyle={{ maxWidth: 800 }}>
            <OrderShippedIndicator>
              <p>{`${dateString(record.completedDate.toDate(), true)}`}</p>
            </OrderShippedIndicator>
          </Tooltip>
          )}
    </>
  ),
  // width: IS_MOBILE ? 100 : 150,
  width: '10%',
};

export const EMPTY_COLUMN = {
  title: '',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => <div />,
};

export const DAYS_AT_STEP_COLUMN = {
  title: 'Days at step',
  dataIndex: 'daysAtStep',
  render: (text: string, record: any) => (
    <RouterTDCopy>{record.daysAtStep}</RouterTDCopy>
  ),
};

export const STEP_COLUMN = {
  title: 'Step',
  dataIndex: 'step',
  render: (text: string, record: any) => (
    <RouterTDCopy>{record.step}</RouterTDCopy>
  ),
  width: 400,
};

export const MATERIAL_VALUE_COLUMN = {
  title: 'BOM Value',
  dataIndex: 'bom-value',
  render: (text: string, record: IRunner) => {
    const value = Math.round(record.parts
      .map((p: IOrderItem) => p.bom.map((b: IBomItem) => p.quantityAssigned * b.unitCost).reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0));
    // const bomValue = boms.map((bom: IBomItem[]) => bom.map((bomItem: IBomItem) => bomItem.totalCost).reduce((a, b) => a + b, 0));
    // const value = bomValue.reduce((a, b) => a + b, 0);
    return <RouterTDCopy>{`$${Math.round(value)}`}</RouterTDCopy>;
  },
};

export const PLACEHOLDER_COLUMN = {
  title: '',
  dataIndex: 'placeholder',
  render: (text: string, record: any) => <RouterTDCopy>--</RouterTDCopy>,
  width: '5%',
};

export const columns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  SHIP_DATE_COLUMN,
  COMPLETION_DATE_COLUMN,
  ACTIONS_COLUMN,
];

export const baseNeckColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  MATERIAL_VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  COMPLETION_DATE_COLUMN,
  DAYS_AT_STEP_COLUMN,
  ACTIONS_COLUMN,
];

export const routerQuickLookColumns = [
  { ...EMPTY_COLUMN, width: 100 },
  { ...EMPTY_COLUMN, width: 100 },
  WORK_ORDER_COLUMN,
  // UNITS_COLUMN,
  JOB_TYPE_COLUMN, // 100
  PART_COUNT_COLUMN,
  VALUE_COLUMN,
  STEP_COLUMN,
  DAYS_AT_STEP_COLUMN,
  { ...COMPLETION_DATE_COLUMN, width: 200 },
  ACTIONS_COLUMN,
];

export const baseBodyColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  MATERIAL_VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  COMPLETION_DATE_COLUMN,
  DAYS_AT_STEP_COLUMN,
  ACTIONS_COLUMN,
];

export const onHoldNeckColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  HOLD_COLUMN,
  ACTIONS_COLUMN,
];

export const readyNeckColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  RELEASE_DATE_COLUMN,
  ACTIONS_COLUMN,
];

export const completedNeckColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  SHIPPED_DATE_COLUMN,
  ACTIONS_COLUMN,
];

export const completedBodyColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  SHIPPED_DATE_COLUMN,
  ACTIONS_COLUMN,
];

export const onHoldBodyColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  HOLD_COLUMN,
  ACTIONS_COLUMN,
];

export const readyBodyColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  BODY_UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  VALUE_COLUMN,
  MATERIAL_VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  RELEASE_DATE_COLUMN,
  ACTIONS_COLUMN,
];

export const dryRoomColumns = [
  CUSTOMER_COLUMN,
  SALES_ORDER_COLUMN,
  WORK_ORDER_COLUMN,
  UNITS_COLUMN,
  DESCRIPTION_COLUMN,
  DRY_ROOM_DAYS_COLUMN,
  VALUE_COLUMN,
  MATERIAL_VALUE_COLUMN,
  ROUTER_SHIP_DATE_COLUMN,
  COMPLETION_DATE_COLUMN,
  DAYS_AT_STEP_COLUMN,
  ACTIONS_COLUMN,
];

export const salesOrderColumns = [
  SALES_CUSTOMER_COLUMN, // 150
  SALES_ORDER_COLUMN, // 100
  ORDER_VALUE_COLUMN,
  DESCRIPTION_COLUMN, // 600
  SALES_ORDER_DATE_COLUMN,
  SALES_RELEASE_DATE_COLUMN,
  SALES_SHIP_DATE_COLUMN,
  SALES_ON_TIME_COLUMN,
  // { ...NC_COLUMN, width: 80 },
  SALES_ORDER_ACTIONS_COLUMN,
];

export const salesOrderBodyColumns = [
  ...salesOrderColumns.slice(0, 2),
  PART_COUNT_COLUMN,
  ...salesOrderColumns.slice(2),
  // PLACEHOLDER_COLUMN,
  // PLACEHOLDER_COLUMN,
  // ...salesOrderColumns.slice(5),
];

export const salesOrderNeckColumns = [
  ...salesOrderColumns.slice(0, 2),
  PART_COUNT_COLUMN,
  ...salesOrderColumns.slice(2),
  // SALES_CONST_DATE_COLUMN,
  // SALES_CARVE_DATE_COLUMN,
  // ...salesOrderColumns.slice(5),
];

export const salesOrderByCustomerColumns = [
  {
    ...SALES_ORDER_COLUMN,
    sorter: {
      compare: (a: IClientRunner, b: IClientRunner) => {
        if (a.salesOrder > b.salesOrder) return 1;
        if (b.salesOrder > a.salesOrder) return -1;
        return 0;
      },
      multiple: 1,
    },
    defaultSortOrder: 'ascend',
  }, // 100
  // JOB_TYPE_COLUMN, // 100
  ORDER_VALUE_COLUMN,
  // NC_COLUMN,
  {
    ...DESCRIPTION_COLUMN,
    width: 500,
    sorter: {
      compare: (a: any, b: any) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
        return 0;
      },
    },
  }, // 600
  // SALES_ORDER_PO_COLUMN,
  SALES_ORDER_DATE_COLUMN,
  SALES_RELEASE_DATE_COLUMN,
  {
    ...SALES_SHIP_DATE_COLUMN,
    sorter: {
      compare: (a: IClientRunner, b: IClientRunner) => {
        if (a.shipDate > b.shipDate) return 1;
        if (b.shipDate > a.shipDate) return -1;
        return 0;
      },
      multiple: 2,
    },
    defaultSortOrder: 'ascend',
    render: (text: string, record: any) => (
      <ViewJobWeekButton record={record} />
    ),
  },
  SALES_ON_TIME_COLUMN,
  SALES_ORDER_ACTIONS_COLUMN,
];

export const salesOrderByCustomerBodyColumns = [
  ...salesOrderByCustomerColumns.slice(0, 2),
  PART_COUNT_COLUMN,
  ...salesOrderByCustomerColumns.slice(2, 4),
  // PLACEHOLDER_COLUMN,
  // PLACEHOLDER_COLUMN,
  ...salesOrderByCustomerColumns.slice(4),
];

export const salesOrderByCustomerNeckColumns = [
  ...salesOrderByCustomerColumns.slice(0, 2),
  PART_COUNT_COLUMN,
  ...salesOrderByCustomerColumns.slice(2, 4),
  // SALES_CONST_DATE_COLUMN,
  // SALES_CARVE_DATE_COLUMN,
  ...salesOrderByCustomerColumns.slice(4),
];
