import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { find, sortBy } from 'lodash';
import { customersAtom } from 'shared/state/customerState';
import { ICustomerRecord } from 'shared/types/dbRecords';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';

interface ICustomerSearchSelect {
  selectedCustomerId?: string;
  onCustomerSelect: (customer: ICustomerRecord) => void;
  disabled?: boolean;
}

const CustomerSearchSelect = ({
  selectedCustomerId,
  onCustomerSelect,
  disabled = false,
}: ICustomerSearchSelect) => {
  const customers = useRecoilValue(customersAtom);
  const [customerOptions, setCustomerOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');

  useEffect(() => {
    const activeCustomers = customers.filter((c: ICustomerRecord) => c.active);
    const sortedCustomers = sortBy(activeCustomers, (c: ICustomerRecord) => c.CompanyName);

    const options = sortedCustomers.map((customer) => ({
      label: `${customer.CompanyName}`,
      value: customer.id,
    }));

    setCustomerOptions(options);
  }, [customers]);

  useEffect(() => {
    if (selectedCustomerId) {
      const customer = find(customers, (c) => c.id === selectedCustomerId);
      if (customer) {
        setSelectedCustomer(customer.CompanyName);
      }
    }
  }, [selectedCustomerId, customers]);

  const handleCustomerSelect = (customerId: string) => {
    const selectedCustomerRecord = find(customers, (c) => c.id === customerId);
    if (selectedCustomerRecord) {
      setSelectedCustomer(selectedCustomerRecord.CompanyName);
      onCustomerSelect(selectedCustomerRecord);
    }
  };

  return (
    <DetailDropdownWithCallback
      id="customer-search-select"
      label="Customer"
      listPrompt="Select a customer"
      optionsList={customerOptions}
      selectedOption={selectedCustomer}
      changeCallback={handleCustomerSelect}
      width="100%"
      useSearch
      disabled={disabled}
    />
  );
};

export default CustomerSearchSelect;
